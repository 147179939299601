package features.mentory

import TABLET_SCREEN_WIDTH
import androidx.compose.runtime.Composable
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import features.mentory.adaptive.MentoryAdaptive
import features.mentory.web.MentoryPage
import navigation.MentoryComponent

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun Mentory(
    component: MentoryComponent,
) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    if (screenWidth < TABLET_SCREEN_WIDTH) {
        MentoryAdaptive(component)
    } else {
        MentoryPage(component)
    }
}