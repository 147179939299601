package features.common

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import features.common.web.ImageActionButton
import features.common.web.ImageActionButtons
import features.common.web.TextActionButton
import features.common.web.TextActionButtons
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.decorative_line_5
import passaporteaupair.composeapp.generated.resources.lagguage_2
import passaporteaupair.composeapp.generated.resources.logo
import passaporteaupair.composeapp.generated.resources.map
import passaporteaupair.composeapp.generated.resources.ticket
import theme.dimens

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ColumnScope.FooterContent(
    screenWidth: Dp,
    textActionButtons: List<TextActionButton>,
    imageActionButtons: List<ImageActionButton>,
) {
    Image(
        modifier = Modifier.width(screenWidth * 0.138f),
        painter = painterResource(Res.drawable.logo),
        contentDescription = null
    )
    Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxLarge))
    Row {
        TextActionButtons(textActionButtons)
    }
    Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxLarge))
    Row {
        ImageActionButtons(imageActionButtons)
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun LandingFooterDivider(screenWidth: Dp) {
    Box(modifier = Modifier.fillMaxWidth()) {

        Image(
            modifier = Modifier.fillMaxWidth()
                .offset(y = screenWidth * 0.092f),
            painter = painterResource(Res.drawable.decorative_line_5),
            contentDescription = null
        )
        Image(
            modifier = Modifier.width(screenWidth * 0.0796f)
                .offset(y = screenWidth * 0.085f),
            painter = painterResource(Res.drawable.lagguage_2),
            contentDescription = null
        )
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.TopEnd
        ) {
            Image(
                modifier = Modifier.width(screenWidth * 0.197f)
                    .offset(y = screenWidth * 0.024f),
                painter = painterResource(Res.drawable.map),
                contentDescription = null
            )
            Image(
                modifier = Modifier.width(screenWidth * 0.109f)
                    .offset(x = -screenWidth * 0.109f),
                painter = painterResource(Res.drawable.ticket),
                contentDescription = null
            )
        }
    }
}