package features.landing.ui.web

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import features.common.EBOOK_PURCHASE_LINK
import features.common.LANGUAGE_PORTAL_PURCHASE_LINK
import features.common.MENTORY_PURCHASE_LINK
import features.landing.LandingComponent
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.first_product_desc
import passaporteaupair.composeapp.generated.resources.first_product_items
import passaporteaupair.composeapp.generated.resources.first_product_name
import passaporteaupair.composeapp.generated.resources.second_product_desc
import passaporteaupair.composeapp.generated.resources.second_product_items
import passaporteaupair.composeapp.generated.resources.second_product_name
import passaporteaupair.composeapp.generated.resources.third_product_desc
import passaporteaupair.composeapp.generated.resources.third_product_items
import passaporteaupair.composeapp.generated.resources.third_product_name
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun Products(
    component: LandingComponent,
    modifier: Modifier
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    val uriHandler = LocalUriHandler.current
    Row(
        modifier = modifier
            .fillMaxWidth()
            .padding(
                horizontal = remember(screenWidth) { screenWidth * 0.079f },
                vertical = remember(screenWidth) { screenWidth * 0.033f + spacing.extraLarge }
            ),
        verticalAlignment = Alignment.CenterVertically
    ) {
        ProductItem(
            modifier = Modifier.weight(1f, false),
            title = stringResource(Res.string.first_product_name),
            desc = stringResource(Res.string.first_product_desc),
            benefits = stringArrayResource(Res.array.first_product_items),
            color = MaterialTheme.colorScheme.primary,
            isPopular = false,
            onPrimaryButtonClick = {
                uriHandler.openUri(EBOOK_PURCHASE_LINK)
            },
            onSecondaryButtonClick = {
                component.onEbookClick()
            }
        )
        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.extraLarge))
        ProductItem(
            modifier = Modifier.weight(1f, false),
            title = stringResource(Res.string.second_product_name),
            desc = stringResource(Res.string.second_product_desc),
            benefits = stringArrayResource(Res.array.second_product_items),
            color = MaterialTheme.colorScheme.secondary,
            isPopular = true,
            onPrimaryButtonClick = {
                uriHandler.openUri(MENTORY_PURCHASE_LINK)
            },
            onSecondaryButtonClick = {
                component.onMentoryClick()
            }
        )

        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.extraLarge))
        ProductItem(
            modifier = Modifier.weight(1f, false),
            title = stringResource(Res.string.third_product_name),
            desc = stringResource(Res.string.third_product_desc),
            benefits = stringArrayResource(Res.array.third_product_items),
            color = MaterialTheme.colorScheme.primary,
            isPopular = false,
            onPrimaryButtonClick = {
                uriHandler.openUri(LANGUAGE_PORTAL_PURCHASE_LINK)
            },
            onSecondaryButtonClick = {
                component.onLanguagePortalClick()
            }
        )
    }
}