package features.ebook.ui

import TABLET_SCREEN_WIDTH
import androidx.compose.runtime.Composable
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import features.ebook.ui.adaptive.EbookPageAdaptive
import features.ebook.ui.web.EbookPage
import navigation.EbookComponent

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun Ebook(
    component: EbookComponent,
) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    if(screenWidth<TABLET_SCREEN_WIDTH){
        EbookPageAdaptive(component)
    }else{
        EbookPage(component)
    }
}