package theme

import androidx.compose.material3.ColorScheme
import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.Font
import passaporteaupair.composeapp.generated.resources.Poppins_Bold
import passaporteaupair.composeapp.generated.resources.Poppins_Medium
import passaporteaupair.composeapp.generated.resources.Poppins_Regular
import passaporteaupair.composeapp.generated.resources.Poppins_SemiBold
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.abril_fatface_regular
import passaporteaupair.composeapp.generated.resources.handwritten

// Set of Material typography styles to start with

@OptIn(ExperimentalResourceApi::class)
@Composable

fun Typography(colorScheme: ColorScheme): Typography {
    val poppinsFamily = FontFamily(
        Font(resource = Res.font.Poppins_Regular, weight = FontWeight.Normal),
        Font(resource = Res.font.Poppins_Medium, weight = FontWeight.Medium),
        Font(resource = Res.font.Poppins_SemiBold, weight = FontWeight.SemiBold),
        Font(resource = Res.font.Poppins_Bold, weight = FontWeight.Bold)
    )
    val abrilFamily = FontFamily(
        Font(resource = Res.font.abril_fatface_regular, weight = FontWeight.Normal),
    )
    val handwrittenFamily = FontFamily(
        Font(resource = Res.font.handwritten, weight = FontWeight.Normal),
    )

    return Typography(
        displaySmall = TextStyle(
            fontSize = LocalDimens.current.fontSize.displaySmall,
            fontWeight = FontWeight.Normal,
            fontFamily = handwrittenFamily,
            color = colorScheme.primary
        ),
        displayMedium = TextStyle(
            fontSize = LocalDimens.current.fontSize.displayMedium,
            fontWeight = FontWeight.Normal,
            fontFamily = handwrittenFamily,
            color = colorScheme.primary

        ),
        displayLarge = TextStyle(
            fontSize = LocalDimens.current.fontSize.displayLarge,
            fontWeight = FontWeight.Normal,
            fontFamily = handwrittenFamily,
            color = colorScheme.primary

        ),
        headlineSmall = TextStyle(
            fontSize = LocalDimens.current.fontSize.headlineSmall,
            fontWeight = FontWeight.Normal,
            fontFamily = abrilFamily,
            color = colorScheme.onBackground

        ),
        headlineMedium = TextStyle(
            fontSize = LocalDimens.current.fontSize.headlineMedium,
            fontWeight = FontWeight.Normal,
            fontFamily = abrilFamily,
            color = colorScheme.onBackground

        ),
        headlineLarge = TextStyle(
            fontSize = LocalDimens.current.fontSize.headlineLarge,
            fontWeight = FontWeight.Normal,
            fontFamily = abrilFamily,
            color = colorScheme.onBackground

        ),
        titleSmall = TextStyle(
            fontSize = LocalDimens.current.fontSize.titleSmall,
            fontWeight = FontWeight.Normal,
            fontFamily = poppinsFamily,
            color = colorScheme.onBackground

        ),
        titleMedium = TextStyle(
            fontSize = LocalDimens.current.fontSize.titleMedium,
            fontWeight = FontWeight.Normal,
            fontFamily = poppinsFamily,
            color = colorScheme.onBackground

        ),
        titleLarge = TextStyle(
            fontSize = LocalDimens.current.fontSize.titleLarge,
            fontWeight = FontWeight.Normal,
            fontFamily = poppinsFamily,
            color = colorScheme.onBackground

        ),
        bodySmall = TextStyle(
            fontSize = LocalDimens.current.fontSize.bodySmall,
            fontFamily = poppinsFamily,
            fontWeight = FontWeight.Normal,
            lineHeight = LocalDimens.current.fontLineHeight.bodySmallLineHight,
            color = colorScheme.onBackground
//        letterSpacing = 0.5.sp
        ),
        bodyMedium = TextStyle(
            fontSize = LocalDimens.current.fontSize.bodyMedium,
            fontFamily = poppinsFamily,
            fontWeight = FontWeight.Normal,
            lineHeight = LocalDimens.current.fontLineHeight.bodyMediumLineHight,
            color = colorScheme.onBackground
//        letterSpacing = 0.5.sp
        ),
        bodyLarge = TextStyle(
            fontSize = LocalDimens.current.fontSize.bodyLarge,
            fontFamily = poppinsFamily,
            fontWeight = FontWeight.Normal,
            lineHeight = LocalDimens.current.fontLineHeight.bodyLargeLineHight,
            color = colorScheme.onBackground
//        letterSpacing = 0.5.sp
        ),

        labelMedium = TextStyle(
            fontSize = LocalDimens.current.fontSize.labelMedium,
            fontFamily = poppinsFamily,
            fontWeight = FontWeight.Normal,
            color = colorScheme.onSurface,
        ),
    )
}