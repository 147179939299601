package features.ebook.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import features.common.Circle
import features.common.DashedCircle
import features.common.EBOOK_PURCHASE_LINK
import features.common.ModuleItem
import features.common.PrimaryButton
import navigation.EbookComponent
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_buy_ebook
import passaporteaupair.composeapp.generated.resources.action_buy_ebook_price
import passaporteaupair.composeapp.generated.resources.aim_simple
import passaporteaupair.composeapp.generated.resources.ebook_module_1_items
import passaporteaupair.composeapp.generated.resources.ebook_module_2_items
import passaporteaupair.composeapp.generated.resources.ebook_module_3_items
import passaporteaupair.composeapp.generated.resources.engine
import passaporteaupair.composeapp.generated.resources.label_ebook_details_1
import passaporteaupair.composeapp.generated.resources.label_ebook_details_2
import passaporteaupair.composeapp.generated.resources.product_image_ebook
import passaporteaupair.composeapp.generated.resources.rocket_simple
import passaporteaupair.composeapp.generated.resources.subtitle_ebook_module_1
import passaporteaupair.composeapp.generated.resources.subtitle_ebook_module_2
import passaporteaupair.composeapp.generated.resources.subtitle_ebook_module_3
import passaporteaupair.composeapp.generated.resources.title_ebook_module_1
import passaporteaupair.composeapp.generated.resources.title_ebook_module_2
import passaporteaupair.composeapp.generated.resources.title_ebook_module_3
import theme.dimens
import utils.screenWidth

@Composable
fun EbookDescriptionAdaptive(
    component: EbookComponent
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    val uriHandler = LocalUriHandler.current
    val horizontalPadding = remember(screenWidth) { screenWidth * 0.079f }
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {

        Text(
            modifier = Modifier.fillMaxWidth()
                .padding(horizontal = MaterialTheme.dimens.spacing.medium),
            text = stringResource(Res.string.label_ebook_details_1),
            style = MaterialTheme.typography.headlineMedium
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
        Text(
            modifier = Modifier.fillMaxWidth()
                .padding(horizontal = MaterialTheme.dimens.spacing.medium),
            text = stringResource(Res.string.label_ebook_details_2),
            style = MaterialTheme.typography.bodyMedium
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
        PrimaryButton(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = MaterialTheme.dimens.spacing.medium),
            text = buildAnnotatedString {
                withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
                    append(stringResource(Res.string.action_buy_ebook))
                }
                withStyle(SpanStyle(fontWeight = FontWeight.Bold)) {
                    append(stringResource(Res.string.action_buy_ebook_price))
                }
            },
            onPurchaseClick = {
                uriHandler.openUri(EBOOK_PURCHASE_LINK)
            }
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxxLarge))
        val circleOffsetY = remember(screenWidth) { screenWidth * 0.197f }

        Box(
            modifier = Modifier.padding(bottom = circleOffsetY),
            contentAlignment = Alignment.BottomEnd
        ) {
            Circle(
                modifier = Modifier
                    .offset(
                        x = remember(screenWidth) { screenWidth * 0.248f },
                        y = circleOffsetY
                    ),
                radius = remember(screenWidth) { screenWidth * 0.39f },
                color = MaterialTheme.colorScheme.primary
            )
            DashedCircle(
                modifier = Modifier
                    .offset(
                        x = remember(screenWidth) { screenWidth * 0.105f },
                        y = remember(screenWidth) { screenWidth * 0.182f }
                    ),
                radius = remember(screenWidth) { screenWidth * 0.373f }
            )
            Image(
                modifier = Modifier
                    .wrapContentSize(unbounded = true)
                    .width(remember(screenWidth) { screenWidth * 1.31f }),
                painter = painterResource(Res.drawable.product_image_ebook),
                contentDescription = null
            )
        }
        Spacer(modifier = Modifier.height(remember(screenWidth) { screenWidth * 0.158f + spacing.extraLarge }))
        ModuleItem(
            modifier = Modifier.fillMaxWidth()
                .padding(horizontal = spacing.medium),
            icon = Res.drawable.aim_simple,
            moduleSubtitle = stringResource(Res.string.subtitle_ebook_module_1),
            moduleTitle = stringResource(Res.string.title_ebook_module_1),
            moduleItems = stringArrayResource(Res.array.ebook_module_1_items)
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
        ModuleItem(
            modifier = Modifier.fillMaxWidth()
                .padding(horizontal = spacing.medium),
            icon = Res.drawable.engine,
            moduleSubtitle = stringResource(Res.string.subtitle_ebook_module_2),
            moduleTitle = stringResource(Res.string.title_ebook_module_2),
            moduleItems = stringArrayResource(Res.array.ebook_module_2_items)
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
        ModuleItem(
            modifier = Modifier.fillMaxWidth()
                .padding(horizontal = spacing.medium),
            icon = Res.drawable.rocket_simple,
            moduleSubtitle = stringResource(Res.string.subtitle_ebook_module_3),
            moduleTitle = stringResource(Res.string.title_ebook_module_3),
            moduleItems = stringArrayResource(Res.array.ebook_module_3_items)
        )
        Spacer(modifier = Modifier.height(remember(screenWidth) { screenWidth * 0.35f + spacing.extraLarge }))
    }
}

