package features.landing.ui.web

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.airplane
import passaporteaupair.composeapp.generated.resources.cloud_1
import passaporteaupair.composeapp.generated.resources.cloud_2
import passaporteaupair.composeapp.generated.resources.cloud_3
import passaporteaupair.composeapp.generated.resources.cloud_4
import passaporteaupair.composeapp.generated.resources.cloud_5
import passaporteaupair.composeapp.generated.resources.cloud_6
import passaporteaupair.composeapp.generated.resources.label_exchange_details_1
import passaporteaupair.composeapp.generated.resources.label_exchange_details_2
import passaporteaupair.composeapp.generated.resources.label_exchange_details_3
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun ExchangeDetails() {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(
                top = remember(screenWidth) { screenWidth * 0.094f + spacing.extraLarge },
                start = remember(screenWidth) { screenWidth * 0.079f },
                bottom = remember(screenWidth) { screenWidth * 0.093f + spacing.extraLarge }
            )
    ) {
        Column(
//            modifier = Modifier.fillMaxWidth(0.50f)
        ) {
            Text(
                text = stringResource(Res.string.label_exchange_details_1),
                style = MaterialTheme.typography.bodyLarge
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
            Text(
                modifier = Modifier.fillMaxWidth(0.3f),
                text = stringResource(Res.string.label_exchange_details_2),
                style = MaterialTheme.typography.headlineMedium
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
            Text(
                text = stringResource(Res.string.label_exchange_details_3),
                style = MaterialTheme.typography.bodyLarge,
                lineHeight = MaterialTheme.typography.bodyLarge.lineHeight * 1.3f
            )
        }
        Box(
            modifier = Modifier.padding(top = MaterialTheme.dimens.spacing.medium),
            contentAlignment = Alignment.TopEnd
        ) {
            Column(
                modifier = Modifier.fillMaxSize()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.097f }),
                        painter = painterResource(Res.drawable.cloud_5),
                        contentDescription = null
                    )
                    Spacer(modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.078f }))
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.047f })
                            .offset(y = MaterialTheme.dimens.spacing.medium),
                        painter = painterResource(Res.drawable.cloud_4),
                        contentDescription = null
                    )
                    Spacer(modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.119f }))
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.081f })
                            .offset(y = MaterialTheme.dimens.spacing.small),
                        painter = painterResource(Res.drawable.cloud_3),
                        contentDescription = null
                    )
                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = MaterialTheme.dimens.spacing.medium),
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.113f }),
                        painter = painterResource(Res.drawable.cloud_1),
                        contentDescription = null
                    )
                    Spacer(modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.031f }))
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.375f }),
                        painter = painterResource(Res.drawable.airplane),
                        contentDescription = null
                    )
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.081f }),
                        painter = painterResource(Res.drawable.cloud_2),
                        contentDescription = null
                    )
                    Spacer(modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.076f }))
                    Image(
                        modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.164f }),
                        painter = painterResource(Res.drawable.cloud_6),
                        contentDescription = null
                    )

                }
            }
        }
    }
}