@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package passaporteaupair.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val _10: DrawableResource by 
      lazy { init__10() }

  public val _11: DrawableResource by 
      lazy { init__11() }

  public val _12: DrawableResource by 
      lazy { init__12() }

  public val _13: DrawableResource by 
      lazy { init__13() }

  public val _14: DrawableResource by 
      lazy { init__14() }

  public val _15: DrawableResource by 
      lazy { init__15() }

  public val _16: DrawableResource by 
      lazy { init__16() }

  public val _17: DrawableResource by 
      lazy { init__17() }

  public val _18: DrawableResource by 
      lazy { init__18() }

  public val _19: DrawableResource by 
      lazy { init__19() }

  public val _20: DrawableResource by 
      lazy { init__20() }

  public val _21: DrawableResource by 
      lazy { init__21() }

  public val _22: DrawableResource by 
      lazy { init__22() }

  public val _23: DrawableResource by 
      lazy { init__23() }

  public val _24: DrawableResource by 
      lazy { init__24() }

  public val _25: DrawableResource by 
      lazy { init__25() }

  public val _26: DrawableResource by 
      lazy { init__26() }

  public val _27: DrawableResource by 
      lazy { init__27() }

  public val _28: DrawableResource by 
      lazy { init__28() }

  public val _29: DrawableResource by 
      lazy { init__29() }

  public val _3: DrawableResource by 
      lazy { init__3() }

  public val _30: DrawableResource by 
      lazy { init__30() }

  public val _31: DrawableResource by 
      lazy { init__31() }

  public val _32: DrawableResource by 
      lazy { init__32() }

  public val _33: DrawableResource by 
      lazy { init__33() }

  public val _34: DrawableResource by 
      lazy { init__34() }

  public val _35: DrawableResource by 
      lazy { init__35() }

  public val _36: DrawableResource by 
      lazy { init__36() }

  public val _37: DrawableResource by 
      lazy { init__37() }

  public val _38: DrawableResource by 
      lazy { init__38() }

  public val _39: DrawableResource by 
      lazy { init__39() }

  public val _4: DrawableResource by 
      lazy { init__4() }

  public val _40: DrawableResource by 
      lazy { init__40() }

  public val _41: DrawableResource by 
      lazy { init__41() }

  public val _42: DrawableResource by 
      lazy { init__42() }

  public val _43: DrawableResource by 
      lazy { init__43() }

  public val _44: DrawableResource by 
      lazy { init__44() }

  public val _45: DrawableResource by 
      lazy { init__45() }

  public val _46: DrawableResource by 
      lazy { init__46() }

  public val _5: DrawableResource by 
      lazy { init__5() }

  public val _6: DrawableResource by 
      lazy { init__6() }

  public val _7: DrawableResource by 
      lazy { init__7() }

  public val _8: DrawableResource by 
      lazy { init__8() }

  public val _9: DrawableResource by 
      lazy { init__9() }

  public val academic_hat: DrawableResource by 
      lazy { init_academic_hat() }

  public val achievement: DrawableResource by 
      lazy { init_achievement() }

  public val aim: DrawableResource by 
      lazy { init_aim() }

  public val aim_simple: DrawableResource by 
      lazy { init_aim_simple() }

  public val airplane: DrawableResource by 
      lazy { init_airplane() }

  public val arrow_down: DrawableResource by 
      lazy { init_arrow_down() }

  public val bg_bonus: DrawableResource by 
      lazy { init_bg_bonus() }

  public val boat: DrawableResource by 
      lazy { init_boat() }

  public val checkmark: DrawableResource by 
      lazy { init_checkmark() }

  public val close: DrawableResource by 
      lazy { init_close() }

  public val cloud_1: DrawableResource by 
      lazy { init_cloud_1() }

  public val cloud_2: DrawableResource by 
      lazy { init_cloud_2() }

  public val cloud_3: DrawableResource by 
      lazy { init_cloud_3() }

  public val cloud_4: DrawableResource by 
      lazy { init_cloud_4() }

  public val cloud_5: DrawableResource by 
      lazy { init_cloud_5() }

  public val cloud_6: DrawableResource by 
      lazy { init_cloud_6() }

  public val cloud_7: DrawableResource by 
      lazy { init_cloud_7() }

  public val cloud_8: DrawableResource by 
      lazy { init_cloud_8() }

  public val cloud_9: DrawableResource by 
      lazy { init_cloud_9() }

  public val decorative_circle_1: DrawableResource by 
      lazy { init_decorative_circle_1() }

  public val decorative_circle_2: DrawableResource by 
      lazy { init_decorative_circle_2() }

  public val decorative_circle_3: DrawableResource by 
      lazy { init_decorative_circle_3() }

  public val decorative_circle_4: DrawableResource by 
      lazy { init_decorative_circle_4() }

  public val decorative_circle_5: DrawableResource by 
      lazy { init_decorative_circle_5() }

  public val decorative_dashed_circle: DrawableResource by 
      lazy { init_decorative_dashed_circle() }

  public val decorative_line_1: DrawableResource by 
      lazy { init_decorative_line_1() }

  public val decorative_line_2: DrawableResource by 
      lazy { init_decorative_line_2() }

  public val decorative_line_3: DrawableResource by 
      lazy { init_decorative_line_3() }

  public val decorative_line_4: DrawableResource by 
      lazy { init_decorative_line_4() }

  public val decorative_line_5: DrawableResource by 
      lazy { init_decorative_line_5() }

  public val decorative_line_6: DrawableResource by 
      lazy { init_decorative_line_6() }

  public val engine: DrawableResource by 
      lazy { init_engine() }

  public val flying_rocket: DrawableResource by 
      lazy { init_flying_rocket() }

  public val gift: DrawableResource by 
      lazy { init_gift() }

  public val globe: DrawableResource by 
      lazy { init_globe() }

  public val heart: DrawableResource by 
      lazy { init_heart() }

  public val instagram: DrawableResource by 
      lazy { init_instagram() }

  public val lagguage: DrawableResource by 
      lazy { init_lagguage() }

  public val lagguage_2: DrawableResource by 
      lazy { init_lagguage_2() }

  public val location: DrawableResource by 
      lazy { init_location() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val map: DrawableResource by 
      lazy { init_map() }

  public val mentor_image: DrawableResource by 
      lazy { init_mentor_image() }

  public val mentor_image_2: DrawableResource by 
      lazy { init_mentor_image_2() }

  public val mentor_image_3: DrawableResource by 
      lazy { init_mentor_image_3() }

  public val mentory_bank: DrawableResource by 
      lazy { init_mentory_bank() }

  public val mentory_globe: DrawableResource by 
      lazy { init_mentory_globe() }

  public val mentory_graph: DrawableResource by 
      lazy { init_mentory_graph() }

  public val mentory_hat: DrawableResource by 
      lazy { init_mentory_hat() }

  public val mentory_headphone: DrawableResource by 
      lazy { init_mentory_headphone() }

  public val mentory_house: DrawableResource by 
      lazy { init_mentory_house() }

  public val mentory_message: DrawableResource by 
      lazy { init_mentory_message() }

  public val mentory_paper: DrawableResource by 
      lazy { init_mentory_paper() }

  public val mentory_passport: DrawableResource by 
      lazy { init_mentory_passport() }

  public val mentory_star: DrawableResource by 
      lazy { init_mentory_star() }

  public val mentory_video_cam: DrawableResource by 
      lazy { init_mentory_video_cam() }

  public val mentory_whatsapp: DrawableResource by 
      lazy { init_mentory_whatsapp() }

  public val menu: DrawableResource by 
      lazy { init_menu() }

  public val message: DrawableResource by 
      lazy { init_message() }

  public val pin_green: DrawableResource by 
      lazy { init_pin_green() }

  public val pin_orange: DrawableResource by 
      lazy { init_pin_orange() }

  public val play: DrawableResource by 
      lazy { init_play() }

  public val product_image_ebook: DrawableResource by 
      lazy { init_product_image_ebook() }

  public val product_image_language_portal: DrawableResource by 
      lazy { init_product_image_language_portal() }

  public val product_image_mentory: DrawableResource by 
      lazy { init_product_image_mentory() }

  public val rocket: DrawableResource by 
      lazy { init_rocket() }

  public val rocket_simple: DrawableResource by 
      lazy { init_rocket_simple() }

  public val shield: DrawableResource by 
      lazy { init_shield() }

  public val stamp_1: DrawableResource by 
      lazy { init_stamp_1() }

  public val stamp_2: DrawableResource by 
      lazy { init_stamp_2() }

  public val stamp_3: DrawableResource by 
      lazy { init_stamp_3() }

  public val stamp_4: DrawableResource by 
      lazy { init_stamp_4() }

  public val star: DrawableResource by 
      lazy { init_star() }

  public val stars: DrawableResource by 
      lazy { init_stars() }

  public val stars_adaptive: DrawableResource by 
      lazy { init_stars_adaptive() }

  public val tab_icon: DrawableResource by 
      lazy { init_tab_icon() }

  public val ticket: DrawableResource by 
      lazy { init_ticket() }

  public val whatsapp: DrawableResource by 
      lazy { init_whatsapp() }

  public val youtube: DrawableResource by 
      lazy { init_youtube() }
}

internal val Res.drawable._10: DrawableResource
  get() = CommonMainDrawable0._10

private fun init__10(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_10",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/10.png", -1, -1),
    )
)

internal val Res.drawable._11: DrawableResource
  get() = CommonMainDrawable0._11

private fun init__11(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_11",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/11.png", -1, -1),
    )
)

internal val Res.drawable._12: DrawableResource
  get() = CommonMainDrawable0._12

private fun init__12(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_12",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/12.png", -1, -1),
    )
)

internal val Res.drawable._13: DrawableResource
  get() = CommonMainDrawable0._13

private fun init__13(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_13",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/13.png", -1, -1),
    )
)

internal val Res.drawable._14: DrawableResource
  get() = CommonMainDrawable0._14

private fun init__14(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_14",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/14.png", -1, -1),
    )
)

internal val Res.drawable._15: DrawableResource
  get() = CommonMainDrawable0._15

private fun init__15(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_15",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/15.png", -1, -1),
    )
)

internal val Res.drawable._16: DrawableResource
  get() = CommonMainDrawable0._16

private fun init__16(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_16",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/16.png", -1, -1),
    )
)

internal val Res.drawable._17: DrawableResource
  get() = CommonMainDrawable0._17

private fun init__17(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_17",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/17.png", -1, -1),
    )
)

internal val Res.drawable._18: DrawableResource
  get() = CommonMainDrawable0._18

private fun init__18(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_18",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/18.png", -1, -1),
    )
)

internal val Res.drawable._19: DrawableResource
  get() = CommonMainDrawable0._19

private fun init__19(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_19",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/19.png", -1, -1),
    )
)

internal val Res.drawable._20: DrawableResource
  get() = CommonMainDrawable0._20

private fun init__20(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_20",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/20.png", -1, -1),
    )
)

internal val Res.drawable._21: DrawableResource
  get() = CommonMainDrawable0._21

private fun init__21(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_21",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/21.png", -1, -1),
    )
)

internal val Res.drawable._22: DrawableResource
  get() = CommonMainDrawable0._22

private fun init__22(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_22",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/22.png", -1, -1),
    )
)

internal val Res.drawable._23: DrawableResource
  get() = CommonMainDrawable0._23

private fun init__23(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_23",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/23.png", -1, -1),
    )
)

internal val Res.drawable._24: DrawableResource
  get() = CommonMainDrawable0._24

private fun init__24(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/24.png", -1, -1),
    )
)

internal val Res.drawable._25: DrawableResource
  get() = CommonMainDrawable0._25

private fun init__25(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_25",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/25.png", -1, -1),
    )
)

internal val Res.drawable._26: DrawableResource
  get() = CommonMainDrawable0._26

private fun init__26(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_26",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/26.png", -1, -1),
    )
)

internal val Res.drawable._27: DrawableResource
  get() = CommonMainDrawable0._27

private fun init__27(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_27",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/27.png", -1, -1),
    )
)

internal val Res.drawable._28: DrawableResource
  get() = CommonMainDrawable0._28

private fun init__28(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_28",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/28.png", -1, -1),
    )
)

internal val Res.drawable._29: DrawableResource
  get() = CommonMainDrawable0._29

private fun init__29(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_29",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/29.png", -1, -1),
    )
)

internal val Res.drawable._3: DrawableResource
  get() = CommonMainDrawable0._3

private fun init__3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/3.png", -1, -1),
    )
)

internal val Res.drawable._30: DrawableResource
  get() = CommonMainDrawable0._30

private fun init__30(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_30",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/30.png", -1, -1),
    )
)

internal val Res.drawable._31: DrawableResource
  get() = CommonMainDrawable0._31

private fun init__31(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_31",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/31.png", -1, -1),
    )
)

internal val Res.drawable._32: DrawableResource
  get() = CommonMainDrawable0._32

private fun init__32(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_32",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/32.png", -1, -1),
    )
)

internal val Res.drawable._33: DrawableResource
  get() = CommonMainDrawable0._33

private fun init__33(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_33",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/33.png", -1, -1),
    )
)

internal val Res.drawable._34: DrawableResource
  get() = CommonMainDrawable0._34

private fun init__34(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_34",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/34.png", -1, -1),
    )
)

internal val Res.drawable._35: DrawableResource
  get() = CommonMainDrawable0._35

private fun init__35(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_35",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/35.png", -1, -1),
    )
)

internal val Res.drawable._36: DrawableResource
  get() = CommonMainDrawable0._36

private fun init__36(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_36",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/36.png", -1, -1),
    )
)

internal val Res.drawable._37: DrawableResource
  get() = CommonMainDrawable0._37

private fun init__37(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_37",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/37.png", -1, -1),
    )
)

internal val Res.drawable._38: DrawableResource
  get() = CommonMainDrawable0._38

private fun init__38(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_38",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/38.png", -1, -1),
    )
)

internal val Res.drawable._39: DrawableResource
  get() = CommonMainDrawable0._39

private fun init__39(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_39",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/39.png", -1, -1),
    )
)

internal val Res.drawable._4: DrawableResource
  get() = CommonMainDrawable0._4

private fun init__4(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/4.png", -1, -1),
    )
)

internal val Res.drawable._40: DrawableResource
  get() = CommonMainDrawable0._40

private fun init__40(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_40",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/40.png", -1, -1),
    )
)

internal val Res.drawable._41: DrawableResource
  get() = CommonMainDrawable0._41

private fun init__41(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_41",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/41.png", -1, -1),
    )
)

internal val Res.drawable._42: DrawableResource
  get() = CommonMainDrawable0._42

private fun init__42(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_42",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/42.png", -1, -1),
    )
)

internal val Res.drawable._43: DrawableResource
  get() = CommonMainDrawable0._43

private fun init__43(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_43",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/43.png", -1, -1),
    )
)

internal val Res.drawable._44: DrawableResource
  get() = CommonMainDrawable0._44

private fun init__44(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_44",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/44.png", -1, -1),
    )
)

internal val Res.drawable._45: DrawableResource
  get() = CommonMainDrawable0._45

private fun init__45(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_45",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/45.png", -1, -1),
    )
)

internal val Res.drawable._46: DrawableResource
  get() = CommonMainDrawable0._46

private fun init__46(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_46",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/46.png", -1, -1),
    )
)

internal val Res.drawable._5: DrawableResource
  get() = CommonMainDrawable0._5

private fun init__5(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/5.png", -1, -1),
    )
)

internal val Res.drawable._6: DrawableResource
  get() = CommonMainDrawable0._6

private fun init__6(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_6",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/6.png", -1, -1),
    )
)

internal val Res.drawable._7: DrawableResource
  get() = CommonMainDrawable0._7

private fun init__7(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_7",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/7.png", -1, -1),
    )
)

internal val Res.drawable._8: DrawableResource
  get() = CommonMainDrawable0._8

private fun init__8(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_8",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/8.png", -1, -1),
    )
)

internal val Res.drawable._9: DrawableResource
  get() = CommonMainDrawable0._9

private fun init__9(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:_9",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/9.png", -1, -1),
    )
)

internal val Res.drawable.academic_hat: DrawableResource
  get() = CommonMainDrawable0.academic_hat

private fun init_academic_hat(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:academic_hat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/academic_hat.xml", -1, -1),
    )
)

internal val Res.drawable.achievement: DrawableResource
  get() = CommonMainDrawable0.achievement

private fun init_achievement(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:achievement",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/achievement.xml", -1, -1),
    )
)

internal val Res.drawable.aim: DrawableResource
  get() = CommonMainDrawable0.aim

private fun init_aim(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:aim",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/aim.xml", -1, -1),
    )
)

internal val Res.drawable.aim_simple: DrawableResource
  get() = CommonMainDrawable0.aim_simple

private fun init_aim_simple(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:aim_simple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/aim_simple.xml", -1, -1),
    )
)

internal val Res.drawable.airplane: DrawableResource
  get() = CommonMainDrawable0.airplane

private fun init_airplane(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:airplane",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/airplane.xml", -1, -1),
    )
)

internal val Res.drawable.arrow_down: DrawableResource
  get() = CommonMainDrawable0.arrow_down

private fun init_arrow_down(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/arrow_down.xml", -1, -1),
    )
)

internal val Res.drawable.bg_bonus: DrawableResource
  get() = CommonMainDrawable0.bg_bonus

private fun init_bg_bonus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:bg_bonus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/bg_bonus.xml", -1, -1),
    )
)

internal val Res.drawable.boat: DrawableResource
  get() = CommonMainDrawable0.boat

private fun init_boat(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:boat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/boat.png", -1, -1),
    )
)

internal val Res.drawable.checkmark: DrawableResource
  get() = CommonMainDrawable0.checkmark

private fun init_checkmark(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:checkmark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/checkmark.xml", -1, -1),
    )
)

internal val Res.drawable.close: DrawableResource
  get() = CommonMainDrawable0.close

private fun init_close(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/close.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_1: DrawableResource
  get() = CommonMainDrawable0.cloud_1

private fun init_cloud_1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_1.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_2: DrawableResource
  get() = CommonMainDrawable0.cloud_2

private fun init_cloud_2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_2.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_3: DrawableResource
  get() = CommonMainDrawable0.cloud_3

private fun init_cloud_3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_3.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_4: DrawableResource
  get() = CommonMainDrawable0.cloud_4

private fun init_cloud_4(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_4.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_5: DrawableResource
  get() = CommonMainDrawable0.cloud_5

private fun init_cloud_5(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_5.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_6: DrawableResource
  get() = CommonMainDrawable0.cloud_6

private fun init_cloud_6(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_6",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_6.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_7: DrawableResource
  get() = CommonMainDrawable0.cloud_7

private fun init_cloud_7(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_7",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_7.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_8: DrawableResource
  get() = CommonMainDrawable0.cloud_8

private fun init_cloud_8(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_8",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_8.xml", -1, -1),
    )
)

internal val Res.drawable.cloud_9: DrawableResource
  get() = CommonMainDrawable0.cloud_9

private fun init_cloud_9(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cloud_9",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/cloud_9.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_circle_1: DrawableResource
  get() = CommonMainDrawable0.decorative_circle_1

private fun init_decorative_circle_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_circle_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_circle_1.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_circle_2: DrawableResource
  get() = CommonMainDrawable0.decorative_circle_2

private fun init_decorative_circle_2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_circle_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_circle_2.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_circle_3: DrawableResource
  get() = CommonMainDrawable0.decorative_circle_3

private fun init_decorative_circle_3(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_circle_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_circle_3.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_circle_4: DrawableResource
  get() = CommonMainDrawable0.decorative_circle_4

private fun init_decorative_circle_4(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_circle_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_circle_4.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_circle_5: DrawableResource
  get() = CommonMainDrawable0.decorative_circle_5

private fun init_decorative_circle_5(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_circle_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_circle_5.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_dashed_circle: DrawableResource
  get() = CommonMainDrawable0.decorative_dashed_circle

private fun init_decorative_dashed_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_dashed_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_dashed_circle.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_line_1: DrawableResource
  get() = CommonMainDrawable0.decorative_line_1

private fun init_decorative_line_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_line_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_line_1.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_line_2: DrawableResource
  get() = CommonMainDrawable0.decorative_line_2

private fun init_decorative_line_2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_line_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_line_2.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_line_3: DrawableResource
  get() = CommonMainDrawable0.decorative_line_3

private fun init_decorative_line_3(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_line_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_line_3.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_line_4: DrawableResource
  get() = CommonMainDrawable0.decorative_line_4

private fun init_decorative_line_4(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_line_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_line_4.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_line_5: DrawableResource
  get() = CommonMainDrawable0.decorative_line_5

private fun init_decorative_line_5(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_line_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_line_5.xml", -1, -1),
    )
)

internal val Res.drawable.decorative_line_6: DrawableResource
  get() = CommonMainDrawable0.decorative_line_6

private fun init_decorative_line_6(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:decorative_line_6",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/decorative_line_6.xml", -1, -1),
    )
)

internal val Res.drawable.engine: DrawableResource
  get() = CommonMainDrawable0.engine

private fun init_engine(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:engine",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/engine.xml", -1, -1),
    )
)

internal val Res.drawable.flying_rocket: DrawableResource
  get() = CommonMainDrawable0.flying_rocket

private fun init_flying_rocket(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:flying_rocket",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/flying_rocket.xml", -1, -1),
    )
)

internal val Res.drawable.gift: DrawableResource
  get() = CommonMainDrawable0.gift

private fun init_gift(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:gift",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/gift.xml", -1, -1),
    )
)

internal val Res.drawable.globe: DrawableResource
  get() = CommonMainDrawable0.globe

private fun init_globe(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:globe",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/globe.xml", -1, -1),
    )
)

internal val Res.drawable.heart: DrawableResource
  get() = CommonMainDrawable0.heart

private fun init_heart(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:heart",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/heart.xml", -1, -1),
    )
)

internal val Res.drawable.instagram: DrawableResource
  get() = CommonMainDrawable0.instagram

private fun init_instagram(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/instagram.xml", -1, -1),
    )
)

internal val Res.drawable.lagguage: DrawableResource
  get() = CommonMainDrawable0.lagguage

private fun init_lagguage(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:lagguage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/lagguage.xml", -1, -1),
    )
)

internal val Res.drawable.lagguage_2: DrawableResource
  get() = CommonMainDrawable0.lagguage_2

private fun init_lagguage_2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:lagguage_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/lagguage_2.png", -1, -1),
    )
)

internal val Res.drawable.location: DrawableResource
  get() = CommonMainDrawable0.location

private fun init_location(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:location",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/location.xml", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/logo.xml", -1, -1),
    )
)

internal val Res.drawable.map: DrawableResource
  get() = CommonMainDrawable0.map

private fun init_map(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/map.png", -1, -1),
    )
)

internal val Res.drawable.mentor_image: DrawableResource
  get() = CommonMainDrawable0.mentor_image

private fun init_mentor_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentor_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentor_image.jpeg", -1, -1),
    )
)

internal val Res.drawable.mentor_image_2: DrawableResource
  get() = CommonMainDrawable0.mentor_image_2

private fun init_mentor_image_2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentor_image_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentor_image_2.jpg", -1, -1),
    )
)

internal val Res.drawable.mentor_image_3: DrawableResource
  get() = CommonMainDrawable0.mentor_image_3

private fun init_mentor_image_3(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentor_image_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentor_image_3.png", -1, -1),
    )
)

internal val Res.drawable.mentory_bank: DrawableResource
  get() = CommonMainDrawable0.mentory_bank

private fun init_mentory_bank(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_bank",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_bank.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_globe: DrawableResource
  get() = CommonMainDrawable0.mentory_globe

private fun init_mentory_globe(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_globe",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_globe.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_graph: DrawableResource
  get() = CommonMainDrawable0.mentory_graph

private fun init_mentory_graph(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_graph",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_graph.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_hat: DrawableResource
  get() = CommonMainDrawable0.mentory_hat

private fun init_mentory_hat(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_hat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_hat.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_headphone: DrawableResource
  get() = CommonMainDrawable0.mentory_headphone

private fun init_mentory_headphone(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_headphone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_headphone.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_house: DrawableResource
  get() = CommonMainDrawable0.mentory_house

private fun init_mentory_house(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_house",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_house.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_message: DrawableResource
  get() = CommonMainDrawable0.mentory_message

private fun init_mentory_message(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_message.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_paper: DrawableResource
  get() = CommonMainDrawable0.mentory_paper

private fun init_mentory_paper(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_paper",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_paper.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_passport: DrawableResource
  get() = CommonMainDrawable0.mentory_passport

private fun init_mentory_passport(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_passport",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_passport.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_star: DrawableResource
  get() = CommonMainDrawable0.mentory_star

private fun init_mentory_star(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_star.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_video_cam: DrawableResource
  get() = CommonMainDrawable0.mentory_video_cam

private fun init_mentory_video_cam(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_video_cam",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_video_cam.xml", -1, -1),
    )
)

internal val Res.drawable.mentory_whatsapp: DrawableResource
  get() = CommonMainDrawable0.mentory_whatsapp

private fun init_mentory_whatsapp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mentory_whatsapp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/mentory_whatsapp.xml", -1, -1),
    )
)

internal val Res.drawable.menu: DrawableResource
  get() = CommonMainDrawable0.menu

private fun init_menu(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:menu",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/menu.xml", -1, -1),
    )
)

internal val Res.drawable.message: DrawableResource
  get() = CommonMainDrawable0.message

private fun init_message(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/message.xml", -1, -1),
    )
)

internal val Res.drawable.pin_green: DrawableResource
  get() = CommonMainDrawable0.pin_green

private fun init_pin_green(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pin_green",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/pin_green.xml", -1, -1),
    )
)

internal val Res.drawable.pin_orange: DrawableResource
  get() = CommonMainDrawable0.pin_orange

private fun init_pin_orange(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pin_orange",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/pin_orange.xml", -1, -1),
    )
)

internal val Res.drawable.play: DrawableResource
  get() = CommonMainDrawable0.play

private fun init_play(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/play.xml", -1, -1),
    )
)

internal val Res.drawable.product_image_ebook: DrawableResource
  get() = CommonMainDrawable0.product_image_ebook

private fun init_product_image_ebook(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:product_image_ebook",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/product_image_ebook.png", -1, -1),
    )
)

internal val Res.drawable.product_image_language_portal: DrawableResource
  get() = CommonMainDrawable0.product_image_language_portal

private fun init_product_image_language_portal(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:product_image_language_portal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/product_image_language_portal.png", -1, -1),
    )
)

internal val Res.drawable.product_image_mentory: DrawableResource
  get() = CommonMainDrawable0.product_image_mentory

private fun init_product_image_mentory(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:product_image_mentory",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/product_image_mentory.png", -1, -1),
    )
)

internal val Res.drawable.rocket: DrawableResource
  get() = CommonMainDrawable0.rocket

private fun init_rocket(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:rocket",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/rocket.xml", -1, -1),
    )
)

internal val Res.drawable.rocket_simple: DrawableResource
  get() = CommonMainDrawable0.rocket_simple

private fun init_rocket_simple(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:rocket_simple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/rocket_simple.xml", -1, -1),
    )
)

internal val Res.drawable.shield: DrawableResource
  get() = CommonMainDrawable0.shield

private fun init_shield(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:shield",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/shield.xml", -1, -1),
    )
)

internal val Res.drawable.stamp_1: DrawableResource
  get() = CommonMainDrawable0.stamp_1

private fun init_stamp_1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:stamp_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/stamp_1.xml", -1, -1),
    )
)

internal val Res.drawable.stamp_2: DrawableResource
  get() = CommonMainDrawable0.stamp_2

private fun init_stamp_2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:stamp_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/stamp_2.xml", -1, -1),
    )
)

internal val Res.drawable.stamp_3: DrawableResource
  get() = CommonMainDrawable0.stamp_3

private fun init_stamp_3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:stamp_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/stamp_3.xml", -1, -1),
    )
)

internal val Res.drawable.stamp_4: DrawableResource
  get() = CommonMainDrawable0.stamp_4

private fun init_stamp_4(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:stamp_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/stamp_4.xml", -1, -1),
    )
)

internal val Res.drawable.star: DrawableResource
  get() = CommonMainDrawable0.star

private fun init_star(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/star.xml", -1, -1),
    )
)

internal val Res.drawable.stars: DrawableResource
  get() = CommonMainDrawable0.stars

private fun init_stars(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:stars",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/stars.xml", -1, -1),
    )
)

internal val Res.drawable.stars_adaptive: DrawableResource
  get() = CommonMainDrawable0.stars_adaptive

private fun init_stars_adaptive(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:stars_adaptive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/stars_adaptive.xml", -1, -1),
    )
)

internal val Res.drawable.tab_icon: DrawableResource
  get() = CommonMainDrawable0.tab_icon

private fun init_tab_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:tab_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/tab_icon.png", -1, -1),
    )
)

internal val Res.drawable.ticket: DrawableResource
  get() = CommonMainDrawable0.ticket

private fun init_ticket(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ticket",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/ticket.png", -1, -1),
    )
)

internal val Res.drawable.whatsapp: DrawableResource
  get() = CommonMainDrawable0.whatsapp

private fun init_whatsapp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:whatsapp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/whatsapp.xml", -1, -1),
    )
)

internal val Res.drawable.youtube: DrawableResource
  get() = CommonMainDrawable0.youtube

private fun init_youtube(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:youtube",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/drawable/youtube.xml", -1, -1),
    )
)
