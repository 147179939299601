package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.text.style.TextAlign
import features.common.PrimaryButton
import features.landing.LandingComponent
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.label_exchange_benefits_1
import passaporteaupair.composeapp.generated.resources.label_exchange_benefits_2
import passaporteaupair.composeapp.generated.resources.label_exchange_benefits_3
import passaporteaupair.composeapp.generated.resources.stamp_1
import passaporteaupair.composeapp.generated.resources.stamp_2
import passaporteaupair.composeapp.generated.resources.stamp_3
import passaporteaupair.composeapp.generated.resources.stamp_4
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ExchangeBenefitsAdaptive(
    component: LandingComponent,
    modifier: Modifier
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    Box(
        modifier = modifier
            .background(MaterialTheme.colorScheme.surface)
            .padding(bottom = remember(screenWidth) { screenWidth * 0.05f + spacing.extraLarge })
    ) {
        Image(
            modifier = Modifier
                .width(remember(screenWidth) { screenWidth * 0.44f })
                .offset(y = MaterialTheme.dimens.spacing.extraLarge),
            painter = painterResource(Res.drawable.stamp_1),
            contentDescription = null
        )
        Column(
            modifier = Modifier.padding(
                top = remember(screenWidth) { screenWidth * 0.169f + spacing.extraLarge }
            )
        ) {
            Box(
                modifier = Modifier.fillMaxSize(),
                contentAlignment = Alignment.TopEnd
            ) {
                Image(
                    modifier = Modifier
                        .width(remember(screenWidth) { screenWidth * 0.189f }),
                    painter = painterResource(Res.drawable.stamp_3),
                    contentDescription = null
                )
            }
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        start = MaterialTheme.dimens.spacing.medium,
                        end = MaterialTheme.dimens.spacing.medium,
                        top = MaterialTheme.dimens.spacing.medium,
                    ),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    text = stringResource(Res.string.label_exchange_benefits_1),
                    style = MaterialTheme.typography.bodyMedium,
                    textAlign = TextAlign.Center
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
                Text(
                    modifier = Modifier.fillMaxWidth(0.6f),
                    text = stringResource(Res.string.label_exchange_benefits_2),
                    style = MaterialTheme.typography.headlineMedium,
                    textAlign = TextAlign.Center
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))

                Text(
                    text = stringResource(Res.string.label_exchange_benefits_3),
                    style = MaterialTheme.typography.bodyMedium
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
                PrimaryButton(
                    onPurchaseClick = component.onMentoryClick,
                    textStyle = MaterialTheme.typography.bodyMedium
                )
            }
            Box(
                modifier = Modifier.fillMaxWidth()
            ) {
                Image(
                    modifier = Modifier
                        .width(remember(screenWidth) { screenWidth * 0.256f })
                        .offset(y = MaterialTheme.dimens.spacing.large),
                    painter = painterResource(Res.drawable.stamp_4),
                    contentDescription = null
                )
                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.TopEnd
                ) {
                    Image(
                        modifier = Modifier
                            .width(remember(screenWidth) { screenWidth * 0.225f })
                            .offset(y = -MaterialTheme.dimens.spacing.medium,
                                x = remember(screenWidth) { screenWidth * 0.066f }),
                        painter = painterResource(Res.drawable.stamp_2),
                        contentDescription = null
                    )
                }
            }



        }
    }
}
