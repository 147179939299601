package features.mentory.web

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

@Composable
fun BonusContainer(
    modifier: Modifier,
    headColor: Color = MaterialTheme.colorScheme.primary,
    bodyColor: Color = MaterialTheme.colorScheme.secondary,
    dividerColor: Color = MaterialTheme.colorScheme.inverseOnSurface,
    cornerRadius: Dp = 40.dp,
    headContent: @Composable () -> Unit = {},
    bodyContent: @Composable () -> Unit = {},
) {
    val radius = with(LocalDensity.current) { cornerRadius.toPx() }
    Row(
        modifier = modifier
            .drawBehind {
                drawRoundRect(
                    color = bodyColor,
                    size = Size(size.width, size.height),
                    cornerRadius = CornerRadius(radius, radius)
                )

                val coverWidth = size.width * 0.3f
                val decorOvalRadius = size.width * 0.021f
                val hatPath = Path().apply {
                    moveTo(radius, 0f)
                    lineTo(coverWidth, 0f)

                    quadraticBezierTo(
                        x1 = coverWidth + (coverWidth * 0.08f),
                        y1 = size.height / 6,
                        x2 = coverWidth,
                        y2 = size.height / 3
                    )
                    quadraticBezierTo(
                        x1 = coverWidth - (coverWidth * 0.10f),
                        y1 = size.height / 2,
                        x2 = coverWidth,
                        y2 = size.height / 3 * 2
                    )
                    quadraticBezierTo(
                        x1 = coverWidth + (coverWidth * 0.08f),
                        y1 = size.height / 6 * 5,
                        x2 = coverWidth,
                        y2 = size.height
                    )
                    lineTo(radius, size.height)
                    quadraticBezierTo(
                        x1 = 0f,
                        y1 = size.height,
                        x2 = 0f,
                        y2 = size.height - radius
                    )
                    lineTo(0f, radius)
                    quadraticBezierTo(
                        x1 = 0f,
                        y1 = 0f,
                        x2 = radius,
                        y2 = 0f
                    )
                    close()

                }

                drawPath(
                    path = hatPath,
                    color = headColor,
                )


                drawOval(
                    color = dividerColor,
                    topLeft = Offset(
                        x = coverWidth - decorOvalRadius,
                        y = -decorOvalRadius
                    ),
                    size = Size(decorOvalRadius * 2, decorOvalRadius * 2)
                )

                drawOval(
                    color = dividerColor,
                    topLeft = Offset(
                        x = coverWidth - decorOvalRadius,
                        y = size.height - decorOvalRadius
                    ),
                    size = Size(decorOvalRadius * 2, decorOvalRadius * 2)
                )


                val thinDivider = Path().apply {
                    moveTo(coverWidth, 0f)

                    quadraticBezierTo(
                        x1 = coverWidth + (coverWidth * 0.08f),
                        y1 = size.height / 6,
                        x2 = coverWidth,
                        y2 = size.height / 3
                    )
                    quadraticBezierTo(
                        x1 = coverWidth - (coverWidth * 0.10f),
                        y1 = size.height / 2,
                        x2 = coverWidth,
                        y2 = size.height / 3 * 2
                    )
                    quadraticBezierTo(
                        x1 = coverWidth + (coverWidth * 0.08f),
                        y1 = size.height / 6 * 5,
                        x2 = coverWidth,
                        y2 = size.height
                    )

                }
                drawPath(thinDivider, dividerColor, style = Stroke(2f))
                val dashedDivider = Path().apply {
                    moveTo(coverWidth - 2f, 0f)

                    quadraticBezierTo(
                        x1 = coverWidth + (coverWidth * 0.08f) - 2f,
                        y1 = size.height / 6,
                        x2 = coverWidth - 2f,
                        y2 = size.height / 3
                    )
                    quadraticBezierTo(
                        x1 = coverWidth - (coverWidth * 0.10f) - 2f,
                        y1 = size.height / 2,
                        x2 = coverWidth - 2f,
                        y2 = size.height / 3 * 2
                    )
                    quadraticBezierTo(
                        x1 = coverWidth + (coverWidth * 0.08f) - 2f,
                        y1 = size.height / 6 * 5,
                        x2 = coverWidth - 2f,
                        y2 = size.height
                    )

                }
                val pathEffect = PathEffect.dashPathEffect(floatArrayOf(16f, 16f), 0f)
                drawPath(
                    dashedDivider,
                    dividerColor,
                    style = Stroke(3f, pathEffect = pathEffect)
                )
            }
            .padding(cornerRadius / 3),
        verticalAlignment = Alignment.Bottom
    ) {
        Box(
            modifier = Modifier
                .fillMaxHeight()
                .weight(0.23f),
            contentAlignment = Alignment.BottomStart
        ) {
            headContent()
        }

        Spacer(modifier = Modifier.weight(0.1f))

        Box(
            modifier = Modifier
                .fillMaxHeight()
                .weight(0.67f)
        ) {
            bodyContent()
        }
    }
}