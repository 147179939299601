package features.landing.ui.web

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import features.common.PrimaryButton
import features.common.SecondaryButton
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_buy
import passaporteaupair.composeapp.generated.resources.action_learn_more
import passaporteaupair.composeapp.generated.resources.checkmark
import passaporteaupair.composeapp.generated.resources.label_popular
import theme.dimens
import utils.dashedBorder

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ProductItem(
    modifier: Modifier,
    title: String,
    desc: String,
    benefits: List<String>,
    color: Color,
    isPopular: Boolean,
    onPrimaryButtonClick: () -> Unit,
    onSecondaryButtonClick: () -> Unit,
) {
    Box(
        modifier = modifier,
        contentAlignment = Alignment.TopCenter
    ) {

        Column(
            modifier = Modifier.fillMaxWidth()
        ) {

            if (isPopular) {
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))
            }

            Column(
                modifier = Modifier.fillMaxWidth()
                    .dashedBorder(
                        strokeWidth = 1.dp,
                        color = if (isPopular) color else Color(213, 213, 213),
                        cornerRadiusDp = 50.dp
                    )
                    .padding(
                        horizontal = MaterialTheme.dimens.spacing.large,
                        vertical = MaterialTheme.dimens.spacing.xxLarge,
                    )
            ) {
                Text(
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center,
                    text = title,
                    style = MaterialTheme.typography.headlineSmall,
                    fontSize = MaterialTheme.dimens.fontSize.headlineLargerSmall
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
                Text(
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center,
                    text = desc,
                    style = MaterialTheme.typography.bodyLarge
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
                benefits.forEach { text ->
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Icon(
                            modifier = Modifier.size(MaterialTheme.dimens.iconSize.productCharacteristics),
                            painter = painterResource(Res.drawable.checkmark),
                            tint = color,
                            contentDescription = null,
                        )

                        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.small))
                        Text(
                            modifier = Modifier.weight(1f, true),
                            text = text,
                            style = MaterialTheme.typography.bodyMedium
                        )
                    }

                    Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
                }
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
                PrimaryButton(
                    modifier = Modifier.fillMaxWidth(),
                    text = stringResource(Res.string.action_buy),
                    buttonColors = ButtonDefaults.buttonColors(containerColor = color),
                    textColor = MaterialTheme.colorScheme.onBackground,
                    onPurchaseClick = onPrimaryButtonClick
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
                SecondaryButton(
                    modifier = Modifier.fillMaxWidth(),
                    text = stringResource(Res.string.action_learn_more),
                    buttonColors = ButtonDefaults.buttonColors(
                        containerColor = MaterialTheme.colorScheme.background
                    ),
                    strokeColor = color,
                    textColor = color,
                    onPurchaseClick = onSecondaryButtonClick
                )
            }
        }
        if (isPopular) {
            Text(
                modifier = Modifier
                    .background(MaterialTheme.colorScheme.background)
                    .dashedBorder(
                        strokeWidth = 1.dp,
                        color = color,
                        cornerRadiusDp = 50.dp
                    )
                    .padding(
                        horizontal = MaterialTheme.dimens.spacing.extraLarge,
                        vertical = MaterialTheme.dimens.spacing.largerSmall,
                    ),
                text = stringResource(Res.string.label_popular),
                style = MaterialTheme.typography.bodyLarge,
                fontWeight = FontWeight.ExtraBold,
                color = color
            )

        }
    }
}