package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.platform.LocalUriHandler
import features.common.Circle
import features.common.DashedCircle
import features.common.INTRO_LINK
import features.common.PrimaryButton
import features.landing.LandingComponent
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.cd_play_intro
import passaporteaupair.composeapp.generated.resources.home_section_description
import passaporteaupair.composeapp.generated.resources.label_play_intro
import passaporteaupair.composeapp.generated.resources.mentor_image
import passaporteaupair.composeapp.generated.resources.play
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun IntroDesc(component: LandingComponent) {
    val screenWidth = screenWidth
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.CenterStart
    ) {
        val uriHandler = LocalUriHandler.current

        val imageSize = remember(screenWidth) { screenWidth * 0.64f }
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {

            Column(
                modifier = Modifier
                    .weight(1f, true)
                    .padding(start = MaterialTheme.dimens.spacing.medium),

                ) {
                Text(
                    modifier = Modifier.fillMaxWidth(),
                    style = MaterialTheme.typography.bodyLarge,
                    text = stringResource(Res.string.home_section_description)
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))

                PrimaryButton(
                    textStyle = MaterialTheme.typography.bodyMedium,
                    onPurchaseClick = component.onMentoryClick
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(
                        modifier = Modifier
                            .size(MaterialTheme.dimens.iconSize.playButtonIconSize)
                            .clip(CircleShape)
                            .clickable { uriHandler.openUri(INTRO_LINK) },
                        painter = painterResource(Res.drawable.play),
                        contentDescription = stringResource(Res.string.cd_play_intro)
                    )
                    Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.medium))
                    Text(
                        text = stringResource(Res.string.label_play_intro),
                        style = MaterialTheme.typography.labelMedium,
                        color = MaterialTheme.colorScheme.onSurfaceVariant
                    )

                }

            }
            Spacer(modifier = Modifier.width(imageSize / 2))

        }
        Box(
            modifier = Modifier
//                    .size(remember(screenWidth) { screenWidth * 0.82f })
                .size(imageSize)
                .offset(remember(screenWidth) { screenWidth - (imageSize / 2) })
        ) {
            Circle(
                modifier = Modifier
                    .offset(x = remember(imageSize) { imageSize * 0.1f }),
                radius = remember(imageSize) { imageSize * 0.116f },
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.secondary)
            )
            DashedCircle(
                modifier = Modifier
                    .offset(x = remember(imageSize) { imageSize * 0.069f }),
                radius = remember(imageSize) { imageSize * 0.116f },
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onBackground),
            )
            Image(
                modifier = Modifier
                    .fillMaxSize()
                    .clip(CircleShape),
                painter = painterResource(Res.drawable.mentor_image),
                contentDescription = null
            )
        }
    }
}

