package features.mentory.adaptive

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import features.common.MENTORY_PURCHASE_LINK
import features.common.PrimaryButton
import features.mentory.web.MentorySubject
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.mentory_action_buy
import passaporteaupair.composeapp.generated.resources.mentory_bank
import passaporteaupair.composeapp.generated.resources.mentory_globe
import passaporteaupair.composeapp.generated.resources.mentory_graph
import passaporteaupair.composeapp.generated.resources.mentory_hat
import passaporteaupair.composeapp.generated.resources.mentory_headphone
import passaporteaupair.composeapp.generated.resources.mentory_house
import passaporteaupair.composeapp.generated.resources.mentory_message
import passaporteaupair.composeapp.generated.resources.mentory_paper
import passaporteaupair.composeapp.generated.resources.mentory_passport
import passaporteaupair.composeapp.generated.resources.mentory_product_items
import passaporteaupair.composeapp.generated.resources.mentory_product_title
import passaporteaupair.composeapp.generated.resources.mentory_star
import passaporteaupair.composeapp.generated.resources.mentory_video_cam
import passaporteaupair.composeapp.generated.resources.mentory_whatsapp
import theme.dimens

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryItemsAdaptive(){
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(
                top = MaterialTheme.dimens.spacing.extraLarge * 2,
                bottom = MaterialTheme.dimens.spacing.extraLarge,
                start = MaterialTheme.dimens.spacing.medium,
                end = MaterialTheme.dimens.spacing.medium
            )
    ) {
        Text(
            text = stringResource(Res.string.mentory_product_title),
            style = MaterialTheme.typography.headlineMedium,
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))

        val icons = remember {
            listOf(
                Res.drawable.mentory_globe,
                Res.drawable.mentory_headphone,
                Res.drawable.mentory_message,
                Res.drawable.mentory_star,
                Res.drawable.mentory_house,
                Res.drawable.mentory_bank,
                Res.drawable.mentory_paper,
                Res.drawable.mentory_whatsapp,
                Res.drawable.mentory_hat,
                Res.drawable.mentory_passport,
                Res.drawable.mentory_graph,
                Res.drawable.mentory_video_cam,
            )
        }

        stringArrayResource(Res.array.mentory_product_items).forEachIndexed { index, text ->
            MentorySubject(
                iconRes = icons[index],
                text = text
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
        }
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))
        val uriHandler = LocalUriHandler.current
        PrimaryButton(
            modifier = Modifier.fillMaxWidth(),
            text = stringResource(Res.string.mentory_action_buy)
        ) {
            uriHandler.openUri(MENTORY_PURCHASE_LINK)
        }

    }
}