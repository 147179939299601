package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Dp
import features.common.FooterContent
import features.common.web.ImageActionButton
import features.common.LandingFooterDivider
import features.common.WHATS_APP_CONTACT_LINK
import features.common.adaptive.ContactSectionAdaptive
import features.common.web.ImageActionButtons
import features.common.web.TextActionButton
import features.common.web.TextActionButtons
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.decorative_line_5
import passaporteaupair.composeapp.generated.resources.lagguage_2
import passaporteaupair.composeapp.generated.resources.logo
import passaporteaupair.composeapp.generated.resources.map
import passaporteaupair.composeapp.generated.resources.ticket
import theme.dimens

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun LandingFooterAdaptive(
    showCloud:Boolean,
    textActionButtons: List<TextActionButton>,
    imageActionButtons: List<ImageActionButton>
) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    val uriHandler = LocalUriHandler.current
    ContactSectionAdaptive(showCloud = showCloud){
        uriHandler.openUri(WHATS_APP_CONTACT_LINK)
    }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(MaterialTheme.colorScheme.surface)
            .padding(bottom = MaterialTheme.dimens.spacing.extraLarge * 2),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
        LandingFooterDividerAdaptive(screenWidth)
        FooterContentAdaptive(
            screenWidth = screenWidth,
            textActionButtons = textActionButtons,
            imageActionButtons = imageActionButtons
        )

    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ColumnScope.FooterContentAdaptive(
    screenWidth: Dp,
    textActionButtons: List<TextActionButton>,
    imageActionButtons: List<ImageActionButton>,
) {
    Image(
        modifier = Modifier.width(screenWidth * 0.33f),
        painter = painterResource(Res.drawable.logo),
        contentDescription = null
    )
    Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
//    Row {
    TextActionButtons(textActionButtons)
//    }
    Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
    Row {
        ImageActionButtons(imageActionButtons)
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun LandingFooterDividerAdaptive(screenWidth: Dp) {
    Box(modifier = Modifier.fillMaxWidth()) {

        Image(
            modifier = Modifier.fillMaxWidth()
                .offset(y = screenWidth * 0.18f),
            painter = painterResource(Res.drawable.decorative_line_5),
            contentDescription = null
        )
        Image(
            modifier = Modifier.width(screenWidth * 0.23f)
                .offset(y = screenWidth * 0.085f),
            painter = painterResource(Res.drawable.lagguage_2),
            contentDescription = null
        )
//        Box(
//            modifier = Modifier.fillMaxWidth(),
//            contentAlignment = Alignment.TopEnd
//        ) {
            Image(
                modifier = Modifier.width(screenWidth * 0.479f)
                    .offset(
//                        y = screenWidth * 0.024f,
                        x = screenWidth * 0.70f
                    ),
                painter = painterResource(Res.drawable.map),
                contentDescription = null
            )
            Image(
                modifier = Modifier.width(screenWidth * 0.248f)
                    .offset(x = screenWidth * 0.658f),
                painter = painterResource(Res.drawable.ticket),
                contentDescription = null
            )
//        }
    }
}