package features.common.web

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.ColorFilter
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.cd_instagram
import passaporteaupair.composeapp.generated.resources.cd_logo
import passaporteaupair.composeapp.generated.resources.logo
import theme.LocalDimens
import theme.dimens
import utils.screenWidth


@OptIn(ExperimentalResourceApi::class)
@Composable
fun Toolbar(
    textActionButtons: List<TextActionButton>,
    imageActionButtons: List<ImageActionButton>
) {
    val screenWidth = screenWidth
    val horizontalPadding = remember(screenWidth) {
        screenWidth * 0.062f
    }
    Row(
        modifier = Modifier.fillMaxWidth()
            .padding(
                horizontal = horizontalPadding,
                vertical = MaterialTheme.dimens.spacing.extraLarge
            ),
    ) {
        Image(
            modifier = Modifier.width(LocalDimens.current.iconSize.logoWidth),
            painter = painterResource(Res.drawable.logo),
            contentDescription = stringResource(Res.string.cd_logo)
        )
        Spacer(Modifier.weight(1.0f, true))
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            TextActionButtons(textActionButtons)
            Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.xxLarge))
            ImageActionButtons(imageActionButtons)
        }
    }
}

data class TextActionButton @OptIn(ExperimentalResourceApi::class) constructor(
    val label: StringResource,
    val action: () -> Unit
)

@OptIn(ExperimentalResourceApi::class)
@Composable
fun TextActionButtons(
    actionButtons: List<TextActionButton>
) {
    actionButtons.forEachIndexed { index, actionButton ->
        if (index != 0) {
            Spacer(modifier = Modifier.size(MaterialTheme.dimens.spacing.medium))
        }
        TextButton(onClick = actionButton.action) {
            Text(
                text = stringResource(actionButton.label),
                style = MaterialTheme.typography.bodyMedium
            )
        }
    }
}

data class ImageActionButton @OptIn(ExperimentalResourceApi::class) constructor(
    val icon: DrawableResource,
    val action: () -> Unit
)

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ImageActionButtons(
    actionButtons: List<ImageActionButton>
) {
    actionButtons.forEachIndexed { index, actionButton ->
        if (index != 0) {
            Spacer(modifier = Modifier.size(MaterialTheme.dimens.spacing.medium))
        }
        Image(
            modifier = Modifier
                .size(MaterialTheme.dimens.iconSize.toolbarIconSize)
                .padding(MaterialTheme.dimens.spacing.small)
                .clickable { actionButton.action() },
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary),
            painter = painterResource(actionButton.icon),
            contentDescription = stringResource(Res.string.cd_instagram)
        )
    }
}