package features.mentory.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.dp
import features.common.FooterContent
import features.common.web.ImageActionButton
import features.common.web.TextActionButton
import features.landing.ui.adaptive.FooterContentAdaptive
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.cloud_9
import theme.dimens

@OptIn(ExperimentalComposeUiApi::class, ExperimentalResourceApi::class)
@Composable
fun MentoryFooterAdaptive(
    textActionButtons: List<TextActionButton>,
    imageActionButtons: List<ImageActionButton>
) {

    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    val spacing = MaterialTheme.dimens.spacing
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .background(MaterialTheme.colorScheme.surface)
    ) {
        Image(
            modifier = Modifier
                .width(380.dp)
                .offset(y = -70.dp),
            painter = painterResource(Res.drawable.cloud_9),
            contentDescription = null,
        )
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
                top = remember(screenWidth) { screenWidth * 0.138f + spacing.extraLarge },
                bottom = remember(screenWidth) { screenWidth * 0.138f + spacing.extraLarge },
            ),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FooterContentAdaptive(
                screenWidth = screenWidth,
                textActionButtons = textActionButtons,
                imageActionButtons = imageActionButtons
            )
        }
    }
}