package theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

val LocalDimens = compositionLocalOf { Dimens() }

val MaterialTheme.dimens: Dimens
    @Composable
    @ReadOnlyComposable
    get() = LocalDimens.current

data class Dimens(
    val spacing: Spacing = Spacing(),
    val fontSize: FontSize = FontSize(),
    val fontLineHeight: FontLineHeight = FontLineHeight(),
    val iconSize: IconSize = IconSize(),
    val components: ComponentsDimens = ComponentsDimens(),
)

//Spacing, corner radius, elevation
data class ComponentsDimens(
    val feedbackItemWidth:Dp = 307.dp,
    val feedbackHeight:Dp = 464.dp,
)

data class IconSize(
    val logoWidth: Dp = 200.dp,
    val toolbarIconSize: Dp = 48.dp,
    val playButtonIconSize: Dp = 56.dp,
    val faqExpandIconSize: Dp = 24.dp,
    val mentoryBenefitItemIconSize: Dp = 115.dp,
    val mentoryBonusItemIconSize: Dp = 24.dp,
    val mentoryBonusGiftIconSize: Dp = 62.dp,
    val mentoryItem: Dp = 32.dp,
    val productCharacteristics: Dp = 32.dp,
    val programProsIconSize: Dp = 48.dp,
    val feedbackArrow: Dp = 48.dp,
    )

data class FontLineHeight(
    val bodySmallLineHight: TextUnit = 18.sp,
    val bodyMediumLineHight: TextUnit = 21.sp,
    val bodyLargeLineHight: TextUnit = 24.sp,
    val bodyExtraLargeLineHight: TextUnit = 32.sp,
)

data class FontSize(
    val displaySmall: TextUnit = 40.sp,
    val displayMedium: TextUnit = 64.sp,
    val displayLarge: TextUnit = 96.sp,
    val headlineSmall: TextUnit = 20.sp,
    val headlineLargerSmall: TextUnit = 24.sp,
    val headlineMedium: TextUnit = 48.sp,
    val headlineLarge: TextUnit = 85.sp,
    val titleSmall: TextUnit = 14.sp,
    val titleMedium: TextUnit = 16.sp,
    val titleLarge: TextUnit = 20.sp,
    val bodyMedium: TextUnit = 14.sp,
    val bodySmall: TextUnit = 12.sp,
    val labelMedium: TextUnit = 14.sp,
    val bodyLarge: TextUnit = 16.sp
)

data class Spacing(
    val default: Dp = 0.dp,
    val extraSmall: Dp = 4.dp,
    val small: Dp = 8.dp,
    val largerSmall: Dp = 12.dp,
    val medium: Dp = 16.dp,
    val large: Dp = 24.dp,
    val extraLarge: Dp = 32.dp,
    val xxLarge: Dp = 40.dp,
    val xxxLarge: Dp = 56.dp,
)

val AdaptiveCompactDimens = Dimens(
    spacing = Spacing(
        default = 0.dp,
        extraSmall = 4.dp,
        small = 4.dp,
        medium = 8.dp,
        large = 16.dp,
        extraLarge = 24.dp,
    ),
    fontSize = FontSize(
        displaySmall = 27.sp,
        displayMedium = 42.sp,
        displayLarge = 64.sp,
        headlineSmall = 12.sp,
        headlineMedium = 32.sp,
        headlineLarge = 56.sp,
        titleSmall = 8.sp,
        titleMedium = 10.sp,
        titleLarge = 14.sp,
        bodyMedium = 10.sp,
        bodySmall = 8.sp,
        bodyLarge = 14.sp,
        labelMedium = 10.sp
    ),

    )

val AdaptiveMediumDimens = Dimens(
    fontSize = FontSize(
        displaySmall = 100.sp,
        displayMedium = 36.sp,
        displayLarge = 64.sp,
        headlineSmall = 24.sp,
        headlineMedium = 32.sp,
        headlineLarge = 40.sp,
        titleSmall = 100.sp,
        titleMedium = 100.sp,
        titleLarge = 20.sp,
        bodySmall = 100.sp,
        bodyMedium = 14.sp,
        bodyLarge = 16.sp,
        labelMedium = 13.sp
    ),
    iconSize = IconSize(
        logoWidth = 130.dp,
        playButtonIconSize = 53.dp,
        productCharacteristics = 32.dp,
        mentoryBenefitItemIconSize = 85.dp,
        toolbarIconSize = 40.dp,
        feedbackArrow = 40.dp,
    ),
    components = ComponentsDimens(
        feedbackHeight = 300.dp,
    ),
    spacing = Spacing(
        extraSmall = 6.dp,
        small = 12.dp,
    )
)

val TabletDimens = Dimens(
    fontSize = FontSize(
        displaySmall = 100.sp,
        displayMedium = 54.sp,
        displayLarge = 96.sp,
        headlineSmall = 36.sp,
        headlineMedium = 48.sp,
        headlineLarge = 60.sp,
        titleSmall = 100.sp,
        titleMedium = 100.sp,
        titleLarge = 30.sp,
        bodySmall = 100.sp,
        bodyMedium = 20.sp,
        bodyLarge = 24.sp,
        labelMedium = 18.sp
    ),
    fontLineHeight = FontLineHeight(
        bodySmallLineHight = 24.sp,
        bodyMediumLineHight = 32.sp,
        bodyLargeLineHight = 36.sp
    ),
    iconSize = IconSize(
        logoWidth = 180.dp,
        playButtonIconSize = 84.dp,
        productCharacteristics = 48.dp,
        mentoryBenefitItemIconSize = 172.dp,
        toolbarIconSize = 56.dp,
        feedbackArrow = 56.dp,
        faqExpandIconSize = 36.dp,
        mentoryBonusItemIconSize = 36.dp,
        mentoryBonusGiftIconSize = 94.dp,
        mentoryItem = 40.dp,
        programProsIconSize = 72.dp,
    ),
    spacing = Spacing(
        extraSmall = 6.dp,
        small = 12.dp,
        largerSmall = 18.dp,
        medium = 24.dp,
        large = 36.dp,
        extraLarge = 50.dp,
        xxLarge = 60.dp,
        xxxLarge = 84.dp,
    )
)

val CompactDimens = Dimens(
    spacing = Spacing(
        default = 0.dp,
        extraSmall = 2.dp,
        small = 4.dp,
        medium = 8.dp,
        large = 16.dp,
        extraLarge = 20.dp,
        xxLarge = 34.dp,
    ),
    fontSize = FontSize(
        displaySmall = 27.sp,
        displayMedium = 42.sp,
        displayLarge = 64.sp,
        headlineSmall = 12.sp,
        headlineLargerSmall = 16.sp,
        headlineMedium = 32.sp,
        headlineLarge = 56.sp,
        titleSmall = 8.sp,
        titleMedium = 10.sp,
        titleLarge = 14.sp,
        bodyMedium = 10.sp,
        bodySmall = 8.sp,
        bodyLarge = 14.sp,
        labelMedium = 10.sp
    ),
    iconSize = IconSize(
        toolbarIconSize = 32.dp,
        feedbackArrow = 32.dp,
        logoWidth = 130.dp,
    )
)

val LowerMediumDimens = Dimens(
    spacing = Spacing(
        default = 0.dp,
        extraSmall = 3.dp,
        small = 6.dp,
        medium = 14.dp,
        large = 20.dp,
        extraLarge = 26.dp,
        xxLarge = 32.dp,
    ),
    fontSize = FontSize(
        displaySmall = 32.sp,
        displayMedium = 52.sp,
        displayLarge = 76.sp,
        headlineSmall = 16.sp,
        headlineLargerSmall = 20.sp,
        headlineMedium = 40.sp,
        headlineLarge = 68.sp,
        titleSmall = 12.sp,
        titleMedium = 14.sp,
        titleLarge = 16.sp,
        bodySmall = 10.sp,
        bodyMedium = 12.sp,
        bodyLarge = 14.sp,
        labelMedium = 12.sp
    ),

    iconSize = IconSize(
        toolbarIconSize = 40.dp,
        feedbackArrow = 40.dp,
        logoWidth = 160.dp,
    )
)

val MediumDimens = Dimens()

val ExpandedDimens = Dimens(
    spacing = Spacing(
        default = 0.dp,
        extraSmall = 6.dp,
        small = 10.dp,
        medium = 20.dp,
        large = 28.dp,
        extraLarge = 36.dp,
    ),
)