package features.common

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Dp
import features.common.PrimaryButton
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_button_decoration
import passaporteaupair.composeapp.generated.resources.cloud_7
import passaporteaupair.composeapp.generated.resources.cloud_8
import passaporteaupair.composeapp.generated.resources.decorative_line_4
import passaporteaupair.composeapp.generated.resources.flying_rocket
import passaporteaupair.composeapp.generated.resources.label_decoration
import passaporteaupair.composeapp.generated.resources.stars
import theme.dimens

@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun DecorativeSection(
    screenWidth: Dp,
    showLineDecoration: Boolean,
    onClick: () -> Unit,

    ) {
    Box(
        modifier = Modifier.fillMaxWidth()
            .background(MaterialTheme.colorScheme.surface)
    ) {
        Image(
            modifier = Modifier.width(screenWidth * 0.179f)
                .offset(y = -screenWidth * 0.075f),
            painter = painterResource(Res.drawable.cloud_7),
            contentDescription = null
        )
        Image(
            modifier = Modifier.fillMaxWidth()
                .padding(
                    top = screenWidth * 0.024f,
                    start = screenWidth * 0.062f,
                    end = screenWidth * 0.014f,
                ),
            painter = painterResource(Res.drawable.stars),
            contentDescription = null
        )
        Image(
            modifier = Modifier
                .width(screenWidth * 0.23f)
                .offset(
                    x = screenWidth * 0.097f,
                    y = screenWidth * 0.065f
                ),
            painter = painterResource(Res.drawable.flying_rocket),
            contentDescription = null
        )

        Box(
            modifier = Modifier.matchParentSize(),
            contentAlignment = Alignment.BottomEnd
        ) {
            Image(
                modifier = Modifier
                    .width(screenWidth * 0.309f)
                    .offset(y = screenWidth * 0.053f),
                painter = painterResource(Res.drawable.cloud_8),
                contentDescription = null
            )
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
                    vertical = MaterialTheme.dimens.spacing.extraLarge + (screenWidth * 0.073f)
                ),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                text = stringResource(Res.string.label_decoration),
                style = MaterialTheme.typography.headlineSmall
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
            PrimaryButton(
                text = stringResource(Res.string.action_button_decoration),
                onPurchaseClick = onClick
            )
        }

        if (showLineDecoration) {
            Box(
                modifier = Modifier.matchParentSize(),
                contentAlignment = Alignment.TopEnd
            ) {
                Image(
                    modifier = Modifier
                        .width(screenWidth * 0.198f)
                        .offset(
                            x = screenWidth * 0.041f,
                            y = -screenWidth * 0.265f
                        ),
                    painter = painterResource(Res.drawable.decorative_line_4),
                    contentDescription = null
                )
            }
        }
    }
}
