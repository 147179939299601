package features.mentory.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import features.common.Circle
import features.common.DashedCircle
import features.mentory.web.MentoryBonusItem
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.gift
import passaporteaupair.composeapp.generated.resources.mentory_product_bonus_title
import passaporteaupair.composeapp.generated.resources.mentory_product_bonuses
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryBonusesAdaptive() {
    val spacing = MaterialTheme.dimens.spacing
    val screenWidth = screenWidth
    val circleRadius = remember(screenWidth) { screenWidth * 0.258f }
    val lowerCircleOffsetY = remember(circleRadius) { circleRadius * 1.168f }
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(bottom = lowerCircleOffsetY),
        contentAlignment = Alignment.BottomEnd
    ) {

        Circle(
            modifier = Modifier
                .offset(
                    x = remember(circleRadius) { circleRadius * 0.693f },
                    y = lowerCircleOffsetY
                ),
            radius = circleRadius,
            color = MaterialTheme.colorScheme.primary
        )
        DashedCircle(
            modifier = Modifier
                .offset(
                    x = remember(circleRadius) { circleRadius * 0.495f },
                    y = remember(circleRadius) { circleRadius * 0.95f }
                ),
            radius = circleRadius
        )

        BonusContainerVertical(
            modifier = Modifier
                .padding(
                    top = MaterialTheme.dimens.spacing.xxxLarge,
                    start = MaterialTheme.dimens.spacing.large,
                    end = MaterialTheme.dimens.spacing.large,
                ),
            headColor = MaterialTheme.colorScheme.primary,
            bodyColor = Color(255, 237, 229, 230),
            dividerColor = MaterialTheme.colorScheme.background,
            cornerRadius = 120.dp,
            paddingTop = MaterialTheme.dimens.spacing.xxxLarge,
            paddingStart = MaterialTheme.dimens.spacing.large,
            paddingEnd = MaterialTheme.dimens.spacing.large,
            paddingBottom = MaterialTheme.dimens.spacing.xxxLarge,
            headContent = {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(
                            top = MaterialTheme.dimens.spacing.xxxLarge,
                            start = MaterialTheme.dimens.spacing.large,
                            end = MaterialTheme.dimens.spacing.large,
                            bottom = MaterialTheme.dimens.spacing.small,
                        ),
                    verticalArrangement = Arrangement.Bottom
                ) {

                    Spacer(modifier = Modifier.weight(1f, true))
                    Image(
                        modifier = Modifier.size(MaterialTheme.dimens.iconSize.mentoryBonusGiftIconSize),
                        painter = painterResource(Res.drawable.gift),
                        contentDescription = null
                    )
                    Spacer(modifier = Modifier.height(spacing.medium))
                    Text(
                        text = stringResource(Res.string.mentory_product_bonus_title),
                        style = MaterialTheme.typography.headlineMedium,
                    )
                }
            },
            bodyContent = {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(
                            top = MaterialTheme.dimens.spacing.medium,
                            bottom = MaterialTheme.dimens.spacing.large,
                        ),
                ) {
                    stringArrayResource(Res.array.mentory_product_bonuses).forEach {
                        MentoryBonusItem(it)
                        Spacer(modifier = Modifier.height(spacing.medium))
                    }
                }
            },
        )
    }
}