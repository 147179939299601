package features.language_portal.ui.web

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import features.common.Circle
import features.common.DashedCircle
import features.common.LANGUAGE_PORTAL_PURCHASE_LINK
import features.common.ModuleItem
import features.common.PrimaryButton
import navigation.LanguagePortalComponent
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_buy_language_portal
import passaporteaupair.composeapp.generated.resources.action_buy_language_portal_price
import passaporteaupair.composeapp.generated.resources.aim_simple
import passaporteaupair.composeapp.generated.resources.engine
import passaporteaupair.composeapp.generated.resources.label_language_portal_details_1
import passaporteaupair.composeapp.generated.resources.label_language_portal_details_2
import passaporteaupair.composeapp.generated.resources.language_portal_module_1_items
import passaporteaupair.composeapp.generated.resources.language_portal_module_2_items
import passaporteaupair.composeapp.generated.resources.language_portal_module_3_items
import passaporteaupair.composeapp.generated.resources.product_image_language_portal
import passaporteaupair.composeapp.generated.resources.rocket_simple
import passaporteaupair.composeapp.generated.resources.subtitle_language_portal_module_1
import passaporteaupair.composeapp.generated.resources.subtitle_language_portal_module_2
import passaporteaupair.composeapp.generated.resources.subtitle_language_portal_module_3
import passaporteaupair.composeapp.generated.resources.title_language_portal_module_1
import passaporteaupair.composeapp.generated.resources.title_language_portal_module_2
import passaporteaupair.composeapp.generated.resources.title_language_portal_module_3
import theme.dimens
import utils.screenWidth

@Composable
fun LanguagePortalDescription(
    component: LanguagePortalComponent
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    val uriHandler = LocalUriHandler.current
    val horizontalPadding = remember(screenWidth) { screenWidth * 0.079f }
    Column {
        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(start = horizontalPadding),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(0.48f),
            ) {
                Text(
                    text = stringResource(Res.string.label_language_portal_details_1),
                    style = MaterialTheme.typography.headlineMedium
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium * 3))
                Text(
                    text = stringResource(Res.string.label_language_portal_details_2),
                    style = MaterialTheme.typography.bodyMedium
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium * 3))
                PrimaryButton(
                    text = buildAnnotatedString {
                        withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
                            append(stringResource(Res.string.action_buy_language_portal))
                        }
                        withStyle(SpanStyle(fontWeight = FontWeight.Bold)) {
                            append(stringResource(Res.string.action_buy_language_portal_price))
                        }
                    },
                    onPurchaseClick = {
                        uriHandler.openUri(LANGUAGE_PORTAL_PURCHASE_LINK)
                    }
                )
            }
            Spacer(modifier = Modifier.weight(1f, true))
            Box(
                contentAlignment = Alignment.TopEnd
            ) {
                Circle(
                    modifier = Modifier
                        .offset(
                            x = remember(screenWidth) { screenWidth * 0.078f },
                            y = remember(screenWidth) { screenWidth * 0.01f }
                        ),
                    radius = remember(screenWidth) { screenWidth * 0.117f },
                    color = MaterialTheme.colorScheme.primary
                )
                DashedCircle(
                    modifier = Modifier
                        .offset(
                            x = remember(screenWidth) { screenWidth * 0.031f },
                            y = remember(screenWidth) { screenWidth * 0.009f }
                        ),
                    radius = remember(screenWidth) { screenWidth * 0.105f }
                )
                Image(
                    modifier = Modifier
                        .width(remember(screenWidth) { screenWidth * 0.38f })
                        .offset(x = remember(screenWidth) { -screenWidth * 0.019f }),
                    painter = painterResource(Res.drawable.product_image_language_portal),
                    contentDescription = null
                )
            }
        }
    }
    Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.extraLarge))
    Row(
        modifier = Modifier.fillMaxWidth()
            .padding(
                start = horizontalPadding,
                end = horizontalPadding,
                bottom = remember(screenWidth) { screenWidth * 0.056f+spacing.extraLarge },
            )
    ) {
        Box(
            modifier = Modifier.weight(1f, false)
        ) {
            ModuleItem(
                modifier = Modifier.fillMaxWidth(),
                icon = Res.drawable.aim_simple,
                moduleSubtitle = stringResource(Res.string.subtitle_language_portal_module_1),
                moduleTitle = null,
                moduleItems = stringArrayResource(Res.array.language_portal_module_1_items)
            )
        }
        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.extraLarge))
        Box(
            modifier = Modifier.weight(1f, false)
                .padding(top = remember(screenWidth) { screenWidth * 0.083f})
        ) {
            ModuleItem(
                modifier = Modifier.fillMaxWidth(),
                icon = Res.drawable.engine,
                moduleSubtitle = stringResource(Res.string.subtitle_language_portal_module_2),
                moduleTitle = null,
                moduleItems = stringArrayResource(Res.array.language_portal_module_2_items)
            )
        }
        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.extraLarge))
        Box(
            modifier = Modifier.weight(1f, false)
                .padding(top = remember(screenWidth) { screenWidth * 0.166f})
        ) {
            ModuleItem(
                modifier = Modifier.fillMaxWidth(),
                icon = Res.drawable.rocket_simple,
                moduleSubtitle = stringResource(Res.string.subtitle_language_portal_module_3),
                moduleTitle = null,
                moduleItems = stringArrayResource(Res.array.language_portal_module_3_items)
            )
        }
    }
}

