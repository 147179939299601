package features.common

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.size
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.drawscope.Fill
import androidx.compose.ui.unit.Dp

@Composable
fun Circle(
    radius: Dp,
    modifier: Modifier = Modifier,
    color: Color = MaterialTheme.colorScheme.onBackground,
    colorFilter: ColorFilter? = null,
) {
    Canvas(
        modifier = modifier.size(radius * 2),
        onDraw = {
            val radiusPx = radius.toPx()

            drawCircle(
                colorFilter = colorFilter,
                center = Offset(radiusPx, radiusPx),
                color = color,
                radius = radiusPx,
                style = Fill
            )
        }
    )
}



