import androidx.compose.ui.graphics.Color

val md_theme_light_primary = Color(255, 138, 91)
val md_theme_light_secondary = Color(11, 191, 156)
val md_theme_light_onBackground = Color(32, 32, 32)
val md_theme_light_onPrimary = Color(32, 32, 32)
val md_theme_light_background =  Color(255, 255, 255)
val md_theme_light_surface = Color(255, 249, 243)
val md_theme_light_onSurface = Color(32, 32, 32)
val md_theme_light_onSurfaceVariant = Color(144, 144, 144)

//val md_theme_light_primaryContainer = Color(255, 249, 243)
//
//val md_theme_light_onPrimaryContainer = Color(255, 138, 91)
//val md_theme_light_onSecondary = Color(0xFFFFFFFF)
//val md_theme_light_secondaryContainer = Color(0xFFDDE1FF)
//val md_theme_light_onSecondaryContainer = Color(0xFF001356)
//val md_theme_light_tertiary = Color(0xFF76546E)
//val md_theme_light_onTertiary = Color(0xFFFFFFFF)
//val md_theme_light_tertiaryContainer = Color(0xFFFFD7F3)
//val md_theme_light_onTertiaryContainer = Color(0xFF2C1229)
//val md_theme_light_error = Color(0xFFBA1A1A)
//val md_theme_light_errorContainer = Color(0xFFFFDAD6)
//val md_theme_light_onError = Color(0xFFFFFFFF)
//
//val md_theme_light_onErrorContainer = Color(0xFF410002)
//
//val md_theme_light_surface =  Color(32, 32, 32)
//val md_theme_light_surfaceVariant = Color(0xFFE2E1EC)
//val md_theme_light_onSurfaceVariant = Color(144, 144, 144)
//
//val md_theme_light_outline = Color(0xFF767680)
//val md_theme_light_inverseOnSurface = Color(0xFFF2F0F4)
//val md_theme_light_inverseSurface = Color(0xFF303034)
//val md_theme_light_inversePrimary = Color(0xFFB8C4FF)
//val md_theme_light_shadow = Color(0xFF000000)
//val md_theme_light_surfaceTint = Color(0xFF204EE3)
//val md_theme_light_outlineVariant = Color(0xFFC6C5D0)
//val md_theme_light_scrim = Color(0xFF000000)





