package features.mentory.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import features.mentory.web.requirementsString
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.boat
import passaporteaupair.composeapp.generated.resources.decorative_line_2
import passaporteaupair.composeapp.generated.resources.decorative_line_3
import passaporteaupair.composeapp.generated.resources.label_requirements_1
import passaporteaupair.composeapp.generated.resources.label_requirements_2
import passaporteaupair.composeapp.generated.resources.mentor_image_3
import passaporteaupair.composeapp.generated.resources.mentory_requirements
import theme.dimens
import utils.screenWidth
import utils.toBulletPoints

@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun RequirementsAdaptive() {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing

    Column(
        modifier = Modifier.fillMaxWidth()
    ) {

        Text(
            modifier = Modifier
                .fillMaxWidth(0.8f)
                .padding(horizontal = MaterialTheme.dimens.spacing.medium),
            text = stringResource(Res.string.label_requirements_1),
            style = MaterialTheme.typography.headlineMedium
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))

//        .forEach {
        Text(
            modifier = Modifier.padding(horizontal = MaterialTheme.dimens.spacing.medium),
            text = requirementsString,
            style = MaterialTheme.typography.bodyMedium
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))

//        }


        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                modifier = Modifier.fillMaxHeight().weight(1f, true),
            ) {
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = MaterialTheme.dimens.spacing.large),
                    text = stringResource(Res.string.label_requirements_2),
                    style = MaterialTheme.typography.bodyLarge,
                    fontWeight = FontWeight.Bold
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))

                Box(
                    contentAlignment = Alignment.CenterStart
                ) {
//                    val imageSize = remember(screenWidth) { screenWidth * 0.34f }
                    val imageSize = 134.dp
                    Image(
                        modifier = Modifier.width(imageSize),
                        painter = painterResource(Res.drawable.boat),
                        contentDescription = null
                    )
                    Image(
                        modifier = Modifier
                            .offset(
                                x = imageSize,
                            ),
                        painter = painterResource(Res.drawable.decorative_line_3),
                        contentDescription = null
                    )
                }
            }

            Column(
                modifier = Modifier
//                    .fillMaxWidth(0.52f)
                    .offset(
                        x = remember(screenWidth) { screenWidth * 0.036f },
                    ),
                horizontalAlignment = Alignment.End
            ) {
                Image(
                    modifier = Modifier
                        .width(remember(screenWidth) { screenWidth * 0.187f }),
//                        .offset(y = remember(screenWidth) { screenWidth * 0.026f }),
                    painter = painterResource(Res.drawable.decorative_line_2),
                    contentDescription = null
                )
                Image(
                    modifier = Modifier
                        .width(205.dp)
                        .offset(
//                            x = remember(screenWidth) { screenWidth * 0.036f },
                            y = -MaterialTheme.dimens.spacing.medium
                        )
                        .clip(RoundedCornerShape(remember(screenWidth) { screenWidth * 0.3f })),
                    painter = painterResource(Res.drawable.mentor_image_3),
                    contentDescription = null
                )
            }

        }

    }

}
