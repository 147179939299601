package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.min
import features.common.Circle
import features.common.DashedCircle
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.label_about_mentor_1
import passaporteaupair.composeapp.generated.resources.label_about_mentor_2
import passaporteaupair.composeapp.generated.resources.label_about_mentor_3
import passaporteaupair.composeapp.generated.resources.label_about_mentor_4
import passaporteaupair.composeapp.generated.resources.label_about_mentor_5
import passaporteaupair.composeapp.generated.resources.mentor_image_2
import theme.dimens
import utils.screenWidth


@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun AboutMentor() {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(
                top = MaterialTheme.dimens.spacing.extraLarge,
                bottom = remember(screenWidth) { spacing.extraLarge + (screenWidth * 0.11f) }
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
                    start = MaterialTheme.dimens.spacing.medium,
                    end = MaterialTheme.dimens.spacing.medium,
                )
        ) {
            Text(
                text = stringResource(Res.string.label_about_mentor_1),
                style = MaterialTheme.typography.headlineMedium
            )

            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.End,
                color = MaterialTheme.colorScheme.secondary,
                text = stringResource(Res.string.label_about_mentor_2),
                style = MaterialTheme.typography.displayLarge
            )
        }
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))

        MentorImage(remember(screenWidth) { screenWidth*1.305f })

        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
                start = MaterialTheme.dimens.spacing.medium,
                end = MaterialTheme.dimens.spacing.medium,
            )
        ) {

            Text(
                text = stringResource(Res.string.label_about_mentor_3),
                style = MaterialTheme.typography.bodyMedium
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))

            Text(
                text = stringResource(Res.string.label_about_mentor_4),
                style = MaterialTheme.typography.displayMedium,
                fontWeight = FontWeight.SemiBold,
                color = MaterialTheme.colorScheme.onBackground
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))

            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.End,
                text = stringResource(Res.string.label_about_mentor_5),
                style = MaterialTheme.typography.displayMedium,
                fontWeight = FontWeight.SemiBold,
                color = MaterialTheme.colorScheme.onBackground
            )
        }

    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentorImage(
    size: Dp
) {
    Box(
        modifier = Modifier
            .wrapContentSize(unbounded = true)
    ) {
        Circle(
            modifier = Modifier
                .offset(x = remember(size) { size * 0.098f }),
            radius = remember(size) { size * 0.116f },
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary)
        )
        DashedCircle(
            modifier = Modifier
                .offset(x = remember(size) { size * 0.069f }),
            radius = remember(size) { size * 0.116f },
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onBackground),
        )


        Box(
            modifier = Modifier
                .padding(
                    top = remember(size) { size * 0.639f }
                )

        ) {
            Circle(
                modifier = Modifier
                    .offset(
                        x = remember(size) { size * 0.581f },
                    ),
                radius = remember(size) { size * 0.238f },
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary)
            )
            DashedCircle(
                modifier = Modifier
                    .offset(
                        x = remember(size) { size * 0.648f },
                        y = remember(size) { size * 0.016f }
                    ),
                radius = remember(size) { size * 0.238f },
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onBackground),
            )
        }


        Image(
            modifier = Modifier
                .size(size)
                .clip(CircleShape),
            painter = painterResource(Res.drawable.mentor_image_2),
            contentDescription = null
        )
    }
}