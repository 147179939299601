package features.common

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import theme.dimens
import utils.dashedBorder
import utils.toMultiLine

@Composable
fun ModuleItem(
    modifier: Modifier,
    icon: DrawableResource,
    moduleSubtitle: String,
    moduleTitle: String?,
    moduleItems: List<String>,
) {
    Column(
        modifier = modifier
            .dashedBorder(
                strokeWidth = 1.dp,
                color = Color(213, 213, 213),
                cornerRadiusDp = 60.dp
            )
            .clip(RoundedCornerShape(60.dp))
            .padding(
                vertical = MaterialTheme.dimens.spacing.extraLarge * 2,
                horizontal = MaterialTheme.dimens.spacing.medium
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier.size(92.dp),
            painter = painterResource(icon),
            contentDescription = null,
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.secondary)
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
        Text(
            text = moduleSubtitle,
            textAlign = TextAlign.Center,
            style = MaterialTheme.typography.bodyMedium,
            color = MaterialTheme.colorScheme.onSurfaceVariant
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraSmall))
        if (moduleTitle != null) {
            Text(
                text = moduleTitle,
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.headlineSmall,
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
        }
        Text(
            text = moduleItems.toMultiLine(),
            textAlign = TextAlign.Center,
            style = MaterialTheme.typography.bodyMedium,
        )
    }
}