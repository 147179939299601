package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources._3
import passaporteaupair.composeapp.generated.resources._4
import passaporteaupair.composeapp.generated.resources._5
import passaporteaupair.composeapp.generated.resources._6
import passaporteaupair.composeapp.generated.resources._7
import passaporteaupair.composeapp.generated.resources._8
import passaporteaupair.composeapp.generated.resources._9
import passaporteaupair.composeapp.generated.resources._10
import passaporteaupair.composeapp.generated.resources._11
import passaporteaupair.composeapp.generated.resources._12
import passaporteaupair.composeapp.generated.resources._13
import passaporteaupair.composeapp.generated.resources._14
import passaporteaupair.composeapp.generated.resources._15
import passaporteaupair.composeapp.generated.resources._16
import passaporteaupair.composeapp.generated.resources._17
import passaporteaupair.composeapp.generated.resources._18
import passaporteaupair.composeapp.generated.resources._19
import passaporteaupair.composeapp.generated.resources._20
import passaporteaupair.composeapp.generated.resources._21
import passaporteaupair.composeapp.generated.resources._22
import passaporteaupair.composeapp.generated.resources._23
import passaporteaupair.composeapp.generated.resources._24
import passaporteaupair.composeapp.generated.resources._25
import passaporteaupair.composeapp.generated.resources._26
import passaporteaupair.composeapp.generated.resources._27
import passaporteaupair.composeapp.generated.resources._28
import passaporteaupair.composeapp.generated.resources._29
import passaporteaupair.composeapp.generated.resources._30
import passaporteaupair.composeapp.generated.resources._31
import passaporteaupair.composeapp.generated.resources._32
import passaporteaupair.composeapp.generated.resources._33
import passaporteaupair.composeapp.generated.resources._34
import passaporteaupair.composeapp.generated.resources._35
import passaporteaupair.composeapp.generated.resources._36
import passaporteaupair.composeapp.generated.resources._37
import passaporteaupair.composeapp.generated.resources._38
import passaporteaupair.composeapp.generated.resources._39
import passaporteaupair.composeapp.generated.resources._40
import passaporteaupair.composeapp.generated.resources._41
import passaporteaupair.composeapp.generated.resources._42
import passaporteaupair.composeapp.generated.resources._43
import passaporteaupair.composeapp.generated.resources._44
import passaporteaupair.composeapp.generated.resources._45
import passaporteaupair.composeapp.generated.resources._46
import passaporteaupair.composeapp.generated.resources.label_feedback_1
import theme.dimens
import utils.screenWidth


@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun Feedback(modifier: Modifier) {
    val screenWidth = screenWidth

    Column(
        modifier = modifier
            .fillMaxWidth()
            .background(MaterialTheme.colorScheme.surface)
    ) {
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge * 2))
        Text(
            modifier = Modifier.padding(horizontal = MaterialTheme.dimens.spacing.medium),
            text = stringResource(Res.string.label_feedback_1),
            style = MaterialTheme.typography.headlineMedium
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
        val feedbacks = remember {
            listOf(
                Res.drawable._4,
                Res.drawable._5,
                Res.drawable._6,
                Res.drawable._7,
                Res.drawable._8,
                Res.drawable._3,
                Res.drawable._9,
                Res.drawable._10,
                Res.drawable._11,
                Res.drawable._12,
                Res.drawable._13,
                Res.drawable._14,
                Res.drawable._15,
                Res.drawable._16,
                Res.drawable._17,
                Res.drawable._18,
                Res.drawable._19,
                Res.drawable._20,
                Res.drawable._21,
                Res.drawable._22,
                Res.drawable._23,
                Res.drawable._24,
                Res.drawable._25,
                Res.drawable._26,
                Res.drawable._27,
                Res.drawable._28,
                Res.drawable._29,
                Res.drawable._30,
                Res.drawable._31,
                Res.drawable._32,
                Res.drawable._33,
                Res.drawable._34,
                Res.drawable._35,
                Res.drawable._36,
                Res.drawable._37,
                Res.drawable._38,
                Res.drawable._39,
                Res.drawable._40,
                Res.drawable._41,
                Res.drawable._42,
                Res.drawable._43,
                Res.drawable._44,
                Res.drawable._45,
                Res.drawable._46,
            )
        }

        val height = remember(screenWidth) { screenWidth }
        val itemWidth = remember(screenWidth) { screenWidth * 0.8f }
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(height),
            contentPadding = PaddingValues(horizontal = MaterialTheme.dimens.spacing.medium),
            horizontalArrangement = Arrangement.spacedBy(MaterialTheme.dimens.spacing.extraSmall),
            verticalAlignment = Alignment.CenterVertically
        ) {
            feedbacks.forEach {
                item {
                    Image(
                        modifier = Modifier.width(itemWidth).fillMaxHeight(),
                        contentScale = ContentScale.Fit,
                        painter = painterResource(it),
                        contentDescription = null
                    )
                }
            }
        }
    }

}