@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package passaporteaupair.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val Poppins_Bold: FontResource by 
      lazy { init_Poppins_Bold() }

  public val Poppins_Medium: FontResource by 
      lazy { init_Poppins_Medium() }

  public val Poppins_Regular: FontResource by 
      lazy { init_Poppins_Regular() }

  public val Poppins_SemiBold: FontResource by 
      lazy { init_Poppins_SemiBold() }

  public val abril_fatface_regular: FontResource by 
      lazy { init_abril_fatface_regular() }

  public val handwritten: FontResource by 
      lazy { init_handwritten() }
}

internal val Res.font.Poppins_Bold: FontResource
  get() = CommonMainFont0.Poppins_Bold

private fun init_Poppins_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Poppins_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/font/Poppins-Bold.ttf", -1, -1),
    )
)

internal val Res.font.Poppins_Medium: FontResource
  get() = CommonMainFont0.Poppins_Medium

private fun init_Poppins_Medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Poppins_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/font/Poppins-Medium.ttf", -1, -1),
    )
)

internal val Res.font.Poppins_Regular: FontResource
  get() = CommonMainFont0.Poppins_Regular

private fun init_Poppins_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Poppins_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/font/Poppins-Regular.ttf", -1, -1),
    )
)

internal val Res.font.Poppins_SemiBold: FontResource
  get() = CommonMainFont0.Poppins_SemiBold

private fun init_Poppins_SemiBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Poppins_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/font/Poppins-SemiBold.ttf", -1, -1),
    )
)

internal val Res.font.abril_fatface_regular: FontResource
  get() = CommonMainFont0.abril_fatface_regular

private fun init_abril_fatface_regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:abril_fatface_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/font/abril_fatface_regular.ttf", -1, -1),
    )
)

internal val Res.font.handwritten: FontResource
  get() = CommonMainFont0.handwritten

private fun init_handwritten(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:handwritten",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/font/handwritten.otf", -1, -1),
    )
)
