package features.common.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import features.common.PrimaryButton
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_button_decoration
import passaporteaupair.composeapp.generated.resources.cloud_7
import passaporteaupair.composeapp.generated.resources.cloud_8
import passaporteaupair.composeapp.generated.resources.decorative_line_4
import passaporteaupair.composeapp.generated.resources.flying_rocket
import passaporteaupair.composeapp.generated.resources.label_decoration
import passaporteaupair.composeapp.generated.resources.stars
import passaporteaupair.composeapp.generated.resources.stars_adaptive
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun DecorativeSectionAdaptive(
    onClick: () -> Unit,
    ) {
    val screenWidth = screenWidth
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .background(MaterialTheme.colorScheme.surface)
    ) {
        Image(
            modifier = Modifier.width(230.dp)
                .offset(y = (-100).dp),
            painter = painterResource(Res.drawable.cloud_7),
            contentDescription = null
        )
        Image(
            modifier = Modifier.fillMaxWidth()
                .padding(
                    top = screenWidth * 0.11f,
                    start = screenWidth * 0.076f,
                    end = screenWidth * 0.105f,
                    bottom = screenWidth * 0.2f,
                ),
            painter = painterResource(Res.drawable.stars_adaptive),
            contentDescription = null,
            contentScale = ContentScale.Crop
        )
        Image(
            modifier = Modifier
                .width(screenWidth * 0.85f)
                .offset(
                    x = -screenWidth * 0.256f,
                    y = screenWidth * 0.3f
                ),
            painter = painterResource(Res.drawable.flying_rocket),
            contentDescription = null
        )

        Box(
            modifier = Modifier.matchParentSize(),
            contentAlignment = Alignment.BottomEnd
        ) {
            Image(
                modifier = Modifier
                    .width(290.dp)
                    .offset(y = 40.dp),
                painter = painterResource(Res.drawable.cloud_8),
                contentDescription = null
            )
        }
        Column(
            modifier = Modifier
                .matchParentSize()
                .padding(horizontal = MaterialTheme.dimens.spacing.medium),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                text = stringResource(Res.string.label_decoration),
                style = MaterialTheme.typography.headlineSmall,
                fontSize = MaterialTheme.dimens.fontSize.titleLarge
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
            PrimaryButton(
                modifier = Modifier.fillMaxWidth(),
                text = stringResource(Res.string.action_button_decoration),
                onPurchaseClick = onClick
            )
        }

    }
}
