package features.language_portal.ui

import TABLET_SCREEN_WIDTH
import androidx.compose.runtime.Composable
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import features.language_portal.ui.adaptive.LanguagePortalPageAdaptive
import features.language_portal.ui.web.LanguagePortalPage
import navigation.LanguagePortalComponent

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun LanguagePortal(
    component: LanguagePortalComponent,
) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    if(screenWidth<TABLET_SCREEN_WIDTH){
        LanguagePortalPageAdaptive(component)
    }else{
        LanguagePortalPage(component)
    }
}