package features.common

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.ui.tooling.preview.Preview
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.arrow_down
import passaporteaupair.composeapp.generated.resources.cd_faq_item_arrow_down
import passaporteaupair.composeapp.generated.resources.title_faq
import theme.dimens
import utils.dashedBorder
import utils.screenWidth


data class FaqData(
    val title: String,
    val desc: String
)

@OptIn(ExperimentalResourceApi::class)
@Composable
fun Faq(
    modifier: Modifier,
    faqData: List<FaqData>
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
//    Box(modifier = modifier) {
        Column(
            modifier = modifier.fillMaxWidth()
                .padding(
                    bottom = remember(screenWidth) { screenWidth * 0.065f + spacing.extraLarge }
                ),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Spacer(
                modifier = Modifier
                    .height(remember(screenWidth) { screenWidth * 0.05f + spacing.extraLarge })
            )
            Text(
                text = stringResource(Res.string.title_faq),
                style = MaterialTheme.typography.headlineMedium
            )
            Spacer(
                modifier = Modifier
                    .height(remember(screenWidth) { screenWidth * 0.026f + spacing.extraLarge })
            )
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        horizontal = remember(screenWidth) { screenWidth * 0.179f }
                    )
            ) {
                faqData.forEachIndexed { index, it ->
                    if (index != 0) {
                        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
                    }
                    FaqItem(
                        title = it.title,
                        desc = it.desc
                    )
                }
            }
//        }
    }
}

@OptIn(ExperimentalResourceApi::class, ExperimentalFoundationApi::class)
@Composable
fun FaqItem(
    title: String,
    desc: String
) {
    var isVisible by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .dashedBorder(
                strokeWidth = 1.dp,
                color = Color(213, 213, 213),
                cornerRadiusDp = 15.dp
            )
            .clip(RoundedCornerShape(15.dp))
            .clickable {
                isVisible = !isVisible
            }
            .padding(MaterialTheme.dimens.spacing.large)
    ) {
        Row {
            Text(
                modifier = Modifier.weight(1f, true),
                text = title,
                style = MaterialTheme.typography.bodyLarge,
                fontWeight = FontWeight.Bold
            )
            val rotation = if (isVisible) 180f else 0f
            Image(
                modifier = Modifier
                    .size(MaterialTheme.dimens.iconSize.faqExpandIconSize)
                    .rotate(rotation),
                painter = painterResource(Res.drawable.arrow_down),
                contentDescription = stringResource(Res.string.cd_faq_item_arrow_down),

                )
        }
        AnimatedVisibility(isVisible) {
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
            Text(
                modifier = Modifier.fillMaxWidth(),
                text = desc,
                style = MaterialTheme.typography.bodyLarge,
            )
        }
    }
}