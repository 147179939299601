package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.academic_hat
import passaporteaupair.composeapp.generated.resources.achievement
import passaporteaupair.composeapp.generated.resources.label_guarantee
import passaporteaupair.composeapp.generated.resources.label_secure_purchase
import passaporteaupair.composeapp.generated.resources.label_students_count
import passaporteaupair.composeapp.generated.resources.shield
import theme.dimens

@OptIn(ExperimentalResourceApi::class)
@Composable
fun BenefitsSectionVertical(
    modifier: Modifier,
) {
    Row(
        modifier = modifier
    ) {
        BenefitItemVertical(
            modifier = Modifier.weight(1f, true),
            icon = Res.drawable.achievement,
            text = Res.string.label_guarantee
        )

        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.small))

        BenefitItemVertical(
            modifier = Modifier.weight(1f, true),
            icon = Res.drawable.shield,
            text = Res.string.label_secure_purchase
        )

        Spacer(
            modifier = Modifier.width(MaterialTheme.dimens.spacing.small)
        )

        BenefitItemVertical(
            modifier = Modifier.weight(1f, true),
            icon = Res.drawable.academic_hat,
            text = Res.string.label_students_count
        )
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun BenefitItemVertical(
    modifier: Modifier,
    icon: DrawableResource,
    text: StringResource
) {
    Column(
        modifier = modifier
            .clip(RoundedCornerShape(20.dp))
            .background(MaterialTheme.colorScheme.surface)
            .padding(vertical = MaterialTheme.dimens.spacing.medium),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Image(
            modifier = Modifier
                .size(MaterialTheme.dimens.iconSize.programProsIconSize)
                .padding(MaterialTheme.dimens.spacing.extraSmall),
            painter = painterResource(icon),
            contentDescription = null,
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary),
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraSmall))
        Text(
            minLines = 2,
            textAlign = TextAlign.Center,
            text = stringResource(text),
            color = MaterialTheme.colorScheme.primary,
            style = MaterialTheme.typography.titleLarge,
            fontWeight = FontWeight.ExtraBold
        )
    }
}
