@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package passaporteaupair.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val action_button_decoration: StringResource by 
      lazy { init_action_button_decoration() }

  public val action_buy: StringResource by 
      lazy { init_action_buy() }

  public val action_buy_ebook: StringResource by 
      lazy { init_action_buy_ebook() }

  public val action_buy_ebook_price: StringResource by 
      lazy { init_action_buy_ebook_price() }

  public val action_buy_language_portal: StringResource by 
      lazy { init_action_buy_language_portal() }

  public val action_buy_language_portal_price: StringResource by 
      lazy { init_action_buy_language_portal_price() }

  public val action_contact: StringResource by 
      lazy { init_action_contact() }

  public val action_learn_more: StringResource by 
      lazy { init_action_learn_more() }

  public val action_start_journey: StringResource by 
      lazy { init_action_start_journey() }

  public val cd_faq_item_arrow_down: StringResource by 
      lazy { init_cd_faq_item_arrow_down() }

  public val cd_instagram: StringResource by 
      lazy { init_cd_instagram() }

  public val cd_logo: StringResource by 
      lazy { init_cd_logo() }

  public val cd_menu: StringResource by 
      lazy { init_cd_menu() }

  public val cd_play_intro: StringResource by 
      lazy { init_cd_play_intro() }

  public val cd_youtube: StringResource by 
      lazy { init_cd_youtube() }

  public val first_product_desc: StringResource by 
      lazy { init_first_product_desc() }

  public val first_product_name: StringResource by 
      lazy { init_first_product_name() }

  public val home_section_description: StringResource by 
      lazy { init_home_section_description() }

  public val home_section_title: StringResource by 
      lazy { init_home_section_title() }

  public val label_about_mentor_1: StringResource by 
      lazy { init_label_about_mentor_1() }

  public val label_about_mentor_2: StringResource by 
      lazy { init_label_about_mentor_2() }

  public val label_about_mentor_3: StringResource by 
      lazy { init_label_about_mentor_3() }

  public val label_about_mentor_4: StringResource by 
      lazy { init_label_about_mentor_4() }

  public val label_about_mentor_5: StringResource by 
      lazy { init_label_about_mentor_5() }

  public val label_contact: StringResource by 
      lazy { init_label_contact() }

  public val label_decoration: StringResource by 
      lazy { init_label_decoration() }

  public val label_ebook_details_1: StringResource by 
      lazy { init_label_ebook_details_1() }

  public val label_ebook_details_2: StringResource by 
      lazy { init_label_ebook_details_2() }

  public val label_europe: StringResource by 
      lazy { init_label_europe() }

  public val label_exchange_benefits_1: StringResource by 
      lazy { init_label_exchange_benefits_1() }

  public val label_exchange_benefits_2: StringResource by 
      lazy { init_label_exchange_benefits_2() }

  public val label_exchange_benefits_3: StringResource by 
      lazy { init_label_exchange_benefits_3() }

  public val label_exchange_details_1: StringResource by 
      lazy { init_label_exchange_details_1() }

  public val label_exchange_details_2: StringResource by 
      lazy { init_label_exchange_details_2() }

  public val label_exchange_details_3: StringResource by 
      lazy { init_label_exchange_details_3() }

  public val label_feedback_1: StringResource by 
      lazy { init_label_feedback_1() }

  public val label_guarantee: StringResource by 
      lazy { init_label_guarantee() }

  public val label_language_portal_details_1: StringResource by 
      lazy { init_label_language_portal_details_1() }

  public val label_language_portal_details_2: StringResource by 
      lazy { init_label_language_portal_details_2() }

  public val label_mentory_details_1: StringResource by 
      lazy { init_label_mentory_details_1() }

  public val label_mentory_details_2: StringResource by 
      lazy { init_label_mentory_details_2() }

  public val label_mentory_details_3: StringResource by 
      lazy { init_label_mentory_details_3() }

  public val label_new_life: StringResource by 
      lazy { init_label_new_life() }

  public val label_play_intro: StringResource by 
      lazy { init_label_play_intro() }

  public val label_popular: StringResource by 
      lazy { init_label_popular() }

  public val label_requirements_1: StringResource by 
      lazy { init_label_requirements_1() }

  public val label_requirements_2: StringResource by 
      lazy { init_label_requirements_2() }

  public val label_secure_purchase: StringResource by 
      lazy { init_label_secure_purchase() }

  public val label_students_count: StringResource by 
      lazy { init_label_students_count() }

  public val mentory_action_buy: StringResource by 
      lazy { init_mentory_action_buy() }

  public val mentory_details_section_desc_1: StringResource by 
      lazy { init_mentory_details_section_desc_1() }

  public val mentory_details_section_desc_2: StringResource by 
      lazy { init_mentory_details_section_desc_2() }

  public val mentory_details_section_desc_3: StringResource by 
      lazy { init_mentory_details_section_desc_3() }

  public val mentory_details_section_desc_4: StringResource by 
      lazy { init_mentory_details_section_desc_4() }

  public val mentory_details_section_desc_5: StringResource by 
      lazy { init_mentory_details_section_desc_5() }

  public val mentory_details_section_desc_6: StringResource by 
      lazy { init_mentory_details_section_desc_6() }

  public val mentory_details_section_desc_7: StringResource by 
      lazy { init_mentory_details_section_desc_7() }

  public val mentory_details_section_title_1: StringResource by 
      lazy { init_mentory_details_section_title_1() }

  public val mentory_details_section_title_2: StringResource by 
      lazy { init_mentory_details_section_title_2() }

  public val mentory_details_section_title_3: StringResource by 
      lazy { init_mentory_details_section_title_3() }

  public val mentory_details_section_title_4: StringResource by 
      lazy { init_mentory_details_section_title_4() }

  public val mentory_details_section_title_5: StringResource by 
      lazy { init_mentory_details_section_title_5() }

  public val mentory_details_section_title_6: StringResource by 
      lazy { init_mentory_details_section_title_6() }

  public val mentory_details_section_title_7: StringResource by 
      lazy { init_mentory_details_section_title_7() }

  public val mentory_product_bonus_title: StringResource by 
      lazy { init_mentory_product_bonus_title() }

  public val mentory_product_title: StringResource by 
      lazy { init_mentory_product_title() }

  public val second_product_desc: StringResource by 
      lazy { init_second_product_desc() }

  public val second_product_name: StringResource by 
      lazy { init_second_product_name() }

  public val subtitle_ebook_module_1: StringResource by 
      lazy { init_subtitle_ebook_module_1() }

  public val subtitle_ebook_module_2: StringResource by 
      lazy { init_subtitle_ebook_module_2() }

  public val subtitle_ebook_module_3: StringResource by 
      lazy { init_subtitle_ebook_module_3() }

  public val subtitle_language_portal_module_1: StringResource by 
      lazy { init_subtitle_language_portal_module_1() }

  public val subtitle_language_portal_module_2: StringResource by 
      lazy { init_subtitle_language_portal_module_2() }

  public val subtitle_language_portal_module_3: StringResource by 
      lazy { init_subtitle_language_portal_module_3() }

  public val third_product_desc: StringResource by 
      lazy { init_third_product_desc() }

  public val third_product_name: StringResource by 
      lazy { init_third_product_name() }

  public val title_ebook_module_1: StringResource by 
      lazy { init_title_ebook_module_1() }

  public val title_ebook_module_2: StringResource by 
      lazy { init_title_ebook_module_2() }

  public val title_ebook_module_3: StringResource by 
      lazy { init_title_ebook_module_3() }

  public val title_faq: StringResource by 
      lazy { init_title_faq() }

  public val title_language_portal_module_1: StringResource by 
      lazy { init_title_language_portal_module_1() }

  public val title_language_portal_module_2: StringResource by 
      lazy { init_title_language_portal_module_2() }

  public val title_language_portal_module_3: StringResource by 
      lazy { init_title_language_portal_module_3() }

  public val toolbar_label_about: StringResource by 
      lazy { init_toolbar_label_about() }

  public val toolbar_label_faq: StringResource by 
      lazy { init_toolbar_label_faq() }

  public val toolbar_label_feedback: StringResource by 
      lazy { init_toolbar_label_feedback() }

  public val toolbar_label_how_it_work: StringResource by 
      lazy { init_toolbar_label_how_it_work() }

  public val toolbar_label_mentory_items: StringResource by 
      lazy { init_toolbar_label_mentory_items() }

  public val toolbar_label_products: StringResource by 
      lazy { init_toolbar_label_products() }

  public val whatsapp: StringResource by 
      lazy { init_whatsapp() }
}

internal val Res.string.action_button_decoration: StringResource
  get() = CommonMainString0.action_button_decoration

private fun init_action_button_decoration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_button_decoration", "action_button_decoration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6353, 52),
    )
)

internal val Res.string.action_buy: StringResource
  get() = CommonMainString0.action_buy

private fun init_action_buy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:action_buy", "action_buy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6634, 30),
    )
)

internal val Res.string.action_buy_ebook: StringResource
  get() = CommonMainString0.action_buy_ebook

private fun init_action_buy_ebook(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_buy_ebook", "action_buy_ebook",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6449, 60),
    )
)

internal val Res.string.action_buy_ebook_price: StringResource
  get() = CommonMainString0.action_buy_ebook_price

private fun init_action_buy_ebook_price(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_buy_ebook_price", "action_buy_ebook_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6406, 42),
    )
)

internal val Res.string.action_buy_language_portal: StringResource
  get() = CommonMainString0.action_buy_language_portal

private fun init_action_buy_language_portal(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_buy_language_portal", "action_buy_language_portal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6563, 70),
    )
)

internal val Res.string.action_buy_language_portal_price: StringResource
  get() = CommonMainString0.action_buy_language_portal_price

private fun init_action_buy_language_portal_price(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_buy_language_portal_price", "action_buy_language_portal_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6510, 52),
    )
)

internal val Res.string.action_contact: StringResource
  get() = CommonMainString0.action_contact

private fun init_action_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:action_contact", "action_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6665, 34),
    )
)

internal val Res.string.action_learn_more: StringResource
  get() = CommonMainString0.action_learn_more

private fun init_action_learn_more(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_learn_more", "action_learn_more",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6700, 41),
    )
)

internal val Res.string.action_start_journey: StringResource
  get() = CommonMainString0.action_start_journey

private fun init_action_start_journey(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:action_start_journey", "action_start_journey",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6742, 48),
    )
)

internal val Res.string.cd_faq_item_arrow_down: StringResource
  get() = CommonMainString0.cd_faq_item_arrow_down

private fun init_cd_faq_item_arrow_down(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:cd_faq_item_arrow_down", "cd_faq_item_arrow_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6791, 62),
    )
)

internal val Res.string.cd_instagram: StringResource
  get() = CommonMainString0.cd_instagram

private fun init_cd_instagram(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cd_instagram", "cd_instagram",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6854, 40),
    )
)

internal val Res.string.cd_logo: StringResource
  get() = CommonMainString0.cd_logo

private fun init_cd_logo(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cd_logo", "cd_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6895, 31),
    )
)

internal val Res.string.cd_menu: StringResource
  get() = CommonMainString0.cd_menu

private fun init_cd_menu(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cd_menu", "cd_menu",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6927, 23),
    )
)

internal val Res.string.cd_play_intro: StringResource
  get() = CommonMainString0.cd_play_intro

private fun init_cd_play_intro(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cd_play_intro", "cd_play_intro",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6951, 45),
    )
)

internal val Res.string.cd_youtube: StringResource
  get() = CommonMainString0.cd_youtube

private fun init_cd_youtube(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cd_youtube", "cd_youtube",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    6997, 34),
    )
)

internal val Res.string.first_product_desc: StringResource
  get() = CommonMainString0.first_product_desc

private fun init_first_product_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:first_product_desc", "first_product_desc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7032, 98),
    )
)

internal val Res.string.first_product_name: StringResource
  get() = CommonMainString0.first_product_name

private fun init_first_product_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:first_product_name", "first_product_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7131, 58),
    )
)

internal val Res.string.home_section_description: StringResource
  get() = CommonMainString0.home_section_description

private fun init_home_section_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_section_description", "home_section_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7190, 328),
    )
)

internal val Res.string.home_section_title: StringResource
  get() = CommonMainString0.home_section_title

private fun init_home_section_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_section_title", "home_section_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7519, 46),
    )
)

internal val Res.string.label_about_mentor_1: StringResource
  get() = CommonMainString0.label_about_mentor_1

private fun init_label_about_mentor_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_about_mentor_1", "label_about_mentor_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7566, 48),
    )
)

internal val Res.string.label_about_mentor_2: StringResource
  get() = CommonMainString0.label_about_mentor_2

private fun init_label_about_mentor_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_about_mentor_2", "label_about_mentor_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7615, 44),
    )
)

internal val Res.string.label_about_mentor_3: StringResource
  get() = CommonMainString0.label_about_mentor_3

private fun init_label_about_mentor_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_about_mentor_3", "label_about_mentor_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    7660, 1048),
    )
)

internal val Res.string.label_about_mentor_4: StringResource
  get() = CommonMainString0.label_about_mentor_4

private fun init_label_about_mentor_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_about_mentor_4", "label_about_mentor_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    8709, 48),
    )
)

internal val Res.string.label_about_mentor_5: StringResource
  get() = CommonMainString0.label_about_mentor_5

private fun init_label_about_mentor_5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_about_mentor_5", "label_about_mentor_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    8758, 52),
    )
)

internal val Res.string.label_contact: StringResource
  get() = CommonMainString0.label_contact

private fun init_label_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_contact", "label_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    8811, 101),
    )
)

internal val Res.string.label_decoration: StringResource
  get() = CommonMainString0.label_decoration

private fun init_label_decoration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_decoration", "label_decoration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    8913, 68),
    )
)

internal val Res.string.label_ebook_details_1: StringResource
  get() = CommonMainString0.label_ebook_details_1

private fun init_label_ebook_details_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_ebook_details_1", "label_ebook_details_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    8982, 141),
    )
)

internal val Res.string.label_ebook_details_2: StringResource
  get() = CommonMainString0.label_ebook_details_2

private fun init_label_ebook_details_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_ebook_details_2", "label_ebook_details_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9124, 281),
    )
)

internal val Res.string.label_europe: StringResource
  get() = CommonMainString0.label_europe

private fun init_label_europe(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_europe", "label_europe",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9406, 28),
    )
)

internal val Res.string.label_exchange_benefits_1: StringResource
  get() = CommonMainString0.label_exchange_benefits_1

private fun init_label_exchange_benefits_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_exchange_benefits_1", "label_exchange_benefits_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9435, 169),
    )
)

internal val Res.string.label_exchange_benefits_2: StringResource
  get() = CommonMainString0.label_exchange_benefits_2

private fun init_label_exchange_benefits_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_exchange_benefits_2", "label_exchange_benefits_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9605, 109),
    )
)

internal val Res.string.label_exchange_benefits_3: StringResource
  get() = CommonMainString0.label_exchange_benefits_3

private fun init_label_exchange_benefits_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_exchange_benefits_3", "label_exchange_benefits_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9715, 45),
    )
)

internal val Res.string.label_exchange_details_1: StringResource
  get() = CommonMainString0.label_exchange_details_1

private fun init_label_exchange_details_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_exchange_details_1", "label_exchange_details_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9761, 116),
    )
)

internal val Res.string.label_exchange_details_2: StringResource
  get() = CommonMainString0.label_exchange_details_2

private fun init_label_exchange_details_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_exchange_details_2", "label_exchange_details_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9878, 88),
    )
)

internal val Res.string.label_exchange_details_3: StringResource
  get() = CommonMainString0.label_exchange_details_3

private fun init_label_exchange_details_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_exchange_details_3", "label_exchange_details_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    9967, 408),
    )
)

internal val Res.string.label_feedback_1: StringResource
  get() = CommonMainString0.label_feedback_1

private fun init_label_feedback_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_feedback_1", "label_feedback_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    10376, 124),
    )
)

internal val Res.string.label_guarantee: StringResource
  get() = CommonMainString0.label_guarantee

private fun init_label_guarantee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_guarantee", "label_guarantee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    10501, 47),
    )
)

internal val Res.string.label_language_portal_details_1: StringResource
  get() = CommonMainString0.label_language_portal_details_1

private fun init_label_language_portal_details_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_language_portal_details_1", "label_language_portal_details_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    10549, 163),
    )
)

internal val Res.string.label_language_portal_details_2: StringResource
  get() = CommonMainString0.label_language_portal_details_2

private fun init_label_language_portal_details_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_language_portal_details_2", "label_language_portal_details_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    10713, 263),
    )
)

internal val Res.string.label_mentory_details_1: StringResource
  get() = CommonMainString0.label_mentory_details_1

private fun init_label_mentory_details_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_mentory_details_1", "label_mentory_details_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    10977, 67),
    )
)

internal val Res.string.label_mentory_details_2: StringResource
  get() = CommonMainString0.label_mentory_details_2

private fun init_label_mentory_details_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_mentory_details_2", "label_mentory_details_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11045, 183),
    )
)

internal val Res.string.label_mentory_details_3: StringResource
  get() = CommonMainString0.label_mentory_details_3

private fun init_label_mentory_details_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_mentory_details_3", "label_mentory_details_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11229, 79),
    )
)

internal val Res.string.label_new_life: StringResource
  get() = CommonMainString0.label_new_life

private fun init_label_new_life(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_new_life", "label_new_life",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11309, 34),
    )
)

internal val Res.string.label_play_intro: StringResource
  get() = CommonMainString0.label_play_intro

private fun init_label_play_intro(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_play_intro", "label_play_intro",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11344, 48),
    )
)

internal val Res.string.label_popular: StringResource
  get() = CommonMainString0.label_popular

private fun init_label_popular(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_popular", "label_popular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11393, 33),
    )
)

internal val Res.string.label_requirements_1: StringResource
  get() = CommonMainString0.label_requirements_1

private fun init_label_requirements_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_requirements_1", "label_requirements_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11427, 80),
    )
)

internal val Res.string.label_requirements_2: StringResource
  get() = CommonMainString0.label_requirements_2

private fun init_label_requirements_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_requirements_2", "label_requirements_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11508, 236),
    )
)

internal val Res.string.label_secure_purchase: StringResource
  get() = CommonMainString0.label_secure_purchase

private fun init_label_secure_purchase(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_secure_purchase", "label_secure_purchase",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11745, 49),
    )
)

internal val Res.string.label_students_count: StringResource
  get() = CommonMainString0.label_students_count

private fun init_label_students_count(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:label_students_count", "label_students_count",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11795, 44),
    )
)

internal val Res.string.mentory_action_buy: StringResource
  get() = CommonMainString0.mentory_action_buy

private fun init_mentory_action_buy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_action_buy", "mentory_action_buy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11840, 58),
    )
)

internal val Res.string.mentory_details_section_desc_1: StringResource
  get() = CommonMainString0.mentory_details_section_desc_1

private fun init_mentory_details_section_desc_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_1", "mentory_details_section_desc_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    11899, 178),
    )
)

internal val Res.string.mentory_details_section_desc_2: StringResource
  get() = CommonMainString0.mentory_details_section_desc_2

private fun init_mentory_details_section_desc_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_2", "mentory_details_section_desc_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    12078, 286),
    )
)

internal val Res.string.mentory_details_section_desc_3: StringResource
  get() = CommonMainString0.mentory_details_section_desc_3

private fun init_mentory_details_section_desc_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_3", "mentory_details_section_desc_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    12365, 158),
    )
)

internal val Res.string.mentory_details_section_desc_4: StringResource
  get() = CommonMainString0.mentory_details_section_desc_4

private fun init_mentory_details_section_desc_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_4", "mentory_details_section_desc_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    12524, 370),
    )
)

internal val Res.string.mentory_details_section_desc_5: StringResource
  get() = CommonMainString0.mentory_details_section_desc_5

private fun init_mentory_details_section_desc_5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_5", "mentory_details_section_desc_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    12895, 342),
    )
)

internal val Res.string.mentory_details_section_desc_6: StringResource
  get() = CommonMainString0.mentory_details_section_desc_6

private fun init_mentory_details_section_desc_6(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_6", "mentory_details_section_desc_6",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    13238, 354),
    )
)

internal val Res.string.mentory_details_section_desc_7: StringResource
  get() = CommonMainString0.mentory_details_section_desc_7

private fun init_mentory_details_section_desc_7(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_desc_7", "mentory_details_section_desc_7",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    13593, 314),
    )
)

internal val Res.string.mentory_details_section_title_1: StringResource
  get() = CommonMainString0.mentory_details_section_title_1

private fun init_mentory_details_section_title_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_1", "mentory_details_section_title_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    13908, 55),
    )
)

internal val Res.string.mentory_details_section_title_2: StringResource
  get() = CommonMainString0.mentory_details_section_title_2

private fun init_mentory_details_section_title_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_2", "mentory_details_section_title_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    13964, 63),
    )
)

internal val Res.string.mentory_details_section_title_3: StringResource
  get() = CommonMainString0.mentory_details_section_title_3

private fun init_mentory_details_section_title_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_3", "mentory_details_section_title_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14028, 51),
    )
)

internal val Res.string.mentory_details_section_title_4: StringResource
  get() = CommonMainString0.mentory_details_section_title_4

private fun init_mentory_details_section_title_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_4", "mentory_details_section_title_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14080, 51),
    )
)

internal val Res.string.mentory_details_section_title_5: StringResource
  get() = CommonMainString0.mentory_details_section_title_5

private fun init_mentory_details_section_title_5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_5", "mentory_details_section_title_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14132, 63),
    )
)

internal val Res.string.mentory_details_section_title_6: StringResource
  get() = CommonMainString0.mentory_details_section_title_6

private fun init_mentory_details_section_title_6(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_6", "mentory_details_section_title_6",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14196, 55),
    )
)

internal val Res.string.mentory_details_section_title_7: StringResource
  get() = CommonMainString0.mentory_details_section_title_7

private fun init_mentory_details_section_title_7(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_details_section_title_7", "mentory_details_section_title_7",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14252, 71),
    )
)

internal val Res.string.mentory_product_bonus_title: StringResource
  get() = CommonMainString0.mentory_product_bonus_title

private fun init_mentory_product_bonus_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_product_bonus_title", "mentory_product_bonus_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14324, 63),
    )
)

internal val Res.string.mentory_product_title: StringResource
  get() = CommonMainString0.mentory_product_title

private fun init_mentory_product_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mentory_product_title", "mentory_product_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14388, 57),
    )
)

internal val Res.string.second_product_desc: StringResource
  get() = CommonMainString0.second_product_desc

private fun init_second_product_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:second_product_desc", "second_product_desc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14446, 103),
    )
)

internal val Res.string.second_product_name: StringResource
  get() = CommonMainString0.second_product_name

private fun init_second_product_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:second_product_name", "second_product_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14550, 67),
    )
)

internal val Res.string.subtitle_ebook_module_1: StringResource
  get() = CommonMainString0.subtitle_ebook_module_1

private fun init_subtitle_ebook_module_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subtitle_ebook_module_1", "subtitle_ebook_module_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14618, 43),
    )
)

internal val Res.string.subtitle_ebook_module_2: StringResource
  get() = CommonMainString0.subtitle_ebook_module_2

private fun init_subtitle_ebook_module_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subtitle_ebook_module_2", "subtitle_ebook_module_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14662, 43),
    )
)

internal val Res.string.subtitle_ebook_module_3: StringResource
  get() = CommonMainString0.subtitle_ebook_module_3

private fun init_subtitle_ebook_module_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subtitle_ebook_module_3", "subtitle_ebook_module_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14706, 43),
    )
)

internal val Res.string.subtitle_language_portal_module_1: StringResource
  get() = CommonMainString0.subtitle_language_portal_module_1

private fun init_subtitle_language_portal_module_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subtitle_language_portal_module_1", "subtitle_language_portal_module_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14750, 53),
    )
)

internal val Res.string.subtitle_language_portal_module_2: StringResource
  get() = CommonMainString0.subtitle_language_portal_module_2

private fun init_subtitle_language_portal_module_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subtitle_language_portal_module_2", "subtitle_language_portal_module_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14804, 53),
    )
)

internal val Res.string.subtitle_language_portal_module_3: StringResource
  get() = CommonMainString0.subtitle_language_portal_module_3

private fun init_subtitle_language_portal_module_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subtitle_language_portal_module_3", "subtitle_language_portal_module_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14858, 53),
    )
)

internal val Res.string.third_product_desc: StringResource
  get() = CommonMainString0.third_product_desc

private fun init_third_product_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:third_product_desc", "third_product_desc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    14912, 110),
    )
)

internal val Res.string.third_product_name: StringResource
  get() = CommonMainString0.third_product_name

private fun init_third_product_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:third_product_name", "third_product_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15023, 50),
    )
)

internal val Res.string.title_ebook_module_1: StringResource
  get() = CommonMainString0.title_ebook_module_1

private fun init_title_ebook_module_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_ebook_module_1", "title_ebook_module_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15074, 44),
    )
)

internal val Res.string.title_ebook_module_2: StringResource
  get() = CommonMainString0.title_ebook_module_2

private fun init_title_ebook_module_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_ebook_module_2", "title_ebook_module_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15119, 56),
    )
)

internal val Res.string.title_ebook_module_3: StringResource
  get() = CommonMainString0.title_ebook_module_3

private fun init_title_ebook_module_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_ebook_module_3", "title_ebook_module_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15176, 48),
    )
)

internal val Res.string.title_faq: StringResource
  get() = CommonMainString0.title_faq

private fun init_title_faq(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:title_faq", "title_faq",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15225, 21),
    )
)

internal val Res.string.title_language_portal_module_1: StringResource
  get() = CommonMainString0.title_language_portal_module_1

private fun init_title_language_portal_module_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_language_portal_module_1", "title_language_portal_module_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15247, 54),
    )
)

internal val Res.string.title_language_portal_module_2: StringResource
  get() = CommonMainString0.title_language_portal_module_2

private fun init_title_language_portal_module_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_language_portal_module_2", "title_language_portal_module_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15302, 66),
    )
)

internal val Res.string.title_language_portal_module_3: StringResource
  get() = CommonMainString0.title_language_portal_module_3

private fun init_title_language_portal_module_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_language_portal_module_3", "title_language_portal_module_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15369, 58),
    )
)

internal val Res.string.toolbar_label_about: StringResource
  get() = CommonMainString0.toolbar_label_about

private fun init_toolbar_label_about(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toolbar_label_about", "toolbar_label_about",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15428, 51),
    )
)

internal val Res.string.toolbar_label_faq: StringResource
  get() = CommonMainString0.toolbar_label_faq

private fun init_toolbar_label_faq(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toolbar_label_faq", "toolbar_label_faq",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15480, 29),
    )
)

internal val Res.string.toolbar_label_feedback: StringResource
  get() = CommonMainString0.toolbar_label_feedback

private fun init_toolbar_label_feedback(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toolbar_label_feedback", "toolbar_label_feedback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15510, 42),
    )
)

internal val Res.string.toolbar_label_how_it_work: StringResource
  get() = CommonMainString0.toolbar_label_how_it_work

private fun init_toolbar_label_how_it_work(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toolbar_label_how_it_work", "toolbar_label_how_it_work",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15553, 53),
    )
)

internal val Res.string.toolbar_label_mentory_items: StringResource
  get() = CommonMainString0.toolbar_label_mentory_items

private fun init_toolbar_label_mentory_items(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toolbar_label_mentory_items", "toolbar_label_mentory_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15607, 63),
    )
)

internal val Res.string.toolbar_label_products: StringResource
  get() = CommonMainString0.toolbar_label_products

private fun init_toolbar_label_products(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toolbar_label_products", "toolbar_label_products",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15671, 58),
    )
)

internal val Res.string.whatsapp: StringResource
  get() = CommonMainString0.whatsapp

private fun init_whatsapp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whatsapp", "whatsapp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    15730, 28),
    )
)
