package features.landing.ui.web

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.platform.LocalWindowInfo
import features.common.ContactSection
import features.common.FooterContent
import features.common.web.ImageActionButton
import features.common.LandingFooterDivider
import features.common.WHATS_APP_CONTACT_LINK
import features.common.web.TextActionButton
import theme.dimens

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun LandingFooter(
    showCloud:Boolean = true,
    textActionButtons: List<TextActionButton>,
    imageActionButtons: List<ImageActionButton>
) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    val uriHandler = LocalUriHandler.current

    ContactSection(showCloud) { uriHandler.openUri(WHATS_APP_CONTACT_LINK) }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(MaterialTheme.colorScheme.surface)
            .padding(bottom = MaterialTheme.dimens.spacing.extraLarge * 2),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
        LandingFooterDivider(screenWidth)
        FooterContent(
            screenWidth = screenWidth,
            textActionButtons = textActionButtons,
            imageActionButtons = imageActionButtons
        )

    }
}