package navigation

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.popTo
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.decompose.value.Value
import features.ebook.ui.Ebook
import features.language_portal.ui.LanguagePortal
import kotlinx.serialization.Serializable
import features.landing.LandingComponent
import features.landing.ui.Landing
import features.mentory.Mentory

interface RootComponent {

    val stack: Value<ChildStack<*, Child>>

    // It's possible to pop multiple screens at a time on iOS
    fun onBackClicked(toIndex: Int)

    // Defines all possible child components

}

sealed class Child {
    class LandingChild(val component: LandingComponent) : Child()
    class MentoryChild(val component: MentoryComponent) : Child()
    class EbookChild(val component: EbookComponent) : Child()
    class LanguagePortalChild(val component: LanguagePortalComponent) : Child()
}

@OptIn(ExperimentalDecomposeApi::class)
class DefaultRootComponent(
    componentContext: ComponentContext,
    webHistoryController: DefaultWebHistoryController,
    deepLink: DeepLink = DeepLink.None,
    ) : RootComponent, ComponentContext by componentContext {

    private val navigation = StackNavigation<Config>()

    override val stack = childStack(
        source = navigation,
        serializer = Config.serializer(),
        initialStack = {
            getInitialStack(
                webHistoryPaths = webHistoryController.historyPaths,
                deepLink = deepLink
            )
        },
        handleBackButton = true, // Automatically pop from the stack on back button presses
        childFactory = ::child,
    )

    init {
        webHistoryController.attach(
            navigator = navigation,
            serializer = Config.serializer(),
            stack = stack,
            getPath = ::getPathForConfig,
            getConfiguration = ::getConfigForPath,
        )
    }

    private fun child(config: Config, componentContext: ComponentContext): Child =
        when (config) {
            is Config.Landing -> Child.LandingChild(
                LandingComponent(
                    componentContext = componentContext,
                    openMentoryPage = {
                        navigation.push(Config.Mentory)
                    },
                    openEbookPage = {
                        navigation.push(Config.Ebook)
                    },
                    openLanguagePortalPage = {
                        navigation.push(Config.LanguagePortal)
                    },
                )
            )

            is Config.Mentory -> Child.MentoryChild(MentoryComponent(componentContext))
            is Config.Ebook -> Child.EbookChild(EbookComponent(componentContext))
            is Config.LanguagePortal -> Child.LanguagePortalChild(LanguagePortalComponent(componentContext))
        }

    override fun onBackClicked(toIndex: Int) {
        navigation.popTo(index = toIndex)
    }

    @Serializable
    sealed interface Config {
        @Serializable
        data object Landing : Config

        @Serializable
        data object Mentory : Config

        @Serializable
        data object Ebook : Config

        @Serializable
        data object LanguagePortal : Config
    }

    private companion object {
        private const val WEB_PATH_HOME = ""
        private const val WEB_PATH_MENTORY = "mentory"
        private const val WEB_PATH_EBOOK = "e-book"
        private const val WEB_PATH_LANGUAGE_PORTAL = "languagePortal"

        private fun getInitialStack(
            webHistoryPaths: List<String>?,
            deepLink: DeepLink
        ): List<Config> =
            webHistoryPaths
                ?.takeUnless(List<*>::isEmpty)
                ?.map(::getConfigForPath)
                ?: getInitialStack(deepLink)

        private fun getInitialStack(deepLink: DeepLink): List<Config> =
            when (deepLink) {
                is DeepLink.None -> listOf(Config.Landing)
                is DeepLink.Web -> listOf(getConfigForPath(deepLink.path))
            }

        private fun getPathForConfig(config: Config): String =
            when (config) {
                Config.Landing -> "/$WEB_PATH_HOME"
                Config.Mentory -> "/$WEB_PATH_MENTORY"
                Config.Ebook -> "/$WEB_PATH_EBOOK"
                Config.LanguagePortal -> "/$WEB_PATH_LANGUAGE_PORTAL"
            }

        private fun getConfigForPath(path: String): Config =
            when (path.removePrefix("/")) {
                WEB_PATH_HOME -> Config.Landing
                WEB_PATH_MENTORY -> Config.Mentory
                WEB_PATH_EBOOK -> Config.Ebook
                WEB_PATH_LANGUAGE_PORTAL -> Config.LanguagePortal
                else -> Config.Landing
            }
    }
}


class MentoryComponent(componentContext: ComponentContext)
class EbookComponent(componentContext: ComponentContext)
class LanguagePortalComponent(componentContext: ComponentContext)
//class LanguageComponent(componentContext: ComponentContext)

const val INTRO_VIDEO_URI = ""

@Composable
fun RootContent(component: RootComponent, modifier: Modifier = Modifier) {
    Children(
        stack = component.stack,
        modifier = modifier,
//        animation = stackAnimation(fade()),
    ) {
        when (val child = it.instance) {
            is Child.LandingChild -> Landing(component = child.component)
            is Child.MentoryChild -> Mentory(component = child.component)
            is Child.EbookChild -> Ebook(component = child.component)
            is Child.LanguagePortalChild -> LanguagePortal(component = child.component)
        }
    }
}

sealed interface DeepLink {
    data object None : DeepLink
    class Web(val path: String) : DeepLink
}