package features.mentory.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import features.common.DecorativeSection
import features.common.Faq
import features.common.FaqData
import features.common.INSTAGRAM_LINK
import features.common.MENTORY_PURCHASE_LINK
import features.common.WHATS_APP_CONTACT_LINK
import features.common.YOUTUBE_LINK
import features.common.adaptive.DecorativeSectionAdaptive
import features.common.adaptive.FaqAdaptive
import features.common.adaptive.ToolbarAdaptive
import features.common.adaptive.ToolbarAdaptiveScreen
import features.common.web.ImageActionButton
import features.common.web.TextActionButton
import features.landing.ui.adaptive.LandingFooterAdaptive
import features.mentory.web.MentoryBonusItem
import features.mentory.web.MentoryFooter
import features.mentory.web.Requirements
import kotlinx.coroutines.launch
import navigation.MentoryComponent
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.gift
import passaporteaupair.composeapp.generated.resources.instagram
import passaporteaupair.composeapp.generated.resources.mentory_faq_descriptions
import passaporteaupair.composeapp.generated.resources.mentory_faq_titles
import passaporteaupair.composeapp.generated.resources.mentory_product_bonus_title
import passaporteaupair.composeapp.generated.resources.mentory_product_bonuses
import passaporteaupair.composeapp.generated.resources.toolbar_label_faq
import passaporteaupair.composeapp.generated.resources.toolbar_label_how_it_work
import passaporteaupair.composeapp.generated.resources.toolbar_label_mentory_items
import passaporteaupair.composeapp.generated.resources.whatsapp
import passaporteaupair.composeapp.generated.resources.youtube
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryAdaptive(
    component: MentoryComponent,
) {
    Scaffold(
        modifier = Modifier.fillMaxSize()
    ) {

        val uriHandler = LocalUriHandler.current

        val scroll = rememberScrollState()
        var mentoryItemsPosition by remember { mutableIntStateOf(0) }
        var howItWorksPosition by remember { mutableIntStateOf(0) }
        var faqPosition by remember { mutableIntStateOf(0) }
        var showToolbarScreen by remember { mutableStateOf(false) }

        val scrollState = rememberLazyListState()
        val scope = rememberCoroutineScope()

        val textActionButtons = remember {
            listOf(
                TextActionButton(
                    label = Res.string.toolbar_label_mentory_items,
                    action = { scope.launch { scroll.scrollTo(mentoryItemsPosition) } }
                ),
                TextActionButton(
                    label = Res.string.toolbar_label_how_it_work,
                    action = { scope.launch { scroll.scrollTo(howItWorksPosition) } }
                ),
                TextActionButton(
                    label = Res.string.toolbar_label_faq,
                    action = { scope.launch { scroll.scrollTo(faqPosition) } }
                )
            )
        }

        val imageActionButtons = remember {
            listOf(
                ImageActionButton(
                    icon = Res.drawable.whatsapp,
                    action = { uriHandler.openUri(WHATS_APP_CONTACT_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.instagram,
                    action = { uriHandler.openUri(INSTAGRAM_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.youtube,
                    action = { uriHandler.openUri(YOUTUBE_LINK) }
                )
            )
        }
        if (showToolbarScreen) {
            ToolbarAdaptiveScreen(textActionButtons, imageActionButtons) {
                showToolbarScreen = false
            }
        } else {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .verticalScroll(scroll),
            ) {
                ToolbarAdaptive { showToolbarScreen = true }
                RequirementsAdaptive()
                MentoryItemsAdaptive()
                MentoryItemsDecorationAdaptive()
                MentoryBonusesAdaptive()
                MentoryBenefitsAdaptive(
                    modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                        showToolbarScreen = false
                        howItWorksPosition = layoutCoordinates.positionInParent().y.toInt()
                    }
                )

                DecorativeSectionAdaptive {
                    uriHandler.openUri(MENTORY_PURCHASE_LINK)
                }

                val titles = stringArrayResource(Res.array.mentory_faq_titles)
                val descs = stringArrayResource(Res.array.mentory_faq_descriptions)

                val faqData = remember(titles, descs) {
                    titles.mapIndexed { index, title ->
                        FaqData(
                            title = title,
                            desc = descs[index]
                        )
                    }
                }

                FaqAdaptive(
                    modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                        faqPosition = layoutCoordinates.positionInParent().y.toInt()
                    },
                    faqData = faqData
                )
                LandingFooterAdaptive(
                    showCloud = true,
                    textActionButtons = textActionButtons,
                    imageActionButtons = imageActionButtons
                )

//                MentoryFooterAdaptive(textActionButtons, imageActionButtons)
            }
        }
    }
}
