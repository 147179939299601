package features.landing.ui

import TABLET_SCREEN_WIDTH
import androidx.compose.runtime.Composable
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import features.landing.LandingComponent
import features.landing.ui.adaptive.LandingPageAdaptive
import features.landing.ui.web.LandingPage

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun Landing(
    component: LandingComponent,
) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    if(screenWidth<TABLET_SCREEN_WIDTH){
        LandingPageAdaptive(component)
    }else{
        LandingPage(component)
    }
}