package features.landing.ui.web

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import features.common.Circle
import features.common.DashedCircle
import features.common.INTRO_LINK
import features.common.PrimaryButton
import features.landing.LandingComponent
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.getStringArray
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.academic_hat
import passaporteaupair.composeapp.generated.resources.achievement
import passaporteaupair.composeapp.generated.resources.cd_play_intro
import passaporteaupair.composeapp.generated.resources.decorative_line_1
import passaporteaupair.composeapp.generated.resources.home_section_description
import passaporteaupair.composeapp.generated.resources.home_section_title
import passaporteaupair.composeapp.generated.resources.home_title_variations
import passaporteaupair.composeapp.generated.resources.label_guarantee
import passaporteaupair.composeapp.generated.resources.label_play_intro
import passaporteaupair.composeapp.generated.resources.label_secure_purchase
import passaporteaupair.composeapp.generated.resources.label_students_count
import passaporteaupair.composeapp.generated.resources.mentor_image
import passaporteaupair.composeapp.generated.resources.play
import passaporteaupair.composeapp.generated.resources.shield
import theme.dimens
import utils.screenWidth


@OptIn(ExperimentalResourceApi::class)
@Composable
fun Intro(
    component: LandingComponent,
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    val horizontalPadding = remember(screenWidth) { screenWidth * 0.079f }
    Column {
        Box(
            modifier = Modifier.fillMaxWidth()
        ) {
            Image(
                modifier = Modifier.fillMaxWidth(0.35f),
                painter = painterResource(Res.drawable.decorative_line_1),
                contentDescription = null
            )
            Row(
                modifier = Modifier.fillMaxWidth()
            ) {
                IntroWelcomeMessage(
                    component = component,
                    modifier = Modifier.weight(1f, fill = true)
                        .padding(
                            start = horizontalPadding,
                            top = remember(screenWidth) { screenWidth * 0.106f + 70.dp },
                            end = remember(screenWidth) { screenWidth * 0.087f }
                        ),
                )
                IntroImage()
            }
        }
        Spacer(
            modifier = Modifier.height(
                remember(screenWidth) { spacing.extraLarge + (screenWidth * 0.069f) }
            )
        )
        BenefitsSection(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = horizontalPadding),
        )
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun IntroImage() {
    val screenWidth = screenWidth
    Box(
        modifier = Modifier.padding(
            top = remember(screenWidth) { screenWidth * 0.053f + 70.dp }
        ),
        contentAlignment = Alignment.BottomEnd,
    ) {
        Circle(
            modifier = Modifier
                .offset(
                    x = remember(screenWidth) { screenWidth * 0.087f },
                    y = remember(screenWidth) { screenWidth * 0.031f },
                ),
            radius = remember(screenWidth) { screenWidth * 0.103f },
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.secondary)
        )
        DashedCircle(
            modifier = Modifier
                .offset(
                    x = remember(screenWidth) { screenWidth * 0.045f },
                    y = remember(screenWidth) { screenWidth * 0.031f }
                ),
            radius = remember(screenWidth) { screenWidth * 0.086f },
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onBackground),
        )
        Box {
            Circle(
                modifier = Modifier
                    .offset(x = remember(screenWidth) { screenWidth * 0.043f + 50.dp }),
                radius = remember(screenWidth) { screenWidth * 0.042f },
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.secondary)
            )
            DashedCircle(
                modifier = Modifier
                    .offset(x = remember(screenWidth) { screenWidth * 0.032f + 50.dp }),
                radius = remember(screenWidth) { screenWidth * 0.042f },
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onBackground),
            )
            Image(
                modifier = Modifier
                    .size(remember(screenWidth) { screenWidth * 0.41f })
                    .offset(remember(screenWidth) { screenWidth * 0.019f })
                    .clip(CircleShape),
                painter = painterResource(Res.drawable.mentor_image),
                contentDescription = null
            )
        }

    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun IntroWelcomeMessage(
    component: LandingComponent,
    modifier: Modifier,
) {
    val uriHandler = LocalUriHandler.current
    Column(
        modifier = modifier
    ) {
        IntroTitle()
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))
        Text(
            style = MaterialTheme.typography.bodyLarge,
            text = stringResource(Res.string.home_section_description)
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            PrimaryButton(onPurchaseClick = component.onMentoryClick)
            Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.large))
            Image(
                modifier = Modifier
                    .size(MaterialTheme.dimens.iconSize.playButtonIconSize)
                    .clip(CircleShape)
                    .clickable { uriHandler.openUri(INTRO_LINK) },
                painter = painterResource(Res.drawable.play),
                contentDescription = stringResource(Res.string.cd_play_intro)
            )
            Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.medium))
            Text(
                text = stringResource(Res.string.label_play_intro),
                style = MaterialTheme.typography.labelMedium,
                color = MaterialTheme.colorScheme.onSurfaceVariant
            )

        }

    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ColumnScope.IntroTitle() {
    Text(
        text = stringResource(Res.string.home_section_title),
        style = MaterialTheme.typography.headlineLarge
    )
    var text by remember { mutableStateOf("") }
    var isVisible by remember { mutableStateOf(false) }
    val scope = rememberCoroutineScope()

    LaunchedEffect(true) {
        scope.launch {
            while (isActive) {
                getStringArray(Res.array.home_title_variations).forEach {
                    isVisible = true
                    it.forEach {
                        text = buildString {
                            append(text)
                            append(it)
                        }
                        delay(100L)
                    }
                    var count = 0
                    while (isActive && count < 7) {
                        delay(700L)
                        isVisible = !isVisible
                        count++
                    }
                    isVisible = true
                    for (i in 1..it.length) {
                        text = it.substring(0, it.length - i)
                        delay(70L)
                    }
                }
            }
        }
    }
    Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))

    Row(
        modifier = Modifier.height(IntrinsicSize.Min).fillMaxWidth(),
        verticalAlignment = Alignment.Bottom,
        horizontalArrangement = Arrangement.End
    ) {
        Text(
            modifier = Modifier
                .background(MaterialTheme.colorScheme.background),
//                textAlign = TextAlign.End,
            style = MaterialTheme.typography.displayLarge,
            text = text
        )
        if (isVisible) {
            Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.medium))
            VerticalDivider(
                modifier = Modifier.fillMaxHeight()
                    .scale(scaleX = 1f, scaleY = 1.25f),
                color = MaterialTheme.colorScheme.primary,
                thickness = 2.dp
            )
        } else {
            Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.medium + 2.dp))
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun BenefitsSection(
    modifier: Modifier,
    spacing: Dp = MaterialTheme.dimens.spacing.large
) {
    Row(
        modifier = modifier
    ) {
        BenefitItemHorizontal(
            modifier = Modifier.weight(1f, true),
            icon = Res.drawable.achievement,
            text = Res.string.label_guarantee
        )

        Spacer(modifier = Modifier.width(spacing))

        BenefitItemHorizontal(
            modifier = Modifier.weight(1f, true),
            icon = Res.drawable.shield,
            text = Res.string.label_secure_purchase
        )

        Spacer(
            modifier = Modifier.width(spacing)
        )

        BenefitItemHorizontal(
            modifier = Modifier.weight(1f, true),
            icon = Res.drawable.academic_hat,
            text = Res.string.label_students_count
        )
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun BenefitItemHorizontal(
    modifier: Modifier,
    icon: DrawableResource,
    text: StringResource
) {
    Row(
        modifier = modifier
            .clip(RoundedCornerShape(20.dp))
            .background(MaterialTheme.colorScheme.surface)
            .padding(vertical = MaterialTheme.dimens.spacing.medium),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            modifier = Modifier
                .size(MaterialTheme.dimens.iconSize.programProsIconSize)
                .padding(MaterialTheme.dimens.spacing.extraSmall),
            painter = painterResource(icon),
            contentDescription = null,
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary),
        )
        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.large))
        Text(
            text = stringResource(text),
            color = MaterialTheme.colorScheme.primary,
            style = MaterialTheme.typography.titleLarge,
            fontWeight = FontWeight.ExtraBold
        )
    }
}