package features.mentory.web

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.DrawerState
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextDecorationLineStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_buy_language_portal
import passaporteaupair.composeapp.generated.resources.boat
import passaporteaupair.composeapp.generated.resources.decorative_line_2
import passaporteaupair.composeapp.generated.resources.decorative_line_3
import passaporteaupair.composeapp.generated.resources.label_requirements_1
import passaporteaupair.composeapp.generated.resources.label_requirements_2
import passaporteaupair.composeapp.generated.resources.mentor_image_3
import passaporteaupair.composeapp.generated.resources.mentory_requirements
import theme.dimens
import utils.screenWidth
import utils.toBulletPoints

@OptIn(ExperimentalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun Requirements() {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    Row(
        modifier = Modifier.fillMaxWidth()
            .padding(bottom = MaterialTheme.dimens.spacing.extraLarge * 2)
    ) {
        Column(
            modifier = Modifier
                .weight(1f, true)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        top = remember(screenWidth) { screenWidth * 0.056f + spacing.extraLarge },
                        start = remember(screenWidth) { screenWidth * 0.079f },
                        end = remember(screenWidth) { screenWidth * 0.027f + spacing.extraLarge }
                    )
            ) {
                Text(
                    text = stringResource(Res.string.label_requirements_1),
                    style = MaterialTheme.typography.headlineMedium
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))

                Text(
                    text = requirementsString,
                    style = MaterialTheme.typography.bodyMedium,
                    lineHeight = MaterialTheme.dimens.fontLineHeight.bodyExtraLargeLineHight
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))

                Text(
                    text = stringResource(Res.string.label_requirements_2),
                    style = MaterialTheme.typography.bodyLarge,
                    fontWeight = FontWeight.Bold
                )
            }
            Row(
                modifier = Modifier.padding(top = MaterialTheme.dimens.spacing.large)
            ) {
                Image(
                    modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.168f }),
                    painter = painterResource(Res.drawable.boat),
                    contentDescription = null
                )
                Image(
                    modifier = Modifier
                        .weight(1f, true)
                        .offset(
                            x = MaterialTheme.dimens.spacing.large,
                            y = MaterialTheme.dimens.spacing.extraLarge * 2
                        ),
                    painter = painterResource(Res.drawable.decorative_line_3),
                    contentDescription = null
                )
            }
        }
        Row(
            modifier = Modifier.padding(
                top = MaterialTheme.dimens.spacing.extraLarge
            )
        ) {
            Image(
                modifier = Modifier
                    .height(remember(screenWidth) { screenWidth * 0.395f })
//                    .width(remember(screenWidth) { screenWidth * 0.256f)
                    .clip(RoundedCornerShape(remember(screenWidth) { screenWidth * 0.138f })),
                painter = painterResource(Res.drawable.mentor_image_3),
                contentDescription = null
            )
            Image(
                modifier = Modifier
                    .width(remember(screenWidth) { screenWidth * 0.116f })
                    .offset(y = remember(screenWidth) { screenWidth * 0.026f }),
                painter = painterResource(Res.drawable.decorative_line_2),
                contentDescription = null
            )
        }
    }
}

val requirementsString = buildAnnotatedString {
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append("•  Você é")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" mulher")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append(" e tem entre")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" 18 a 28 anos.\n")
    }

    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append("•")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append("  Você se sente perdida ")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append(" em um mar de informações soltas e")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" deseja ter facilidade")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append(" em encontrar a documentação necessária?\n")
    }

    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append("•  Você")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" sonha em fazer intercâmbio")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append(" desde sempre e precisa encontrar uma forma")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" que seja sustentável")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append(" pra você?\n")
    }

    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append("•  Você")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" já tentou")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append(" fazer o processo sozinha mas")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
        append(" não teve o resultado esperado")
    }
    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
        append("?")
    }
}