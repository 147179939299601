package features.mentory.adaptive

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import theme.dimens

@Composable
fun BonusContainerVertical(
    modifier: Modifier,
    headColor: Color = MaterialTheme.colorScheme.primary,
    bodyColor: Color = MaterialTheme.colorScheme.secondary,
    dividerColor: Color = MaterialTheme.colorScheme.inverseOnSurface,
    cornerRadius: Dp = 40.dp,
    paddingStart: Dp = 16.dp,
    paddingEnd: Dp = 16.dp,
    paddingTop: Dp = 16.dp,
    paddingBottom: Dp = 16.dp,
    headContent: @Composable () -> Unit = {},
    bodyContent: @Composable () -> Unit = {},
) {
    val radius = with(LocalDensity.current) { cornerRadius.toPx() }

    Column(
        modifier = modifier
            .clip(RoundedCornerShape(cornerRadius))
            .background(bodyColor)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .drawBehind {
                    val hatHeight = size.height
                    val decorOvalRadius = size.height * 0.082f
                    val hatPath = Path().apply {
                        moveTo(0f, radius)
                        lineTo(0f, hatHeight)

                        quadraticBezierTo(
                            x1 = size.width / 6,
                            y1 = hatHeight + (hatHeight * 0.08f),
                            x2 = size.width / 3,
                            y2 = hatHeight
                        )
                        quadraticBezierTo(
                            x1 = size.width / 2,
                            y1 = hatHeight - (hatHeight * 0.10f),
                            x2 = size.width / 3 * 2,
                            y2 = hatHeight
                        )
                        quadraticBezierTo(
                            x1 = size.width / 6 * 5,
                            y1 = hatHeight + (hatHeight * 0.08f),
                            x2 = size.width,
                            y2 = hatHeight
                        )
                        lineTo(size.width, radius)
                        quadraticBezierTo(
                            x1 = size.width,
                            y1 = 0f,
                            x2 = size.width - radius,
                            y2 = 0f
                        )
                        lineTo(radius, 0f)
                        quadraticBezierTo(
                            x1 = 0f,
                            y1 = 0f,
                            x2 = 0f,
                            y2 = radius
                        )
                        close()

                    }

                    drawPath(
                        path = hatPath,
                        color = headColor,
                    )
                    drawOval(
                        color = dividerColor,
                        topLeft = Offset(
                            x = -decorOvalRadius,
                            y = hatHeight - decorOvalRadius
                        ),
                        size = Size(decorOvalRadius * 2, decorOvalRadius * 2)
                    )

                    drawOval(
                        color = dividerColor,
                        topLeft = Offset(
                            x = size.width - decorOvalRadius,
                            y = hatHeight - decorOvalRadius
                        ),
                        size = Size(decorOvalRadius * 2, decorOvalRadius * 2)
                    )

                    val thinDivider = Path().apply {
                        moveTo(0f, hatHeight)

                        quadraticBezierTo(
                            x1 = size.width / 6,
                            y1 = hatHeight + (hatHeight * 0.08f),
                            x2 = size.width / 3,
                            y2 = hatHeight
                        )
                        quadraticBezierTo(
                            x1 = size.width / 2,
                            y1 = hatHeight - (hatHeight * 0.10f),
                            x2 = size.width / 3 * 2,
                            y2 = hatHeight
                        )
                        quadraticBezierTo(
                            x1 = size.width / 6 * 5,
                            y1 = hatHeight + (hatHeight * 0.08f),
                            x2 = size.width,
                            y2 = hatHeight
                        )

                    }
                    drawPath(thinDivider, dividerColor, style = Stroke(2f))
                    val dashedDivider = Path().apply {
                        moveTo(0f, hatHeight - 2f)

                        quadraticBezierTo(
                            x1 = size.width / 6,
                            y1 = hatHeight + (hatHeight * 0.08f) - 2f,
                            x2 = size.width / 3,
                            y2 = hatHeight - 2f
                        )
                        quadraticBezierTo(
                            x1 = size.width / 2,
                            y1 = hatHeight - (hatHeight * 0.10f) - 2f,
                            x2 = size.width / 3 * 2,
                            y2 = hatHeight - 2f
                        )
                        quadraticBezierTo(
                            x1 = size.width / 6 * 5,
                            y1 = hatHeight + (hatHeight * 0.08f) - 2f,
                            x2 = size.width,
                            y2 = hatHeight - 2f
                        )

                    }
                    val pathEffect = PathEffect.dashPathEffect(floatArrayOf(16f, 16f), 0f)
                    drawPath(
                        dashedDivider,
                        dividerColor,
                        style = Stroke(3f, pathEffect = pathEffect)
                    )
                }

                .padding(
                    top = paddingTop,
                    start = paddingStart,
                    end = paddingEnd,
                    bottom = MaterialTheme.dimens.spacing.large
                )
        ) {
            headContent()
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
                    start = paddingStart,
                    end = paddingEnd,
                    bottom = paddingBottom,
                    top = MaterialTheme.dimens.spacing.xxLarge
                )
        ) {
            bodyContent()
        }
    }
}