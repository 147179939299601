package features.common

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_start_journey
import theme.dimens

@OptIn(ExperimentalResourceApi::class)
@Composable
fun SecondaryButton(
    modifier: Modifier = Modifier,
    strokeColor: Color,
    buttonColors: ButtonColors = ButtonDefaults.buttonColors(),
    textColor: Color = MaterialTheme.colorScheme.onBackground,
    text: String = stringResource(Res.string.action_start_journey),
    onPurchaseClick: () -> Unit
) {
    Button(
        modifier = modifier,
        colors = buttonColors,
        border = BorderStroke(1.dp, strokeColor),
        onClick = onPurchaseClick,
    ) {
        Text(
            text = text,
            color = textColor,
            style = MaterialTheme.typography.bodyLarge,
            fontWeight = FontWeight.ExtraBold,
            modifier = Modifier.padding(
                vertical = MaterialTheme.dimens.spacing.small,
                horizontal = MaterialTheme.dimens.spacing.extraLarge
            ),
        )
    }
}