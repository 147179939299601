package features.common

import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.unit.dp


@Composable
fun PrimaryButton(
    modifier: Modifier = Modifier,
    text: AnnotatedString,
    buttonColors:ButtonColors = ButtonDefaults.buttonColors(),
    onPurchaseClick: () -> Unit
) {
    Button(
        modifier = modifier,
        colors = buttonColors,
        onClick = onPurchaseClick
    ) {
        Text(
            text = text,
            style = MaterialTheme.typography.bodyLarge,
            modifier = Modifier.padding(
                vertical = 8.dp,
                horizontal = 40.dp
            ),
        )
    }
}


