package features.ebook.ui.web

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalUriHandler
import features.common.DecorativeSection
import features.common.EBOOK_PURCHASE_LINK
import features.common.Faq
import features.common.FaqData
import features.common.INSTAGRAM_LINK
import features.common.WHATS_APP_CONTACT_LINK
import features.common.web.ImageActionButton
import features.common.web.TextActionButton
import features.common.web.Toolbar
import features.common.YOUTUBE_LINK
import features.mentory.web.MentoryFooter
import kotlinx.coroutines.launch
import navigation.EbookComponent
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringArrayResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.ebook_faq_descriptions
import passaporteaupair.composeapp.generated.resources.ebook_faq_titles
import passaporteaupair.composeapp.generated.resources.instagram
import passaporteaupair.composeapp.generated.resources.toolbar_label_faq
import passaporteaupair.composeapp.generated.resources.whatsapp
import passaporteaupair.composeapp.generated.resources.youtube
import utils.screenWidth

@Composable
fun EbookPage(
    component: EbookComponent,
) {
    val screenWidth = screenWidth
    Scaffold {
        val uriHandler = LocalUriHandler.current

        val scroll = rememberScrollState()
        var faqPosition by remember { mutableIntStateOf(0) }

        val scrollState = rememberLazyListState()
        val scope = rememberCoroutineScope()

        val textActionButtons = remember {
            listOf(
                TextActionButton(
                    label = Res.string.toolbar_label_faq,
                    action = { scope.launch { scroll.scrollTo(faqPosition) } }
                )
            )
        }

        val imageActionButtons = remember {
            listOf(
                ImageActionButton(
                    icon = Res.drawable.whatsapp,
                    action = { uriHandler.openUri(WHATS_APP_CONTACT_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.instagram,
                    action = { uriHandler.openUri(INSTAGRAM_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.youtube,
                    action = { uriHandler.openUri(YOUTUBE_LINK) }
                )
            )
        }
        Column(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(scroll),
        ) {
            Toolbar(textActionButtons, imageActionButtons)
            EbookDescription(component)

            DecorativeSection(
                screenWidth = screenWidth,
                showLineDecoration = false
            ) {
                uriHandler.openUri(EBOOK_PURCHASE_LINK)
            }


            val titles = stringArrayResource(Res.array.ebook_faq_titles)
            val descs = stringArrayResource(Res.array.ebook_faq_descriptions)

            val faqData = remember(titles, descs) {
                titles.mapIndexed { index, title ->
                    FaqData(
                        title = title,
                        desc = descs[index]
                    )
                }
            }

            Faq(
                modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                    faqPosition = layoutCoordinates.positionInParent().y.toInt()
                },
                faqData = faqData
            )


            MentoryFooter(textActionButtons, imageActionButtons)

        }
    }
}