package features.landing.ui.adaptive

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalUriHandler
import features.common.INSTAGRAM_LINK
import features.common.WHATS_APP_CONTACT_LINK
import features.common.YOUTUBE_LINK
import features.common.adaptive.ToolbarAdaptive
import features.common.adaptive.ToolbarAdaptiveScreen
import features.common.web.ImageActionButton
import features.common.web.TextActionButton
import features.common.web.Toolbar
import features.landing.LandingComponent
import features.landing.ui.web.Intro
import features.landing.ui.web.LandingFooter
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.ExperimentalResourceApi
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.instagram
import passaporteaupair.composeapp.generated.resources.toolbar_label_about
import passaporteaupair.composeapp.generated.resources.toolbar_label_feedback
import passaporteaupair.composeapp.generated.resources.toolbar_label_products
import passaporteaupair.composeapp.generated.resources.whatsapp
import passaporteaupair.composeapp.generated.resources.youtube
import theme.dimens


@OptIn(ExperimentalResourceApi::class)
@Composable
fun LandingPageAdaptive(
    component: LandingComponent
) {
    Scaffold(
        modifier = Modifier.fillMaxSize()
    ) {

        val scrollState = rememberScrollState()
        val scope = rememberCoroutineScope()
        val uriHandler = LocalUriHandler.current

        var aboutPosition by remember { mutableIntStateOf(0) }
        var productsPosition by remember { mutableIntStateOf(0) }
        var feedbacksPosition by remember { mutableIntStateOf(0) }
        var showToolbarScreen by remember { mutableStateOf(false) }

        val textActionButtons = remember {
            listOf(
                TextActionButton(
                    label = Res.string.toolbar_label_about,
                    action = {
                        showToolbarScreen = false
                        scope.launch { scrollState.scrollTo(aboutPosition) }
                    }
                ),
                TextActionButton(
                    label = Res.string.toolbar_label_products,
                    action = {
                        showToolbarScreen = false
                        scope.launch { scrollState.scrollTo(productsPosition) }
                    }
                ),
                TextActionButton(
                    label = Res.string.toolbar_label_feedback,
                    action = {
                        showToolbarScreen = false
                        scope.launch { scrollState.scrollTo(feedbacksPosition) }
                    }
                )
            )
        }


        val imageActionButtons = remember {
            listOf(
                ImageActionButton(
                    icon = Res.drawable.whatsapp,
                    action = { uriHandler.openUri(WHATS_APP_CONTACT_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.instagram,
                    action = { uriHandler.openUri(INSTAGRAM_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.youtube,
                    action = { uriHandler.openUri(YOUTUBE_LINK) }
                )
            )
        }
        if (showToolbarScreen) {
            ToolbarAdaptiveScreen(textActionButtons, imageActionButtons) {
                showToolbarScreen = false
            }
        } else {
            Column(
                modifier = Modifier.fillMaxSize()
                    .verticalScroll(scrollState),
            ) {
                Box(
                    modifier = Modifier.fillMaxSize()
                ) {
                    IntroAdaptive(component)
                    ToolbarAdaptive {
                        showToolbarScreen = true
                    }

                }
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxLarge))
                BenefitsSectionVertical(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = MaterialTheme.dimens.spacing.medium),
                )

                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxLarge))
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxLarge))
                ExchangeDetailsAdaptive()
                ExchangeBenefitsAdaptive(
                    component = component,
                    modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                        aboutPosition = layoutCoordinates.positionInParent().y.toInt()
                    }
                )
                ProductsAdaptive(
                    component = component,
                    modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                        productsPosition = layoutCoordinates.positionInParent().y.toInt()
                    }
                )
                AboutMentor()
                Feedback(
                    modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                        feedbacksPosition = layoutCoordinates.positionInParent().y.toInt()
                    }
                )
                LandingFooterAdaptive(
                    showCloud = false,
                    textActionButtons = textActionButtons,
                    imageActionButtons = imageActionButtons
                )
            }
        }
    }
}