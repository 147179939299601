package features.landing

import com.arkivanov.decompose.ComponentContext

class LandingComponent(
    componentContext: ComponentContext,
    private val openMentoryPage: () -> Unit,
    private val openEbookPage: () -> Unit,
    private val openLanguagePortalPage: () -> Unit,
) {
    val onMentoryClick = openMentoryPage
    val onEbookClick = openEbookPage
    val onLanguagePortalClick = openLanguagePortalPage
}