package features.mentory.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.ColorFilter
import features.common.Circle
import features.common.DashedCircle
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.product_image_mentory
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryItemsDecorationAdaptive(){
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    Box(
        modifier = Modifier.padding(
            top = MaterialTheme.dimens.spacing.xxLarge,
            start = MaterialTheme.dimens.spacing.medium,
            bottom = remember(screenWidth) { screenWidth * 0.158f },
        ),
        contentAlignment = Alignment.BottomEnd
    ) {

        val circleRadius = remember(screenWidth) { screenWidth * 0.25f }
        Circle(
            radius = circleRadius,
            modifier = Modifier.offset(
                y = remember(screenWidth) { screenWidth * 0.158f },
                x = remember(screenWidth) { screenWidth * 0.133f }
            ),
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary)
        )
        DashedCircle(
            radius = circleRadius,
            modifier = Modifier.offset(
                y = remember(screenWidth) { screenWidth * 0.12f },
                x = remember(screenWidth) { screenWidth * 0.171f }
            ),
        )
        Box(
            contentAlignment = Alignment.TopStart
        ) {
            Image(
                modifier = Modifier
                    .wrapContentSize(unbounded = true, align = Alignment.TopStart)
                    .width(remember(screenWidth) { screenWidth * 1.38f }),
                painter = painterResource(Res.drawable.product_image_mentory),
                contentDescription = null
            )
        }
    }
}