package utils

import androidx.compose.runtime.Composable
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

fun List<String>.toMultiLine(): String {
    return buildString {
        this@toMultiLine.forEachIndexed { index, text ->
            if (index != 0) {
                append("\n")
            }
            append(text)
        }
    }
}

fun List<String>.toBulletPoints(): String {
    return buildString {
        val bullet = "\u2022"
        this@toBulletPoints.forEach {
            append(bullet)
            append("  ")
            append(it)
            append("\n")
        }
    }
}

fun Modifier.dashedBorder(strokeWidth: Dp, color: Color, cornerRadiusDp: Dp) = composed(
    factory = {
        val density = LocalDensity.current
        val strokeWidthPx = density.run { strokeWidth.toPx() }
        val cornerRadiusPx = density.run { cornerRadiusDp.toPx() }

        this.then(
            Modifier.drawWithCache {
                onDrawBehind {
                    val stroke = Stroke(
                        width = strokeWidthPx,
                        pathEffect = PathEffect.dashPathEffect(
                            floatArrayOf(16.dp.toPx(), 16.dp.toPx()), 0f
                        )
                    )

                    drawRoundRect(
                        color = color,
                        style = stroke,
                        cornerRadius = CornerRadius(cornerRadiusPx)
                    )
                }
            }
        )
    }
)

@OptIn(ExperimentalComposeUiApi::class)
val screenWidth
    @Composable get() = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }