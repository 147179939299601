import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.dp
import navigation.RootComponent
import navigation.RootContent
import theme.AdaptiveMediumDimens
import theme.CompactDimens
import theme.ExpandedDimens
import theme.LocalDimens
import theme.LowerMediumDimens
import theme.MediumDimens
import theme.TabletDimens
import theme.Typography

private const val POSITION_ABOUT_THE_PROGRAM = 1
private const val POSITION_PRODUCTS = 4
private const val POSITION_FEEDBACKS = 7
private const val POSITION_FAQ = 8

val TABLET_SCREEN_WIDTH = 840.dp
val MOBILE_SCREEN_WIDTH = 600.dp

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun App(component: RootComponent) {
    val screenWidth = with(LocalDensity.current) {
        LocalWindowInfo.current.containerSize.width.toDp()
    }
    val dimens = when {
        screenWidth < MOBILE_SCREEN_WIDTH -> AdaptiveMediumDimens
        screenWidth < TABLET_SCREEN_WIDTH -> TabletDimens
        screenWidth < 1100.dp -> CompactDimens
        screenWidth < 1300.dp -> LowerMediumDimens
        screenWidth < 1600.dp -> MediumDimens
        else -> ExpandedDimens
    }
    Box(modifier = Modifier.fillMaxSize().background(Color.Blue)) {
        CompositionLocalProvider(LocalDimens provides dimens) {
            MaterialTheme(
                colorScheme = LightColors,
                typography = Typography(LightColors),
            ) {
                RootContent(component, Modifier.fillMaxSize())
            }
        }
    }
}
