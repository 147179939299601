package features.mentory.web

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import features.common.Circle
import features.common.DashedCircle
import features.common.DecorativeSection
import features.common.Faq
import features.common.FaqData
import features.common.INSTAGRAM_LINK
import features.common.MENTORY_PURCHASE_LINK
import features.common.PrimaryButton
import features.common.WHATS_APP_CONTACT_LINK
import features.common.YOUTUBE_LINK
import features.common.web.ImageActionButton
import features.common.web.TextActionButton
import features.common.web.Toolbar
import features.landing.ui.web.LandingFooter
import kotlinx.coroutines.launch
import navigation.MentoryComponent
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.aim
import passaporteaupair.composeapp.generated.resources.decorative_line_6
import passaporteaupair.composeapp.generated.resources.gift
import passaporteaupair.composeapp.generated.resources.globe
import passaporteaupair.composeapp.generated.resources.heart
import passaporteaupair.composeapp.generated.resources.instagram
import passaporteaupair.composeapp.generated.resources.label_mentory_details_1
import passaporteaupair.composeapp.generated.resources.label_mentory_details_2
import passaporteaupair.composeapp.generated.resources.label_mentory_details_3
import passaporteaupair.composeapp.generated.resources.lagguage
import passaporteaupair.composeapp.generated.resources.location
import passaporteaupair.composeapp.generated.resources.mentory_action_buy
import passaporteaupair.composeapp.generated.resources.mentory_bank
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_1
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_2
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_3
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_4
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_5
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_6
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_7
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_1
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_2
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_3
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_4
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_5
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_6
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_7
import passaporteaupair.composeapp.generated.resources.mentory_faq_descriptions
import passaporteaupair.composeapp.generated.resources.mentory_faq_titles
import passaporteaupair.composeapp.generated.resources.mentory_globe
import passaporteaupair.composeapp.generated.resources.mentory_graph
import passaporteaupair.composeapp.generated.resources.mentory_hat
import passaporteaupair.composeapp.generated.resources.mentory_headphone
import passaporteaupair.composeapp.generated.resources.mentory_house
import passaporteaupair.composeapp.generated.resources.mentory_message
import passaporteaupair.composeapp.generated.resources.mentory_paper
import passaporteaupair.composeapp.generated.resources.mentory_passport
import passaporteaupair.composeapp.generated.resources.mentory_product_bonus_title
import passaporteaupair.composeapp.generated.resources.mentory_product_bonuses
import passaporteaupair.composeapp.generated.resources.mentory_product_items
import passaporteaupair.composeapp.generated.resources.mentory_product_title
import passaporteaupair.composeapp.generated.resources.mentory_star
import passaporteaupair.composeapp.generated.resources.mentory_video_cam
import passaporteaupair.composeapp.generated.resources.mentory_whatsapp
import passaporteaupair.composeapp.generated.resources.message
import passaporteaupair.composeapp.generated.resources.product_image_mentory
import passaporteaupair.composeapp.generated.resources.rocket
import passaporteaupair.composeapp.generated.resources.star
import passaporteaupair.composeapp.generated.resources.toolbar_label_faq
import passaporteaupair.composeapp.generated.resources.toolbar_label_how_it_work
import passaporteaupair.composeapp.generated.resources.toolbar_label_mentory_items
import passaporteaupair.composeapp.generated.resources.whatsapp
import passaporteaupair.composeapp.generated.resources.youtube
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryPage(
    component: MentoryComponent,
) {
    val screenWidth = screenWidth
    Scaffold {
        val uriHandler = LocalUriHandler.current

        val scroll = rememberScrollState()
        var mentoryItemsPosition by remember { mutableIntStateOf(0) }
        var howItWorksPosition by remember { mutableIntStateOf(0) }
        var faqPosition by remember { mutableIntStateOf(0) }

        val scrollState = rememberLazyListState()
        val scope = rememberCoroutineScope()

        val textActionButtons = remember {
            listOf(
                TextActionButton(
                    label = Res.string.toolbar_label_mentory_items,
                    action = { scope.launch { scroll.scrollTo(mentoryItemsPosition) } }
                ),
                TextActionButton(
                    label = Res.string.toolbar_label_how_it_work,
                    action = { scope.launch { scroll.scrollTo(howItWorksPosition) } }
                ),
                TextActionButton(
                    label = Res.string.toolbar_label_faq,
                    action = { scope.launch { scroll.scrollTo(faqPosition) } }
                )
            )
        }

        val imageActionButtons = remember {
            listOf(
                ImageActionButton(
                    icon = Res.drawable.whatsapp,
                    action = { uriHandler.openUri(WHATS_APP_CONTACT_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.instagram,
                    action = { uriHandler.openUri(INSTAGRAM_LINK) }
                ),
                ImageActionButton(
                    icon = Res.drawable.youtube,
                    action = { uriHandler.openUri(YOUTUBE_LINK) }
                )
            )
        }
        Column(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(scroll),
        ) {
            Toolbar(textActionButtons, imageActionButtons)
            Requirements()


            MentoryItems(
                modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                    mentoryItemsPosition = layoutCoordinates.positionInParent().y.toInt()
                }
            )


            MentoryBonuses(screenWidth)


            MentoryBenefits(
                modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                    howItWorksPosition = layoutCoordinates.positionInParent().y.toInt()
                }
            )

            DecorativeSection(
                screenWidth = screenWidth,
                showLineDecoration = true
            ) {
                uriHandler.openUri(MENTORY_PURCHASE_LINK)
            }

            val titles = stringArrayResource(Res.array.mentory_faq_titles)
            val descs = stringArrayResource(Res.array.mentory_faq_descriptions)

            val faqData = remember(titles, descs) {
                titles.mapIndexed { index, title ->
                    FaqData(
                        title = title,
                        desc = descs[index]
                    )
                }
            }

            Faq(
                modifier = Modifier.onGloballyPositioned { layoutCoordinates ->
                    faqPosition = layoutCoordinates.positionInParent().y.toInt()
                },
                faqData = faqData
            )


            LandingFooter(showCloud = true, textActionButtons, imageActionButtons)

        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryBenefits(
    modifier: Modifier
) {
    val spacing = MaterialTheme.dimens.spacing
    val screenWidth = screenWidth
    Column(
        modifier = modifier
            .fillMaxWidth()
            .padding(
                bottom = remember(screenWidth) { screenWidth * 0.068f + spacing.extraLarge }
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            modifier = Modifier.padding(horizontal = spacing.extraLarge),
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.label_mentory_details_1),
            style = MaterialTheme.typography.headlineMedium
        )
        Spacer(modifier = Modifier.height(spacing.xxLarge))

        Text(
            modifier = Modifier.padding(horizontal = spacing.extraLarge),
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.label_mentory_details_2),
            style = MaterialTheme.typography.bodyLarge
        )
        Spacer(modifier = Modifier.height(spacing.medium))

        Text(
            modifier = Modifier.padding(horizontal = spacing.extraLarge),
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.label_mentory_details_3),
            style = MaterialTheme.typography.headlineSmall
        )
        Spacer(modifier = Modifier.height(spacing.extraLarge))
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.aim,
                title = stringResource(Res.string.mentory_details_section_title_1),
                desc = stringResource(Res.string.mentory_details_section_desc_1)
            )
            Spacer(modifier = Modifier.width(spacing.xxLarge))
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.location,
                title = stringResource(Res.string.mentory_details_section_title_2),
                desc = stringResource(Res.string.mentory_details_section_desc_2)
            )
            Spacer(modifier = Modifier.width(spacing.xxLarge))
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.globe,
                title = stringResource(Res.string.mentory_details_section_title_3),
                desc = stringResource(Res.string.mentory_details_section_desc_3)
            )
            Spacer(modifier = Modifier.width(spacing.xxLarge))
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.message,
                title = stringResource(Res.string.mentory_details_section_title_4),
                desc = stringResource(Res.string.mentory_details_section_desc_4)
            )
        }
        Spacer(modifier = Modifier.height(spacing.xxLarge))

        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.lagguage,
                title = stringResource(Res.string.mentory_details_section_title_5),
                desc = stringResource(Res.string.mentory_details_section_desc_5)
            )
            Spacer(modifier = Modifier.width(spacing.xxLarge))
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.rocket,
                title = stringResource(Res.string.mentory_details_section_title_6),
                desc = stringResource(Res.string.mentory_details_section_desc_6)
            )
            Spacer(modifier = Modifier.width(spacing.xxLarge))
            MentoryBenefitItem(
                modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.178f }),
                resource = Res.drawable.heart,
                title = stringResource(Res.string.mentory_details_section_title_7),
                desc = stringResource(Res.string.mentory_details_section_desc_7)
            )
        }
    }

}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryBenefitItem(
    modifier: Modifier,
    resource: DrawableResource,
    title: String,
    desc: String
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier.size(MaterialTheme.dimens.iconSize.mentoryBenefitItemIconSize),
            painter = painterResource(resource),
            contentDescription = null
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))
        Text(
            text = title,
            textAlign = TextAlign.Center,
            style = MaterialTheme.typography.headlineSmall
        )
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.medium))
        Text(
            text = desc,
            textAlign = TextAlign.Center,
            style = MaterialTheme.typography.bodyMedium
        )
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryItems(
    modifier: Modifier,
//    onPurchaseClick: () -> Unit
) {
    val screenWidth = screenWidth
    val spacing = MaterialTheme.dimens.spacing
    val uriHandler = LocalUriHandler.current
    Box(
        modifier = modifier
            .fillMaxWidth()
//            .background(MaterialTheme.colorScheme.surface)
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(0.46f)
                .padding(
                    top = MaterialTheme.dimens.spacing.extraLarge * 2,
                    bottom = MaterialTheme.dimens.spacing.extraLarge,
                    start = remember(screenWidth) { screenWidth * 0.079f }
                )
        ) {
            Text(
                text = stringResource(Res.string.mentory_product_title),
                style = MaterialTheme.typography.headlineMedium,
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.extraLarge))

            val icons = remember {
                listOf(
                    Res.drawable.mentory_globe,
                    Res.drawable.mentory_headphone,
                    Res.drawable.mentory_message,
                    Res.drawable.mentory_star,
                    Res.drawable.mentory_house,
                    Res.drawable.mentory_bank,
                    Res.drawable.mentory_paper,
                    Res.drawable.mentory_whatsapp,
                    Res.drawable.mentory_hat,
                    Res.drawable.mentory_passport,
                    Res.drawable.mentory_graph,
                    Res.drawable.mentory_video_cam,
                )
            }

            stringArrayResource(Res.array.mentory_product_items).forEachIndexed { index, text ->
                MentorySubject(
                    iconRes = icons[index],
                    text = text
                )
                Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))

            }
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))
            PrimaryButton(text = stringResource(Res.string.mentory_action_buy)) {
                uriHandler.openUri(MENTORY_PURCHASE_LINK)
            }
        }
        Box(
            modifier = Modifier.matchParentSize(),
            contentAlignment = Alignment.TopEnd
        ) {

            Circle(
                radius = remember(screenWidth) { screenWidth * 0.117f },
                modifier = Modifier.offset(x = remember(screenWidth) { screenWidth * 0.097f })
                    .padding(top = remember(screenWidth) { screenWidth * 0.024f + spacing.medium }),
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary)
            )
            DashedCircle(
                radius = remember(screenWidth) { screenWidth * 0.117f },
                modifier = Modifier.offset(x = remember(screenWidth) { screenWidth * 0.084f })
                    .padding(top = remember(screenWidth) { screenWidth * 0.009f + spacing.medium }),
            )
            Box(
                modifier = Modifier.matchParentSize(),
                contentAlignment = Alignment.CenterEnd
            ) {
                Image(
                    modifier = Modifier.width(remember(screenWidth) { screenWidth * 0.679f })
                        .offset(x = remember(screenWidth) { screenWidth * 0.138f }),
                    painter = painterResource(Res.drawable.product_image_mentory),
                    contentDescription = null
                )
            }
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentorySubject(
    text: String,
    iconRes: DrawableResource
) {
    Row(
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            modifier = Modifier.size(MaterialTheme.dimens.iconSize.mentoryItem),
            painter = painterResource(iconRes),
            tint = MaterialTheme.colorScheme.primary,
            contentDescription = null
        )
        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.medium))
        Text(
            text = text,
            style = MaterialTheme.typography.bodyMedium
        )
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryBonuses(screenWidth: Dp) {
    val spacing = MaterialTheme.dimens.spacing
    Box(
        modifier = Modifier
            .fillMaxWidth()
        ,
        contentAlignment = Alignment.BottomEnd
    ) {

        Circle(
            modifier = Modifier
                .offset(
                    x = remember(screenWidth) { screenWidth * 0.075f },
                ),
            radius = remember(screenWidth) { screenWidth * 0.117f },
            color = MaterialTheme.colorScheme.primary
        )
        DashedCircle(
            modifier = Modifier
                .offset(
                    x = remember(screenWidth) { screenWidth * 0.075f },
                    y = remember(screenWidth) { -screenWidth * 0.025f }
                ),
            radius = remember(screenWidth) { screenWidth * 0.117f }
        )
        BonusContainer(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
                    start = remember(screenWidth) { screenWidth * 0.079f },
                    top = remember(screenWidth) { screenWidth * 0.022f + spacing.extraLarge },
                    end = remember(screenWidth) { screenWidth * 0.079f },
                    bottom = remember(screenWidth) { screenWidth * 0.043f + spacing.extraLarge }
                ),
            bodyColor = Color(255, 237, 229, 230),
            dividerColor = MaterialTheme.colorScheme.background,
            cornerRadius = 180.dp,
            bodyContent = {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                ) {
                    stringArrayResource(Res.array.mentory_product_bonuses).forEach {
                        MentoryBonusItem(it)
                        Spacer(modifier = Modifier.height(spacing.medium))
                    }
                }
            },
            headContent = {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    verticalArrangement = Arrangement.Bottom
                ) {

                    Spacer(modifier = Modifier.weight(1f, true))
                    Image(
                        modifier = Modifier.size(MaterialTheme.dimens.iconSize.mentoryBonusGiftIconSize),
                        painter = painterResource(Res.drawable.gift),
                        contentDescription = null
                    )
                    Spacer(modifier = Modifier.height(spacing.large))
                    Text(
                        text = stringResource(Res.string.mentory_product_bonus_title),
                        style = MaterialTheme.typography.headlineMedium,
                    )
                }
            }
        )
        Box(
            modifier = Modifier
                .matchParentSize(),
            contentAlignment = Alignment.TopStart
        ) {
            Image(
                modifier = Modifier
                    .height(remember(screenWidth) { screenWidth * 0.197f })
                    .offset(y = remember(screenWidth) { -screenWidth * 0.016f }),
                painter = painterResource(Res.drawable.decorative_line_6),
                contentDescription = null
            )
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryBonusItem(
    text: String
) {
    Row(
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onBackground),
            modifier = Modifier.size(MaterialTheme.dimens.iconSize.mentoryBonusItemIconSize),
            painter = painterResource(Res.drawable.star),
            contentDescription = null
        )
        Spacer(modifier = Modifier.width(MaterialTheme.dimens.spacing.medium))
        Text(
            text = text,
            style = MaterialTheme.typography.bodyMedium
        )
    }
}



