package features.mentory.adaptive

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import features.mentory.web.MentoryBenefitItem
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.aim
import passaporteaupair.composeapp.generated.resources.globe
import passaporteaupair.composeapp.generated.resources.heart
import passaporteaupair.composeapp.generated.resources.label_mentory_details_1
import passaporteaupair.composeapp.generated.resources.label_mentory_details_2
import passaporteaupair.composeapp.generated.resources.label_mentory_details_3
import passaporteaupair.composeapp.generated.resources.lagguage
import passaporteaupair.composeapp.generated.resources.location
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_1
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_2
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_3
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_4
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_5
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_6
import passaporteaupair.composeapp.generated.resources.mentory_details_section_desc_7
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_1
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_2
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_3
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_4
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_5
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_6
import passaporteaupair.composeapp.generated.resources.mentory_details_section_title_7
import passaporteaupair.composeapp.generated.resources.message
import passaporteaupair.composeapp.generated.resources.rocket
import theme.dimens
import utils.screenWidth


@OptIn(ExperimentalResourceApi::class)
@Composable
fun MentoryBenefitsAdaptive(
    modifier: Modifier
) {
    val spacing = MaterialTheme.dimens.spacing
    val screenWidth = screenWidth
    Column(
        modifier = modifier
            .fillMaxWidth()
            .padding(
                top = MaterialTheme.dimens.spacing.xxxLarge,
                start = MaterialTheme.dimens.spacing.medium,
                end = MaterialTheme.dimens.spacing.medium,
                bottom = remember(screenWidth) { screenWidth * 0.3f + spacing.extraLarge }
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.label_mentory_details_1),
            style = MaterialTheme.typography.headlineMedium
        )
        Spacer(modifier = Modifier.height(spacing.medium))

        Text(
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.label_mentory_details_2),
            style = MaterialTheme.typography.bodyMedium
        )
        Spacer(modifier = Modifier.height(spacing.large))

        Text(
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.label_mentory_details_3),
            fontSize = MaterialTheme.dimens.fontSize.titleLarge,
            style = MaterialTheme.typography.headlineSmall
        )
        Spacer(modifier = Modifier.height(spacing.large))

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.aim,
                title = stringResource(Res.string.mentory_details_section_title_1),
                desc = stringResource(Res.string.mentory_details_section_desc_1)
            )
            Spacer(modifier = Modifier.width(spacing.medium))
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.location,
                title = stringResource(Res.string.mentory_details_section_title_2),
                desc = stringResource(Res.string.mentory_details_section_desc_2)
            )
        }
        Spacer(modifier = Modifier.height(spacing.large))
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.globe,
                title = stringResource(Res.string.mentory_details_section_title_3),
                desc = stringResource(Res.string.mentory_details_section_desc_3)
            )
            Spacer(modifier = Modifier.width(spacing.medium))
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.message,
                title = stringResource(Res.string.mentory_details_section_title_4),
                desc = stringResource(Res.string.mentory_details_section_desc_4)
            )
        }
        Spacer(modifier = Modifier.height(spacing.large))
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.lagguage,
                title = stringResource(Res.string.mentory_details_section_title_5),
                desc = stringResource(Res.string.mentory_details_section_desc_5)
            )
            Spacer(modifier = Modifier.width(spacing.medium))
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.rocket,
                title = stringResource(Res.string.mentory_details_section_title_6),
                desc = stringResource(Res.string.mentory_details_section_desc_6)
            )
        }
        Spacer(modifier = Modifier.height(spacing.large))

        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {
            MentoryBenefitItem(
                modifier = Modifier.weight(1f),
                resource = Res.drawable.heart,
                title = stringResource(Res.string.mentory_details_section_title_7),
                desc = stringResource(Res.string.mentory_details_section_desc_7)
            )
        }
    }
}