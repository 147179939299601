package features.common.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import features.common.PrimaryButton
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.action_buy_ebook
import passaporteaupair.composeapp.generated.resources.action_buy_ebook_price
import passaporteaupair.composeapp.generated.resources.action_contact
import passaporteaupair.composeapp.generated.resources.cloud_9
import passaporteaupair.composeapp.generated.resources.label_contact
import passaporteaupair.composeapp.generated.resources.label_feedback_1
import passaporteaupair.composeapp.generated.resources.whatsapp
import theme.dimens
import utils.dashedBorder
import utils.screenWidth
import utils.toMultiLine

@Composable
fun ContactSectionAdaptive(
    showCloud: Boolean = true,
    onClick: () -> Unit
) {
    val screenWidth = screenWidth
    Box {

        Column(
            modifier = Modifier
                .background(MaterialTheme.colorScheme.surface)
                .padding(vertical = MaterialTheme.dimens.spacing.xxxLarge)
        ) {
            Image(
                modifier = Modifier
                    .size(40.dp)
                    .offset(
                        x = remember(screenWidth) { screenWidth * 0.089f },
                        y=20.dp
                    ),
                painter = painterResource(Res.drawable.whatsapp),
                contentDescription = null,
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))

            Image(
                modifier = Modifier
                    .size(26.dp)
                    .offset(x = remember(screenWidth) { screenWidth * 0.85f }),
                painter = painterResource(Res.drawable.whatsapp),
                contentDescription = null,
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary)
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
            Text(
                modifier = Modifier.fillMaxWidth()
                    .padding(MaterialTheme.dimens.spacing.medium),
                text = stringResource(Res.string.label_contact),
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.headlineMedium
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxxLarge))

            PrimaryButton(
                modifier = Modifier.fillMaxWidth()
                    .padding(MaterialTheme.dimens.spacing.medium),
                text = buildAnnotatedString {
                    withStyle(style = SpanStyle(fontWeight = FontWeight.Normal)) {
                        append(stringResource(Res.string.action_contact))
                    }
                    withStyle(SpanStyle(fontWeight = FontWeight.Bold)) {
                        append(stringResource(Res.string.whatsapp))
                    }
                },
                onPurchaseClick = onClick,
                buttonColors = ButtonDefaults.buttonColors()
                    .copy(containerColor = MaterialTheme.colorScheme.secondary)
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.small))
            Image(
                modifier = Modifier
                    .size(26.dp)
                    .offset(
                        x = remember(screenWidth) { screenWidth * 0.37f },
                        y = 44.dp
                    ),
                painter = painterResource(Res.drawable.whatsapp),
                contentDescription = null,
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.primary)
            )



            Image(
                modifier = Modifier
                    .size(40.dp)
                    .offset(x = remember(screenWidth) { screenWidth * 0.92f })
                    .rotate(20f),
                painter = painterResource(Res.drawable.whatsapp),
                contentDescription = null,
            )
            Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.xxLarge))

            Image(
                modifier = Modifier
                    .size(32.dp)
                    .offset(x = -6.dp)
                    .rotate(20f),
                painter = painterResource(Res.drawable.whatsapp),
                contentDescription = null,
            )
        }
        if (showCloud) {
            Image(
                modifier = Modifier
                    .width(380.dp)
                    .offset(y = -70.dp),
                painter = painterResource(Res.drawable.cloud_9),
                contentDescription = null,
            )
        }
    }
}