package features.landing.ui.adaptive

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import features.landing.LandingComponent
import features.landing.ui.web.IntroTitle
import features.landing.ui.web.LandingFooter
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import passaporteaupair.composeapp.generated.resources.Res
import passaporteaupair.composeapp.generated.resources.decorative_line_1
import theme.dimens
import utils.screenWidth

@OptIn(ExperimentalResourceApi::class)
@Composable
fun IntroAdaptive(
    component: LandingComponent,
) {
    val screenWidth = screenWidth

    Image(
        modifier = Modifier.fillMaxWidth(0.81f)
            .offset(y = remember(screenWidth) { -screenWidth * 0.143f }),
        painter = painterResource(Res.drawable.decorative_line_1),
        contentDescription = null
    )
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(
                top = remember(screenWidth) { screenWidth * 0.294f },
            ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(0.81f)
                .padding(MaterialTheme.dimens.spacing.medium)
        ) {
            IntroTitle()
        }
        Spacer(modifier = Modifier.height(MaterialTheme.dimens.spacing.large))

        IntroDesc(component)

    }

}

