@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package passaporteaupair.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringArrayResource

private object CommonMainArray0 {
  public val ebook_faq_descriptions: StringArrayResource by 
      lazy { init_ebook_faq_descriptions() }

  public val ebook_faq_titles: StringArrayResource by 
      lazy { init_ebook_faq_titles() }

  public val ebook_module_1_items: StringArrayResource by 
      lazy { init_ebook_module_1_items() }

  public val ebook_module_2_items: StringArrayResource by 
      lazy { init_ebook_module_2_items() }

  public val ebook_module_3_items: StringArrayResource by 
      lazy { init_ebook_module_3_items() }

  public val first_product_items: StringArrayResource by 
      lazy { init_first_product_items() }

  public val home_title_variations: StringArrayResource by 
      lazy { init_home_title_variations() }

  public val language_portal_faq_descriptions: StringArrayResource by 
      lazy { init_language_portal_faq_descriptions() }

  public val language_portal_faq_titles: StringArrayResource by 
      lazy { init_language_portal_faq_titles() }

  public val language_portal_module_1_items: StringArrayResource by 
      lazy { init_language_portal_module_1_items() }

  public val language_portal_module_2_items: StringArrayResource by 
      lazy { init_language_portal_module_2_items() }

  public val language_portal_module_3_items: StringArrayResource by 
      lazy { init_language_portal_module_3_items() }

  public val mentory_faq_descriptions: StringArrayResource by 
      lazy { init_mentory_faq_descriptions() }

  public val mentory_faq_titles: StringArrayResource by 
      lazy { init_mentory_faq_titles() }

  public val mentory_product_bonuses: StringArrayResource by 
      lazy { init_mentory_product_bonuses() }

  public val mentory_product_items: StringArrayResource by 
      lazy { init_mentory_product_items() }

  public val mentory_requirements: StringArrayResource by 
      lazy { init_mentory_requirements() }

  public val second_product_items: StringArrayResource by 
      lazy { init_second_product_items() }

  public val third_product_items: StringArrayResource by 
      lazy { init_third_product_items() }
}

internal val Res.array.ebook_faq_descriptions: StringArrayResource
  get() = CommonMainArray0.ebook_faq_descriptions

private fun init_ebook_faq_descriptions(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:ebook_faq_descriptions", "ebook_faq_descriptions",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    10, 551),
    )
)

internal val Res.array.ebook_faq_titles: StringArrayResource
  get() = CommonMainArray0.ebook_faq_titles

private fun init_ebook_faq_titles(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:ebook_faq_titles", "ebook_faq_titles",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    562, 185),
    )
)

internal val Res.array.ebook_module_1_items: StringArrayResource
  get() = CommonMainArray0.ebook_module_1_items

private fun init_ebook_module_1_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:ebook_module_1_items", "ebook_module_1_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    748, 159),
    )
)

internal val Res.array.ebook_module_2_items: StringArrayResource
  get() = CommonMainArray0.ebook_module_2_items

private fun init_ebook_module_2_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:ebook_module_2_items", "ebook_module_2_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    908, 197),
    )
)

internal val Res.array.ebook_module_3_items: StringArrayResource
  get() = CommonMainArray0.ebook_module_3_items

private fun init_ebook_module_3_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:ebook_module_3_items", "ebook_module_3_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    1106, 170),
    )
)

internal val Res.array.first_product_items: StringArrayResource
  get() = CommonMainArray0.first_product_items

private fun init_first_product_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:first_product_items", "first_product_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    1277, 233),
    )
)

internal val Res.array.home_title_variations: StringArrayResource
  get() = CommonMainArray0.home_title_variations

private fun init_home_title_variations(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:home_title_variations", "home_title_variations",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    1511, 56),
    )
)

internal val Res.array.language_portal_faq_descriptions: StringArrayResource
  get() = CommonMainArray0.language_portal_faq_descriptions

private fun init_language_portal_faq_descriptions(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:language_portal_faq_descriptions", "language_portal_faq_descriptions",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    1568, 517),
    )
)

internal val Res.array.language_portal_faq_titles: StringArrayResource
  get() = CommonMainArray0.language_portal_faq_titles

private fun init_language_portal_faq_titles(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:language_portal_faq_titles", "language_portal_faq_titles",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    2086, 239),
    )
)

internal val Res.array.language_portal_module_1_items: StringArrayResource
  get() = CommonMainArray0.language_portal_module_1_items

private fun init_language_portal_module_1_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:language_portal_module_1_items", "language_portal_module_1_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    2326, 152),
    )
)

internal val Res.array.language_portal_module_2_items: StringArrayResource
  get() = CommonMainArray0.language_portal_module_2_items

private fun init_language_portal_module_2_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:language_portal_module_2_items", "language_portal_module_2_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    2479, 192),
    )
)

internal val Res.array.language_portal_module_3_items: StringArrayResource
  get() = CommonMainArray0.language_portal_module_3_items

private fun init_language_portal_module_3_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:language_portal_module_3_items", "language_portal_module_3_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    2672, 148),
    )
)

internal val Res.array.mentory_faq_descriptions: StringArrayResource
  get() = CommonMainArray0.mentory_faq_descriptions

private fun init_mentory_faq_descriptions(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:mentory_faq_descriptions", "mentory_faq_descriptions",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    2821, 593),
    )
)

internal val Res.array.mentory_faq_titles: StringArrayResource
  get() = CommonMainArray0.mentory_faq_titles

private fun init_mentory_faq_titles(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:mentory_faq_titles", "mentory_faq_titles",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    3415, 211),
    )
)

internal val Res.array.mentory_product_bonuses: StringArrayResource
  get() = CommonMainArray0.mentory_product_bonuses

private fun init_mentory_product_bonuses(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:mentory_product_bonuses", "mentory_product_bonuses",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    3627, 767),
    )
)

internal val Res.array.mentory_product_items: StringArrayResource
  get() = CommonMainArray0.mentory_product_items

private fun init_mentory_product_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:mentory_product_items", "mentory_product_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    4395, 706),
    )
)

internal val Res.array.mentory_requirements: StringArrayResource
  get() = CommonMainArray0.mentory_requirements

private fun init_mentory_requirements(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:mentory_requirements", "mentory_requirements",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    5102, 509),
    )
)

internal val Res.array.second_product_items: StringArrayResource
  get() = CommonMainArray0.second_product_items

private fun init_second_product_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:second_product_items", "second_product_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    5612, 374),
    )
)

internal val Res.array.third_product_items: StringArrayResource
  get() = CommonMainArray0.third_product_items

private fun init_third_product_items(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:third_product_items", "third_product_items",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/passaporteaupair.composeapp.generated.resources/values/strings.commonMain.cvr",
    5987, 365),
    )
)
